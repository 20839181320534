//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// ScannerPage - InteractLite Order Items - Use the barcode scanner to select items to add to the order
//               Created: May 30, 2020
//               Version 1.003 - Nov 17, 21 - Cleanup List
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom'
import './App.css';
//import './custom.scss';            
import { Popup } from '@progress/kendo-react-popup';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
import { Avatar } from '@progress/kendo-react-layout';
import { TreeView } from '@progress/kendo-react-treeview';
import { Button } from '@progress/kendo-react-buttons';
import { withRouter } from './withRouter';
import { SessionInfo } from './App';
import { BrowserBarcodeReader } from '@zxing/library';
import { CallOM,OMTrace,trace,displayMessage,displayWarning,displayError,GetFileExtension,CreateKeys,LogOffResetMsg,getImageFileFromServer,CTYP,TYP,NOTIFY,SetNotify } from './CommonCode.js'; 
//
import { ReactComponent as MenuBar } from "./images/bars.svg";
//import { ReactComponent as ArrowDown } from "./images/arrow-down.svg";
//import { ReactComponent as ArrowUp } from "./images/arrow-up.svg";
//import { ReactComponent as StopLight } from "./images/circle.svg";
//import { ReactComponent as ButtonSave } from "./images/button-save.svg";
//import { ReactComponent as ButtonNew } from "./images/button-add.svg";
//import { ReactComponent as ButtonAddRow } from "./images/button-add-row.svg";
//import { ReactComponent as ButtonDelete } from "./images/button-delete.svg";
//import { ReactComponent as ButtonSync } from "./images/button-sync.svg";
//import { ReactComponent as CompanyAbout } from "./images/company-about.svg";
//import { ReactComponent as ButtonExportCSV } from "./images/button-export-csv.svg";
//import { ReactComponent as ButtonSearch } from "./images/search.svg";
import { ReactComponent as ButtonClear } from "./images/button-clear.svg";
//

//let ItemCategoriesDD = [];
//class CategoryDropDownCell extends React.Component {
//  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
//    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
//    this.props.onChange({
//      dataItem: this.props.dataItem,
//      field: this.props.field,
//      syntheticEvent: e.syntheticEvent,
//      value: e.target.value
//    });
//    this.props.dataItem[this.props.field] = e.target.value.ddID;
//    this.forceUpdate();
//  }
//  render() {
//    let dataValue = this.props.dataItem[this.props.field];
//    if (dataValue === undefined || dataValue === null)
//      dataValue = 0;
//    if (!this.props.dataItem.inEdit) {
//      if (ItemCategoriesDD.find(c => c.ddID === dataValue) === undefined)
//        return (
//          <td>{"Unknown: " + dataValue}</td>
//        );
//      else
//        return (
//          <td>
//            {ItemCategoriesDD.find(c => c.ddID === dataValue).ddName}
//          </td>
//        );
//    }
//    return (
//      <td>
//        <DropDownList style={{ width: "100px" }} data={ItemCategoriesDD} textField="ddName" dataItemKey="ddID" value={ItemCategoriesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
//      </td>
//    );
//  }
//}     
//------------------------- List Template ------------------------------------------------------------------ 

const percentFormat = (value) => new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
const moneyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);

const StateContext = React.createContext([
  {
    TotalPrice: 0,
    ItemCount: 0,
    SubTotalPrice: 0,
    TotalTaxAmount: 0,
  },
  () => { },
]);
let useAlt = false;

class ScannerPage extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    //trace("ScannerPage");
    if (!SessionInfo.session || SessionInfo.session === '')
      this.props.navigate("/AppMain");
    else {
      if (SessionInfo.ScannerPage) { // Data saved for this session
        this.state = SessionInfo.ScannerPage;
        this.loadExistingPage = true;
      }
      else {
        this.loadExistingPage = false;
      }
      //trace("start Bind");
      //this.getCustomerRecords = this.getCustomerRecords.bind(this);
      //this.saveRecord = this.saveRecord.bind(this);
      //this.clearRecord = this.clearRecord.bind(this);
      //this.deleteRecord = this.deleteRecord.bind(this);
      //trace("Bind Keys");
      this.createKeys = CreateKeys.bind(this);
      //trace("finish Bind");  
      //this.handleScan = this.handleScan.bind(this)  
    }
  }
  menuAnchor = React.createRef();
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    CustomerID: 0,
    DeviceProfileID: 0,
    EmailReceipts: false,
    EmailPromotions: false,
    SMSReminders: false,
    SoundOn: false,
    AutoScanLocation: false,
    FullScreenReader: false,
    MaximumPurchaseAmount: 0,
    PersonPhotoID: 0,
    RoleID: 0,

    CustomOrderStatus: 0,
    OrderCreationDate: 0,
    OrderPaidDate: 0,
    AmountOwing: 0,
    ItemCount: 0,

    //--------------------------------
    CustomOrderID: 0,
    CustomOrderType: 0,
    TotalQuantity: 0,
    TotalNetWeight: 0,
    TotalPrice: 0,
    AmountPaid: 0,
    TotalAmount: 0,
    DiscountAmount: 0,
    CreditAmountUsed: 0,
    RefundAmount: 0,
    TipAmount: 0,
    TotalTaxAmount: 0,
    TotalTax2Amount: 0,
    TotalTax3Amount: 0,
    TotalDiscountAmount: 0,
    TotalCost: 0,
    TotalTime: 0,
    StartDateTime: 0,
    EndDateTime: 0,
    PaymentMethodID: 0,
    IndividualItems: 0,
    PaymentVerifiedID: 0,
    //--------------------------------
    // CustomerAccounts
    Amount: 0,
    TransactionCount: 0,
    MinusTransactions: 0,
    PlusTransactions: 0,
    TotalMinusAmount: 0,
    TotalPlusAmount: 0,
    MaximumAmount: 0,
    LastUsedOn: 0,
    CustomerAccountStatus: 0,
    //---- New ----   
    SubTotalPrice: 0,
    total: 0,
    //--------------------------------

    string: '',

    StoreName: 'Vancouver East',
    Address: '123 Main Street, Vancouver',
    FirstName: '',
    AdditionalName: '',
    LastName: '',
    EmailAddress: '',
    PhoneNumber: '',
    PhoneNumber2: '',

    selectedTopTab: 0,
    selectedBottomTab: 0,
    stateHasChanged: false,

    imgVideoVisible: false,
    showScan: true,
    result: 'No result',
    appDisplay: false,

    OrderItems: [],
    orderItemsSkip: 0,
    orderItemsTake: 8,

    isMainSearch: true,
    currentCustomerID: 0,
    //PersonPhoto: '',
    PersonPhotoURL: undefined,
    isWalletUser: false,
    contractsVisible: false,
    StoreLocationID: 0,
    selectCustomerID: 0,

    CustomOrders: [],
    customOrdersSkip: 0,
    customOrdersTake: 8,
    receiptFilter: { logic: "and", filters: [] },
    receiptSort: [],

    Customers: [],
    customersSkip: 0,
    customersTake: 18,

    Roles: [],
    CustomerAccountStatusInfo: [],
    CreditAmount: 0,

    showMenu: false,
    showNotify: false,

    DocumentTypes: [{ ddName: "jpg", ddID: 1 }, { ddName: "jpeg", ddID: 2 }, { ddName: "png", ddID: 3 }],
  };
  vertSplitC = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":true,"resizable":true,"scrollable":true,"size":"50%"}]');
  vertSplitO = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"50%"}]');

  inputReference = React.createRef();
  createKeys;
  loadExistingPage = false;
  lastSelectedPID = 0;
  barcodeReader = undefined;
  selectedDeviceId = undefined;
  barcode = '';
  notifyTimer = 0;
  menuKeep = false;
  //activeColor = "#3e80ed";
  //inactiveColor = "#BBBDC7";
  //okColor = "#00FF00";

  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
    trace(`Component Did Mount - existing: ${this.loadExistingPage}, reset: ${SessionInfo.ResetOrder}`);
    SessionInfo.currentPage = "ScannerPage";
    this.setState({ showMenu: false });
    //SessionInfo.currSaveFunc = this.saveRecord;
    //SessionInfo.currDeleteFunc = this.deleteRecord;
    //SessionInfo.currClearFunc = this.clearRecord;
    //SessionInfo.currAddGridRowFunc = undefined;
    //SessionInfo.currExcelExportFunc = undefined;
    //SessionInfo.currExpandLPane = undefined;
    //SessionInfo.currCollapseLPane = undefined;
    //SessionInfo.searchFunc = this.searchCurrent;
    //SessionInfo.currRefreshGridFunc = this.searchCurrent;
    //await this.getCustomerRecord(1);
    //----------------------------------
    //this.setState({ verticalPanes: this.vertSplitC });
    //trace("Get Image: " + this.state.PersonPhotoID);
    let image = undefined;
    ////let imageURL = undefined;
    ////let byteArray = undefined;
    ////let CD = await CallOM("GetImageXX", 0, this.state.PersonPhotoID);
    ////if (CD.d) {
    ////  //trace("Image: " + JSON.stringify(CD.d));
    ////  //const byteArray = CD.d.Item2;
    ////  // Image Preparation
    ////  const byteCharacters = atob(CD.d.Item2);
    ////  const byteNumbers = new Array(byteCharacters.length);
    ////  for (let i = 0; i < byteCharacters.length; i++) {
    ////    byteNumbers[i] = byteCharacters.charCodeAt(i);
    ////  }
    ////  byteArray = new Uint8Array(byteNumbers);
    ////  //this.setState({ PersonPhoto: byteArray }); 
    ////  image = new Blob([byteArray], { type: 'image/jpeg' });
    ////  imageURL = URL.createObjectURL(image);
    ////  this.setState({ PersonPhotoURL: imageURL });
    ////  //trace("Photo: " + JSON.stringify(this.state.PersonPhoto));
    ////  //trace("URL: " + JSON.stringify(this.state.PersonPhotoURL));
    ////}
    if (SessionInfo.ResetOrder === true) {
      SessionInfo.ResetOrder = false;
      SessionInfo.CustomOrderID = 0;      
      this.setState({ CustomOrderID: 0 });
      this.setState({ OrderItems: [] });
      this.setState({ SubTotalPrice: 0 });
      this.setState({ TotalTaxAmount: 0 });
      this.setState({ TotalPrice: 0 });
      this.forceUpdate();
    }
    else {
      if (this.loadExistingPage === true)
        this.forceUpdate();
      else {
        this.state.selectCustomerID = SessionInfo.PersonInfo.CustomerID;
        this.lastSelectedPID = this.state.selectCustomerID;
        //if (SessionInfo.SelectedCustomerID > 0) {
        //  trace("Get Customer Info for: " + SessionInfo.SelectedCustomerID);
        //  await this.getCustomerRecord(SessionInfo.SelectedCustomerID);
        //  trace("Get Record Complete");
        //  displayMessage("GREEN");
        //  SessionInfo.SelectedCustomerID = 0;
        //}
        //await this.getCustomerRecords();
        //if (this.state.CustomerAccountStatusInfo.length <= 1) {
        //  let CD = await GetDropdownData(545784);
        //  this.setState({ CustomerAccountStatusInfo: CD.d });
        //}
        //if (this.state.Roles.length <= 1) {
        //  let CD = await GetDropdownData(131286);
        //  this.setState({ Roles: CD.d });
        //}
      }
      await this.getOrderItemRecords();
    }
    //------------------------------------------------------------- 
    this.barcodeReader = new BrowserBarcodeReader(); // Investigate hing paramaters for this - type of barcode?
    this.setState({ imgVideoVisible: false });
    this.runMonitorProcess = true;
    this.monitorProcess();
    this.closePopups(1);
  }
  componentWillUnmount() {
    if (SessionInfo.session !== '')
      SessionInfo.ScannerPage = this.state;
    this.runMonitorProcess = false;
    if (this.timeout)
      clearTimeout(this.timeout);
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // Barcode   
  initBarcode() {
    //trace("init barcode");
    //window.addEventListener('load', function () {
    trace('ZXing code reader initialized');
    this.barcodeReader.getVideoInputDevices()
      .then((videoInputDevices) => {
        //const sourceSelect = document.getElementById('sourceSelect');
        //trace(`Input Devices: ${videoInputDevices.length}`);
        trace(`Input Devices: ${JSON.stringify(videoInputDevices)}`);
        OMTrace(1, `Input Devices: ${JSON.stringify(videoInputDevices)}`);
        displayMessage(`Input Devices: ${videoInputDevices.length}`);
        this.selectedDeviceId = videoInputDevices[0].deviceId
        if (videoInputDevices.length > 1) {
          videoInputDevices.forEach((element) => {
            const sourceOption = document.createElement('option')
            sourceOption.text = element.label
            sourceOption.value = element.deviceId
            //sourceSelect.appendChild(sourceOption)
          })
          if (videoInputDevices[1]) {
            this.selectedDeviceId = videoInputDevices[1].deviceId
          }
          //sourceSelect.onchange = () => {
          //  this.selectedDeviceId = sourceSelect.value;
          //}
          //const sourceSelectPanel = document.getElementById('sourceSelectPanel')
          //sourceSelectPanel.style.display = 'block'
        }

        //trace(`Add Click Event`);
        //document.getElementById('scanButton').addEventListener('click', () => {
        //  trace("Start Decode device: " + selectedDeviceId); 
        //  this.setState({ imgVideoVisible: 'false' });
        //  displayMessage("Start Decode  device: " + selectedDeviceId);
        //  codeReader.decodeOnceFromVideoDevice(selectedDeviceId, 'video').then((result) => {
        //    trace(result);
        //    this.barcode = result.text;
        //    document.getElementById('result').textContent = result.text;
        //    codeReader.reset();
        //    this.finishScan();
        //  }).catch((err) => {
        //    console.error("Barcode Listener: " + err);
        //    document.getElementById('result').textContent = err;
        //  })
        //  trace(`Started continuous decode from camera with id ${selectedDeviceId}`)
        //})

        //document.getElementById('resetButton').addEventListener('click', () => {
        //  trace("Reset Decode");
        //  document.getElementById('result').textContent = '';
        //  this.barcodeReader.reset();
        //  trace('Reset.')
        //})

      })
      .catch((err) => {
        console.error("addEventListener: " + err)
      })
    //})
  }
  onOrder = () => {
    this.props.navigate("/OrderPage");
  }
  onStartScan = () => {
    document.documentElement.style.setProperty('--scan-height', '340px');
    this.setState({ imgVideoVisible: true });
    this.initBarcode();
    //trace("Start Decode device: " + this.selectedDeviceId);
    //displayMessage("Start Decode  device: " + this.selectedDeviceId);
    this.setState({ showScan: false });
    this.barcodeReader.decodeOnceFromVideoDevice(this.selectedDeviceId, 'video').then((result) => {
      trace(result);
      this.barcode = result.text;
      //document.getElementById('result').textContent = result.text;
      this.barcodeReader.reset();
      this.finishScan();
    }).catch((err) => {
      console.error("Barcode Listener: " + err);
      //document.getElementById('result').textContent = err;
    })
    //trace(`Started continuous decode from camera with id ${this.selectedDeviceId}`)
  }
  finishScan = async () => {
    //codeReader.reset();
    trace("Barcode: " + this.barcode);
    //displayMessage("Barcode: " + this.barcode);
    this.setState({ imgVideoVisible: false });
    document.documentElement.style.setProperty('--scan-height', '40px');
    this.setState({ showScan: true });
    await this.addOrderItem(this.barcode);
  }
  onCancelScan = async () => {
    trace("Cancel Scan ");
    trace("Reset Decode");
    document.documentElement.style.setProperty('--scan-height', '40px');
    //document.getElementById('result').textContent = '';
    this.barcodeReader.reset();
    this.setState({ imgVideoVisible: false });
    this.setState({ showScan: true });
    await this.addOrderItem('11020200011');
  }
  //----------------------------------
  runMonitorProcess = false;
  setTimer() {
    if (SessionInfo.loggedOn === true && this.runMonitorProcess === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this), 200); // 200 microseconds  
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess = async (force) => { // Note - Monitor only runs if logged on
    if (force)
      this.runMonitorProcess = true;
    //trace(`monitor logoff: ${SessionInfo.forceLogoff}, On: ${SessionInfo.loggedOn}`); 
    if (SessionInfo.forceLogoff === true) {
      SessionInfo.forceLogoff = false;
      this.props.navigate("/Login");
    }
    if (this.runMonitorProcess === true && SessionInfo.loggedOn === true) {
      this.setTimer();
      if (1 === 1 || document.hasFocus() === true) { // Execute monitor process
        //---- Ping and Notification Processing -------------
        if (SessionInfo.setShowNotify > 0 && SessionInfo.notifyMessage.length > 0) {
          this.setState({ notifyMessage: SessionInfo.notifyMessage });
          SessionInfo.notifyTimer = SessionInfo.notifyTime;
          this.setState({ showNotify: true }); // Show the Message 
          if (SessionInfo.notifyRing && (SessionInfo.SoundOn === true || SessionInfo.SoundOverride === true)) {
            //trace(`Play Notify Sound: ${SessionInfo.notifyRing}`);
            try {
              await SessionInfo.notifyRing.play();
            }
            catch (Ex) {
              trace(`Ring Ex: ${Ex}`);
            }
            SessionInfo.notifyRing = undefined;
            SessionInfo.SoundOverride = false;
          }
        }                   
        SessionInfo.setShowNotify = 0;
        if (SessionInfo.notifyTimer > 0) {
          if (--SessionInfo.notifyTimer <= 0)
            this.setState({ showNotify: false });
        } else if (SessionInfo.showNotify === true)
          this.setState({ showNotify: false }); // Hide the Notify Message
        if (SessionInfo.pingCounter++ > 50) {
          SessionInfo.pingCounter = 0;
          let CD = await CallOM("PingApp",SessionInfo.StoreLocationID,SessionInfo.CustomOrderID,CTYP.STR,"");
          if (CD && CD.d && CD.d.rows) {
            //trace(`Return from PingApp - CD: ${JSON.stringify(CD)}`);
            let rows = CD.d.rows;
            for (let ix = 0; ix < rows.length; ix++) {
              let row = rows[ix];
              SetNotify(row["NotificationMessage"],row["NotificationTypeID"]);
              SessionInfo.AppNotificationID = row["AppNotificationID"];
            }
          }
        }
      }
      //---- End of Notification Processing -------------
    }
  }
  acknowledgeMessage = async () => {
    let CD = await CallOM("AcknowledgeNotification",0,SessionInfo.AppNotificationID);
    this.setState({ showNotify: false });
    SessionInfo.notifyTimer = 50;
    SessionInfo.acknowledge = false;
    this.forceUpdate();
  }
  // Test implementation
  //shouldComponentUpdate(nextProps, nextState) {
  //  return !equals(nextProps, this.props); // equals() is your implementation
  //}
  //handleScan(data) {
  //  this.setState({
  //    result: data,
  //  })
  //}
  //handleError(err) {
  //  console.error(err)
  //}
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  CommandCell;
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  OrderHeader = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewHeader className='listOrderHeader'>
        <div className='listHeaderLeft'>
          <span style={{ fontSize: 'large' }}>Total &nbsp;&nbsp;&nbsp;</span>
          <span style={{ fontSize: 'large', fontWeight: 'bold' }}>{moneyFormat(this.state.TotalPrice)}</span>
          <br style={{ lineHeight: '2px', fontSize: 'small' }} />
          {this.state.ItemCount === 0 ?
            <span style={{ left: '210px', fontSize: 'medium' }}>No Items Yet</span> : null}
          {this.state.ItemCount === 1 ?
            <span style={{ left: '210px', fontSize: 'medium' }}>1 Item Selected</span> : null}
          {this.state.ItemCount > 1 ?
            <span style={{ left: '210px', fontSize: 'medium' }}>{this.state.ItemCount} Items</span> : null}
          {this.state.TotalQuantity > this.state.ItemCount ?
            <span style={{ fontSize: 'medium' }}>&nbsp;({this.state.TotalQuantity})</span> : null}
          <span></span>
        </div>
        <div className='listHeaderRight'>
          <Button id="checkoutButton" icon="cart" look="outline" className="largeButton" onClick={this.onCheckout} >&nbsp;Checkout &nbsp;</Button>
        </div>
      </ListViewHeader>
    );
  }
  OrderItemFormat = props => {
    let item = props.dataItem;
    if (!item.imageVal) {
      let value = item.Image;
      if (!value) { // Note - blank
        value = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD32iiigAooooAKKKKACiiigD//2Q==";
      }
      const byteCharacters = atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray], { type: 'image/jpeg' });
      item.imageVal = URL.createObjectURL(image);
    }
    //let item = props.dataItem;
    //let imageVal = undefined;
    //if (item["Image"]) {
    //  let value = item["Image"];
    //  //trace("ImageCell value: " + value);
    //  const byteCharacters = atob(value);
    //  const byteNumbers = new Array(byteCharacters.length);
    //  for (let i = 0; i < byteCharacters.length; i++) {
    //    byteNumbers[i] = byteCharacters.charCodeAt(i);
    //  }
    //  const byteArray = new Uint8Array(byteNumbers);
    //  let image = new Blob([byteArray], { type: 'image/jpeg' });
    //  //trace("image: " + JSON.stringify(image));
    //  imageVal = URL.createObjectURL(image);
    //}
    return (
      <div className="listRow">
        <div className="listCol1">
          <span style={{ fontSize: 16, color: "black" }} >{item.ItemName}</span><br />
          <span style={{ fontSize: 12, color: "black" }} >{item.ItemDescription}</span>
        </div>
        <div className="listColR">
          <div className="listCol2">
            <span style={{ fontSize: 16, color: "black" }} >{moneyFormat(item.Price)}</span> <br />
            {/*  <span style={{ fontSize: 12, color: "black" }} >{item.BarcodeNumber}</span>*/}
          </div>
          <div className="listOMImageDiv1">
            <Avatar rounded='large' size='large' type='image' style={{ height: '76px', width: '76px', }}>
              <img src={item.imageVal} alt='' style={{ height: '76px', width: '76px', }} title={`${item.ItemName}`} />
            </Avatar>
          </div>
          <div className="listCol5">
            {/*  <img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Delete the current entry" className="functionImage" onClick={(e) => window.confirm(`Delete ${item.ItemName.trimRight()}?`) && this.onDeleteItem(item)} /> */}
            <ButtonClear alt="" fill={SessionInfo.activeColor} id="DeleteG" title={`Delete ${item.ItemName}`} className="functionImage" onClick={(e) => window.confirm(`Delete ${item.ItemName.trimRight()}?`) && this.onDeleteItem(item)} />
          </div>
        </div>
      </div>
    );
  }
  OrderItemFormatAlt = props => {
    let item = props.dataItem;
    if (!item.imageVal) {
      let value = item.Image;
      if (!value) { // Note - blank
        value = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD32iiigAooooAKKKKACiiigD//2Q==";
      }
      const byteCharacters = atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray], { type: 'image/jpeg' });
      item.imageVal = URL.createObjectURL(image);
    }
    //let item = props.dataItem;
    //let imageVal = undefined;
    //if (item["Image"]) {
    //  let value = item["Image"];
    //  //trace("ImageCell value: " + value);
    //  const byteCharacters = atob(value);
    //  const byteNumbers = new Array(byteCharacters.length);
    //  for (let i = 0; i < byteCharacters.length; i++) {
    //    byteNumbers[i] = byteCharacters.charCodeAt(i);
    //  }
    //  const byteArray = new Uint8Array(byteNumbers);
    //  let image = new Blob([byteArray], { type: 'image/jpeg' });
    //  //trace("image: " + JSON.stringify(image));
    //  imageVal = URL.createObjectURL(image);
    //}
    return (
      <div className="listRow">
        <div className="listCol1">
          <span style={{ fontSize: 16, color: "black" }} >{item.ItemName}</span><br />
          <span style={{ fontSize: 12, color: "black" }} >{item.ItemDescription}</span>
        </div>
        <div className="listColR">
          <div className="listCol2">
            <span style={{ fontSize: 16, color: "black" }} >{moneyFormat(item.Price)}</span> <br />
            {/*  <span style={{ fontSize: 12, color: "black" }} >{item.BarcodeNumber}</span>*/}
          </div>
          <div className="listOMImageDiv1">
            <Avatar rounded='large' size='large' type='image' style={{ height: '76px', width: '76px', }}>
              <img src={item.imageVal} alt='' style={{ height: '76px', width: '76px', }} title={`${item.ItemName}`} />
            </Avatar>
          </div>
          <div className="listCol5">
            {/*  <img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Delete the current entry" className="functionImage" onClick={(e) => window.confirm(`Delete ${item.ItemName.trimRight()}?`) && this.onDeleteItem(item)} /> */}
            <ButtonClear alt="" fill={SessionInfo.activeColor} id="DeleteG" title={`Delete ${item.ItemName}`} className="functionImage" onClick={(e) => window.confirm(`Delete ${item.ItemName.trimRight()}?`) && this.onDeleteItem(item)} />
          </div>
        </div>
      </div>
    );
  }
  //lastCategory = "";
  //OrderItemFormat = props => {
  //  let item = props.dataItem;
  //  if (!item.imageVal) {
  //    let value = item.Image;
  //    if (!value) { // Note - blank
  //      value = "/9j/4AAQSkZJRgABAQEAYABgAAD/2wBDAAgGBgcGBQgHBwcJCQgKDBQNDAsLDBkSEw8UHRofHh0aHBwgJC4nICIsIxwcKDcpLDAxNDQ0Hyc5PTgyPC4zNDL/2wBDAQkJCQwLDBgNDRgyIRwhMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjIyMjL/wAARCAAZABkDASIAAhEBAxEB/8QAHwAAAQUBAQEBAQEAAAAAAAAAAAECAwQFBgcICQoL/8QAtRAAAgEDAwIEAwUFBAQAAAF9AQIDAAQRBRIhMUEGE1FhByJxFDKBkaEII0KxwRVS0fAkM2JyggkKFhcYGRolJicoKSo0NTY3ODk6Q0RFRkdISUpTVFVWV1hZWmNkZWZnaGlqc3R1dnd4eXqDhIWGh4iJipKTlJWWl5iZmqKjpKWmp6ipqrKztLW2t7i5usLDxMXGx8jJytLT1NXW19jZ2uHi4+Tl5ufo6erx8vP09fb3+Pn6/8QAHwEAAwEBAQEBAQEBAQAAAAAAAAECAwQFBgcICQoL/8QAtREAAgECBAQDBAcFBAQAAQJ3AAECAxEEBSExBhJBUQdhcRMiMoEIFEKRobHBCSMzUvAVYnLRChYkNOEl8RcYGRomJygpKjU2Nzg5OkNERUZHSElKU1RVVldYWVpjZGVmZ2hpanN0dXZ3eHl6goOEhYaHiImKkpOUlZaXmJmaoqOkpaanqKmqsrO0tba3uLm6wsPExcbHyMnK0tPU1dbX2Nna4uPk5ebn6Onq8vP09fb3+Pn6/9oADAMBAAIRAxEAPwD32iiigAooooAKKKKACiiigD//2Q==";
  //    }
  //    const byteCharacters = atob(value);
  //    const byteNumbers = new Array(byteCharacters.length);
  //    for (let i = 0; i < byteCharacters.length; i++) {
  //      byteNumbers[i] = byteCharacters.charCodeAt(i);
  //    }
  //    const byteArray = new Uint8Array(byteNumbers);
  //    let image = new Blob([byteArray], { type: 'image/jpeg' });
  //    item.imageVal = URL.createObjectURL(image);
  //  }
  //  let showCategory = false;
  //  let categoryName = '';
  //  let categoryDesc = '';
  //  let categoryImage = undefined;
  //  //trace(`CategoryID: ${item.ProductItemCategoryID}`);
  //  //if (item.ProductItemCategoryID !== this.lastCategory) {
  //  //  showCategory = true;
  //  //  let ix = this.state.Categories.findIndex(cat => cat.ProductItemCategoryID === item.ProductItemCategoryID);
  //  //  categoryName = this.state.Categories[ix].ProductItemCategory;
  //  //  categoryDesc = this.state.Categories[ix].Description;
  //  //  this.lastCategory = item.ProductItemCategoryID;
  //  //  // Image
  //  //  const byteCharacters = atob(this.state.Categories[ix].ImageID);
  //  //  const byteNumbers = new Array(byteCharacters.length);
  //  //  for (let i = 0; i < byteCharacters.length; i++) {
  //  //    byteNumbers[i] = byteCharacters.charCodeAt(i);
  //  //  }
  //  //  const byteArray = new Uint8Array(byteNumbers);
  //  //  let imageVal = new Blob([byteArray], { type: 'image/jpeg' });
  //  //  categoryImage = URL.createObjectURL(imageVal);
  //  //}

  //  return (
  //    <div>
  //      {showCategory === true ?
  //        <div style={{ fontSize: 18, color: "black", fontWeight: "bold", backgroundColor: "linen", height: '74px', width: '100%' }}>
  //          <div className="listOMCatL">
  //            <Avatar rounded='large' size='large' type='image' style={{ height: '74px', width: '74px', marginLeft: '18px' }}>
  //              <img src={categoryImage} alt='' style={{ height: '74px', width: '74px', }} title={`${categoryName}}`} />
  //            </Avatar>
  //          </div>
  //          <div className="listOMCatR">
  //            <span style={{ fontSize: 22, color: "black", fontWeight: "bold" }} >{categoryName}</span>
  //            <br style={{ lineHeight: '0px', }} />
  //            <p style={{ fontSize: 18, color: "black", fontWeight: "normal", wordWrap: "true", lineHeight: "100%" }} >{categoryDesc}</p>
  //          </div>
  //        </div> : null}
  //      <div className="listOrderRow" onClick={(e) => this.addItemToOrder(item)}>
  //        <div className="listCol1">
  //          <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{item.ItemName}&nbsp;&nbsp;&nbsp;
  //            {item.IsFavorite ?
  //              <Favorite alt="" fill={SessionInfo.addColor} id="FavoriteSelectBtn" title={`Set ${item.ItemName} as Favorite`} className="favoriteImage" onClick={(e) => this.addItemToFavorites(item)} />
  //              :
  //              <FavoriteSelect alt="" fill={SessionInfo.addColor} id="FavoriteSelectBtn" title={`Set ${item.ItemName} as Favorite`} className="favoriteImage" onClick={(e) => this.addItemToFavorites(item)} />}
  //          </span><br />
  //          <span style={{ fontSize: 13, color: "black" }}>{item.ItemDescription}</span>
  //        </div>
  //        <div className="listColR">
  //          <div className="listCol2">
  //            <span style={{ fontSize: 18, color: "black", fontWeight: "bold" }} >{moneyFormat(item.ItemPrice)}</span> <br style={{ lineHeight: '0px', }} />
  //            {item.IsSelected ?
  //              <span style={{ fontSize: 16, color: "#3e80ed", fontWeight: "bold" }} >{moneyFormat(item.ItemTotal)}</span> :
  //              <div>
  //                <span> &nbsp; &nbsp; &nbsp; </span>
  //                {item.IsQuick ?
  //                  <CirclePlus alt="" fill={SessionInfo.quickColor} id="AddItemBtn" title={`Quick Add Item ${item.ItemName} to the Order`} className="functionImage" onClick={(e) => this.quickAddItemToOrder(item)} /> : null}
  //              </div>
  //            }
  //            <div className="iconLine">
  //              {item.IsSelected ?
  //                <CartChoose alt="" fill={SessionInfo.removeColor} id="ChooseItemBtn" title={`Choose what to do with Item ${item.ItemName}`} className="functionImage" onClick={(e) => this.choiceForItem(item)} />
  //                :
  //                <CartAdd alt="" fill={SessionInfo.addColor} id="AddItemBtn" title={`Add Item ${item.ItemName} to the Order`} className="functionImage" onClick={(e) => this.addItemToOrder(item)} />}
  //              {item.IsPromotion ?
  //                <Promotion alt="" fill={SessionInfo.attnColor} id="ShowPromoBtn" title={`There is a Promotion for ${item.ItemName}`} className="functionImageR" onClick={(e) => this.addShowPromotion(item)} /> : null}
  //            </div>
  //          </div>
  //          <div className="listOMImageDiv1">
  //            <Avatar rounded='large' size='large' type='image' style={{ height: '76px', width: '76px', }}>
  //              <img src={item.imageVal} alt='' style={{ height: '76px', width: '76px', }} title={`${item.ItemName} (Photo Coming}`} onClick={(e) => this.addItemToOrder(item)} />
  //            </Avatar>
  //          </div>
  //        </div>
  //      </div>
  //    </div>
  //  );
  //}
  OrderFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listOrderFooter'>
        <div className='listFooterLeft'>
          <span className='listName'>SubTotal </span>
          <span className='listMoney'>{moneyFormat(this.state.SubTotalPrice)}</span>
          <br />
          <span className='listName'>Tax </span>
          <span className='listMoney'>{moneyFormat(this.state.TotalTaxAmount)}</span>
          <br />
          <span className='listName'>TOTAL </span>
          <span className='listMoney'>{moneyFormat(this.state.TotalPrice)}</span>
        </div>
        <div className='listFooterRight'>
          <Button id="checkoutButton2" icon="cart" look="outline" className="largeButton" onClick={this.onCheckout} >&nbsp;Place Order &nbsp;</Button>
        </div>
      </ListViewFooter>
    );
  }
  //OrderFooter = () => {
  //  return (
  //    <ListViewFooter className='listFooter'>
  //      <div className='footerLeft'>
  //        <span>SubTotal </span>
  //        <span>{moneyFormat(this.state.SubTotalPrice)}</span>
  //        <br />
  //        <span style={{ left: '180px', }}>Tax </span>
  //        <span>{moneyFormat(this.state.TotalTaxAmount)}</span>
  //      </div>
  //      <div className='footerRight'>
  //        <span>TOTAL </span>
  //        <span>{moneyFormat(this.state.TotalPrice)}</span>
  //        <br />
  //        <Button id="checkoutButton" icon="restore" style={{ cursor: 'pointer', fontSize: 'large', color: 'black', fontWeight: 'bold', borderRadius: '10px', left: '80px', }} onClick={this.onCheckout} >&nbsp;&nbsp; Checkout &nbsp;&nbsp;</Button>
  //      </div>
  //    </ListViewFooter>
  //  );
  //}

  onDeleteItem = props => {
    displayError(`Delete Item: ${JSON.stringify(props.ItemDescription)}`);
    trace(`Delete ID: ${JSON.stringify(props.CustomOrderItemID)}`);
    this.setState({
      OrderItems: this.state.OrderItems.filter(e => e.CustomOrderItemID !== props.CustomOrderItemID)
    });
    this.deleteOrderStoreItem(props.CustomOrderItemID);
  }
  //deleteItem = (editItem) => {
  //  //displayError(`OrderItemID: ${editItem.CustomOrderItemID}`);
  //  this.setState({
  //    data: this.state.OrderItems.filter(e => e.CustomOrderItemID !== editItem.CustomOrderItemID)
  //  });
  //}
  //--------------------App Functions -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------   
  getStoreItemRecords = async (search) => { // Get the available Participants
    //let CD;
    if (!search)
      search = '';
    let CD = await CallOM("GetProductItems", 0, 0, 0, search);
    this.setState({ ItemSelect: "" });
    trace("Store Items: " + JSON.stringify(CD.d));
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      try {
        this.setState({ ItemSelect: search });
        const StoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
        this.setState({ StoreItems });
        this.state.saveStoreItems = StoreItems;
      } catch (error) {
        await OMTrace(2, `Failure in getStoreItemRecords - Error: ${error}`);
      }
    }
  }
  getOrderItemRecords = async (search) => { // Get the order Items according to the search
    if (!search)
      search = '';
    let CD = await CallOM("GetCustomOrderItems", 0, SessionInfo.CustomOrderID, 0, search); // Get OrderItems with the image (MOID=0)
    this.setState({ ItemSelect: "" });
    trace("Order Items: " + JSON.stringify(CD.d));
    trace("Get Order Items - o: " + CD.x.o);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      try {
        this.setState({ ItemSelect: search });
        const OrderItems = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
        this.setState({ OrderItems });
      } catch (error) {
        await OMTrace(2, `Failure in getOrderItemRecords - Error: ${error}`);
      }
    }
  }
  getAddOrderItemRecord = async (barCode) => {
    //let CD;
    //---------------       
    trace("CallGetAddStoreItem - Barcode: " + this.barcode);
    let CD = await CallOM("GetAddStoreItem", 0, 0, CTYP.STR, barCode);
    this.setState({ ItemSelect: "" });
    trace("Store Items: " + JSON.stringify(CD.d));
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      try {
        await this.getOrderItemRecords(''); // refetch whole list
        //this.setState({ ItemSelect: search });
        //const StoreItems = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
        //this.setState({ StoreItems });
        //this.state.saveStoreItems = StoreItems;
      } catch (error) {
        await OMTrace(2, `Failure in getAddOrderItemRecord - Error: ${error}`);
      }
    }
  }
  deleteOrderStoreItem = async (customOrderItemID) => {
    trace(`DeleteOrderItem - ID: ${customOrderItemID}`);
    await CallOM("DeleteOrderItem",0,customOrderItemID,0);
    await this.getCurrentTotal();
  }
  addOrder = async (seatName) => {
    let CD;
    try {
      if (SessionInfo.CustomOrderID === 0) { // Add a CustomOrder
        trace(`Create CustomOrder (1) - StoreLocationID: ${SessionInfo.StoreLocationID}`);
        let orderType = 4; // Order at Table
        let individualItems = true;
        // int: 1 // string:2  decimal:3  double: 4  datetime:5  bool:6  unknown:9
        CD = await CallOM("CreateCustomOrderTab",0,SessionInfo.StoreLocationID,CTYP.TypParm,[TYP.INT,orderType,TYP.INT,this.state.AddressID,TYP.STR,"",TYP.STR,seatName,TYP.INT,SessionInfo.CurrentTableID,6,individualItems]);
        if (CD.x.o === 0)
          await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
        else if (CD.x.o < 9500) {
          SessionInfo.CustomOrderID = CD.d.CustomOrderID;     
          this.setState({ TableName: CD.d.TableName });
          this.setState({ SeatCount: CD.d.SeatCount });
          SessionInfo.CurrentTableName = CD.d.TableName;
          SessionInfo.SeatCount = CD.d.SeatCount;
          if (SessionInfo.SeatName === '')
            SessionInfo.SeatName = `Seat #: ${SessionInfo.SeatCount}`;
          SessionInfo.ParentOrderID = CD.d.ParentOrderID;
          trace(`CreateCustomOrderTabID: ${SessionInfo.CustomOrderID}, TableName: ${this.state.TableName}`);      
        }
      }
    } catch (error) {
      await OMTrace(2, `Failure in addOrder in ScannerPage - Error: ${error}`);
    }
    return
  }
  addOrderItem = async (barCode) => {
    let CD;
    try {
      if (SessionInfo.CustomOrderID === 0) { // Add a CustomOrder 
        await this.addOrder('');
      }
      CD = await CallOM("AddOrderItem", 0, SessionInfo.CustomOrderID, CTYP.STR, barCode); // Process Promotion Information and any other related processing   
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        //trace("test1 data: " + JSON.stringify(CD.d));
        await this.getOrderItemRecords('');
        await this.getCurrentTotal();
        //JArray Rw = (JArray)CD.d;
        //CurrentCOI = new CustomOrderItem(new StoreItem(Rw));
        //CurrentCOI.RateTypeID = (int)Rw[6];
        //CurrentCOI.CustomOrderItemID = (int)Rw[8]; // Returned from GetStoreItem
        //CurrentCOI.RateDescription = CurrentCOI.ShortDescription = (string)Rw[9];
        //App.CurrentCOI = CurrentCOI;
        //bool isNew = true;
        //if (isNew === true) {
        //  CustomOrderItems.Insert(0, CurrentCOI);
        //  CD.x.o = 8000;
        //}
        //App.CartCount = CustomOrderItems.Count;
        //ScanItems = $"SCAN ITEMS ({App.CartCount})";
      }
      else {
        this.onStartScan(); // Retry the scan
      }
    } catch (error) {
      await OMTrace(2, `Failure in addOrderItem in ScannerPage - Error: ${error}`);
    }
    return CD;
  }
  getCurrentTotal = async () => {
    //let CD;
    //---------------       
    trace("CallgetCurrentTotal ");
    let CD = await CallOM("GetCustomOrderTotal", 0, SessionInfo.CustomOrderID, 0);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      try {
        let Row = CD.d;
        let SubTotalPrice = Row["TotalPrice"];
        this.setState({ SubTotalPrice });
        let TotalTaxAmount = Row["TotalTaxAmount"];
        this.setState({ TotalTaxAmount });
        let TotalPrice = SubTotalPrice + TotalTaxAmount;
        this.setState({ TotalPrice });
        let ItemCount = Row["ItemCount"];
        this.setState({ ItemCount });
      } catch (error) {
        await OMTrace(2, `Failure in getCurrentTotal - Error: ${error}`);
      }
    }
  }
  onCheckout = () => {
    if (this.state.TotalPrice > 0) {
      trace(`Goto CheckoutPage - CustomOrderID: ${SessionInfo.CustomOrderID}`);
      this.props.navigate("/CheckoutPage");
    }
    else {
      displayError(`No Items Added For Checkout (${moneyFormat(this.state.TotalPrice)})`);
    }
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  // Select Tabs
  //searchCurrent = async (search) => {
  //  if (this.state.selectedTopTab === 2) {
  //    displayMessage("Search Receipts");
  //    this.setState({ customOrdersSkip: 0 });
  //    this.setState({ customOrdersTake: 8 })
  //    this.setState({ receiptFilter: { logic: "and", filters: [] } });
  //    this.setState({ receiptSort: [] });
  //    await this.getCustomOrders(search);
  //  }
  //  else {
  //    displayMessage("Search Customers");
  //    await this.getCustomerRecords(search);
  //    this.setState({ customersSkip: 0 });
  //    this.setState({ customersTake: 18 })
  //  }
  //}
  //saveRecord = async () => {
  //  trace("Save Customer");
  //  let copyState = Object.assign({}, this.state);
  //  let type = 0;
  //  for (var prop in copyState) {
  //    //trace("Prop: " + prop + " type: " + type);
  //    if (prop === "string") {
  //      type = 1;
  //      copyState[prop] = undefined;
  //    }
  //    else if (prop === "selectedTopTab")
  //      type = 2;
  //    else if (type === 0) {
  //    }
  //    if (type === 2) {
  //      copyState[prop] = undefined;
  //    }
  //  }
  //  trace("copystate: " + JSON.stringify(copyState));
  //  await UpdateRow(1020151, 0, undefined, copyState, this.state.currentCustomerID, "CustomerID"); // Save to OM 
  //  await this.getCustomerRecords();
  //  //await this.getProjectRecords();
  //  this.setState({ stateHasChanged: false });
  //}
  //deleteRecord = async () => {
  //  //trace("Delete ScannerPage: " + this.state.CustomerID);
  //  let CD = await DeleteRow(543619, this.state.currentCustomerID);
  //  trace("Delete Customer - o: " + CD.x.o);
  //  this.setState({ stateHasChanged: false });
  //}
closePopups = (src) => {
    trace(`close Popups - ${src}, keep: ${this.keepMenu}`);
    this.setState({ showNotify: false });
    if (this.keepMenu === false)
      this.setState({ showMenu: false });
    else
      this.keepMenu = false;
  }
  onMenuItemClick = async (event) => {
    trace(`menu event Item: ${event.item.text}`);
    if (SessionInfo.currentItem !== undefined) {
      //trace('Current Item: ' + SessionInfo.currentItem.text);
      SessionInfo.currentItem.selected = false;
    }
    SessionInfo.currentItem = event.item;
    trace('2 Current Item: ' + SessionInfo.currentItem.text);
    event.item.selected = true;
    event.item.expanded = true;
    //trace(`Event - name: ${JSON.stringify(event.item)}`);
    SessionInfo.MenuSelect = event.item;
    this.keepMenu = false;
    this.setState({ showMenu: false });
    if (SessionInfo.menuAction !== undefined) {
      await SessionInfo.menuAction(); // onMenuItemClick in AppMain.js
    }
    this.forceUpdate();
  }
  openMenu = () => {
    this.closePopups(7);
    //trace(`open Menu - anchor: ${this.menuAnchor.current} - showMenu: ${this.state.showMenu}`);
    this.keepMenu = true;
    this.setState({ showMenu: true });
    trace(`open Menu - showMenu: ${this.state.showMenu}`);
  }
  clearRecord = () => {
    trace("Clear Screen - state: " + JSON.stringify(this.state));
    let type = 0;
    //Object.keys(this.state).map
    //  ((prop) => trace("Clear - " + prop + " type: " + type));
    for (var prop in this.state) {
      //trace("Clear - " + prop + " type: " + type);
      if (prop === "selectedTopTab")
        break;
      else if (prop === "string")
        type = 1;
      else if (type === 0) {
        let obj = {};
        obj[prop] = 0;
        this.setState(obj);
      }
      else if (type === 1) {
        let obj = {};
        obj[prop] = '';
        this.setState(obj);
      }
    }
    this.setState({ stateHasChanged: false });
  }
chgFldVal = (event) => {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
chgMoneyFldVal = (event) => {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g, '');
    this.setState({ [event.target.name]: newVal });
  }
chgPercentFldVal = (event) => {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g, '');
    //trace("percent newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setState({ stateHasChanged: true });
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setState({ stateHasChanged: true });
  }
chgCheckboxVal = (event) => {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setState({ stateHasChanged: true });
  }
  ClearMessage = async () => { // ClearDisplay, Clear Messages
    //trace("ClearMessage Pressed - for: " + SessionInfo.currentPage);
    this.setState({ MessagesValue: '' });
    SessionInfo.message = ''; // MessagesValue is set to SessionInfo.message in the Timer loop  
    this.setState({ showPopup: false });
  }
  PopupMessage = async () => {
    // Keep this code for example
    //var tag = document.createElement('div');
    //var newString = this.state.MessagesValue.replace(/<br\/>/g, "XXX");
    //trace("newString: " + newString);
    //tag.innerHTML = newString;
    //newString = tag.innerHTML.replace(/XXX/g, "&#10;");  
    //trace("newString: " + newString);
    //this.setState({ MessagesText: newString });
    if (this.state.showPopup === false)
      this.popupTimer = 50;
    else
      this.popupTimer = 0;
    this.setState({ showPopup: !this.state.showPopup });
    //this.setState({ dialogVisible: true });
  }
  //------------------------- Image Processing ------------------------------------------------------------------
  captureImageRef = () => this.inputReference.current.click();

  captureImage = async (event) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];
    trace("File: " + JSON.stringify(file));
    let ImageType = GetFileExtension(file.name);
    trace("ImageType: " + ImageType);
    ImageType = ImageType.toLowerCase();
    this.setState({ ImageType });
    let imageURL = URL.createObjectURL(file);
    this.setState({ PersonPhotoURL: imageURL });
    //this.setState({ ContractFileName: file.name });
    let reader = new window.FileReader();
    try {
      reader.readAsArrayBuffer(file);
      reader.onloadend = () => this.convertToBuffer(reader);
      displayMessage("Image: " + file.name + " Loaded");
    }
    catch (error) {
      trace("FileReader Error: " + error);
      displayError("Error on Image Read");
    } //catch      
    let ix = this.state.DocumentTypes.findIndex(c => c.ddName === ImageType);
    if (ix >= 0)
      this.setState({ DocumentTypeID: this.state.DocumentTypes[ix].ddID });
    else
      displayWarning("Unknown file type: " + ImageType);
  }
  convertToBuffer = async (reader) => { // File is converted to a buffer to prepare for uploading to OM
    const UploadFile = await Buffer.from(reader.result);
    //set this buffer -using es6 syntax          
    this.setState({ UploadFile });
    trace("UploadFile Length: " + UploadFile.length);
    await this.saveImageToServer(UploadFile);
  };

  GetProductItemImage = async (imageID) => {
    //trace("GetProductCategoryImage ID: " + imageID);
    let imageURL = await getImageFileFromServer(imageID);
    this.setState({ ProductItemPhotoURL: imageURL });
  }
  saveProductItemImageToServer = async (event) => {
    await this.saveImageToServer(this.StoreItemTableObjID, this.state.StoreItemID);
  }
  storeItemsPageChange = (event) => {
    this.setState({ storeItemsSkip: event.page.skip, storeItemsTake: event.page.take });
  }

  saveImageToServer = async (uploadFile) => {
    trace('saveImageToServer: ');
    if (this.isSavingDocument) {
      displayMessage("Document Save in Progress");
      return;
    }
    else if (!uploadFile)
      displayError('A file must be selected');
    else {
      this.isSavingDocument = true;
      displayMessage('Saving Image');
      //trace("Save Image " + JSON.stringify(this.state.UploadFile));
      //let type = this.state.UploadFile.type;  // THIS does not work - I don't understand why??
      //let buff = this.state.UploadFile.data;
      //trace("type " + JSON.stringify(type));
      //trace("buff " + JSON.stringify(buff));   
      //CDATA CD = await AccessOM.CallOM("SaveCustomerProfileImage", 0, 0, 0, NewImage);
      await CallOM("SaveCustomerProfileImage", 0, 0, 20, uploadFile, this.state.ImageType); // 17
      trace("Saved Image " + this.state.UploadFile.length);
      this.isSavingDocument = false;
    }
  }
  onCheckout = () => {
    //trace(`Goto CheckoutPage - CustomOrderID: ${SessionInfo.CustomOrderID}`);
    if (this.state.TotalPrice > 0) {
      //trace(`Goto CheckoutPage - CustomOrderID: ${SessionInfo.CustomOrderID}`);
      SessionInfo.PauseOPMonitor = true;
      //trace(`onCheckout - pauseGoTo: ${SessionInfo.PauseOPMonitor}`);
      this.props.navigate("/CheckoutPage");
    }
    else {
      displayMessage("Add some Items to your Order");
      //displayError(`No Items Added For Checkout (${moneyFormat(this.state.TotalPrice)})`);
    }
  }

  //------------------------- Render ------------------------------------------------------------------
  render() {
    if (!SessionInfo.session || SessionInfo.session === '')
      return (<Navigate to='/' />);
    return (                                 
      <div id="ScannerPage" className="pageMain">
        <div id="hdr" className="header">
          <div className="headerLogoLeft">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }} onClick={(e) => this.gotoLanding()} />
          </div>
          <div className="headerCenter" onClick={(e) => this.closePopups(6)}>
            {/*<span style={{ fontSize: 15, color: "black" }} >&nbsp;&nbsp;</span>*/}
            {/*<span style={{ fontSize: 14, color: "black" }} >Shopping At: </span>*/}
            <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >{this.state.StoreName}</span>
            {/*<br /><span style={{ fontSize: 14, color: "black" }} >{this.state.Address}</span>*/}
            <span style={{ fontSize: 10, color: "black" }} >&nbsp;&nbsp;Ver: {SessionInfo.appVersion}-{SessionInfo.Platform}</span>
            <div className="headerCenterMenu">
              <MenuBar alt="" fill={SessionInfo.headingTextColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} />
            </div>
            {/*{this.state.customOrder > 0 ?*/}
            <div>
              <span style={{ fontSize: 15, color: "black", fontWeight: "bold" }} >{this.state.TableName}</span>
              {this.state.SeatCount > 1 ?
                <span>
                  <span style={{ fontSize: 14, color: "black" }} >&nbsp;Seat:</span>
                  <span style={{ fontSize: 15, color: "black", fontWeight: "bold" }} >&nbsp;{this.state.SeatName}</span>
                  <span style={{ fontSize: 14, color: "black" }} >&nbsp;(</span>
                  <span style={{ fontSize: 14, color: "black", fontWeight: "bold" }} >{SessionInfo.SeatCount}</span>
                  <span style={{ fontSize: 14, color: "black" }} >&nbsp;seats)</span>
                </span> : null}
              <br />
            </div>
            <span style={{ fontSize: 16, color: "black", fontWeight: "bold" }} >Cart ({this.state.ItemCount})</span>
          </div>

          <div id="div3" className="headerLeftScan">
          {this.state.imgVideoVisible === true ?
            <div className="frameVid">
              <video id="video" width="300px" height="300px" margin='1' padding='1' style={{ border: '1px solid gray', }} />
            </div> : null}
            {this.state.showScan === true ?
              <Button id="scanButton" icon="restore" style={{ cursor: 'pointer', fontSize: 'large', color: 'black', backgroundcolor: 'cyan', fontWeight: 'bold', marginTop: '15px' }} onClick={this.onStartScan} >&nbsp;&nbsp; Scan Barcode &nbsp;&nbsp;</Button>
              :
              <Button id="resetButton" icon="refresh" style={{ cursor: 'pointer', fontSize: 'large', color: 'black', fontWeight: 'bold', marginTop: '15px' }} onClick={this.onCancelScan}>Cancel Scan</Button>
            }
          {/*  <Popup offset={this.popupOffset} show={this.state.showNotify} popupClass={'popup-content'} ><div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }}></div></Popup>*/}
          </div>
          {/* Message Notify Popup */}
          <Popup offset={SessionInfo.popupNotifyOffset} show={this.state.showNotify} popupClass={'popup-content'} >
            <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }} onClick={(e) => { this.acknowledgeMessage(); }} />
            {SessionInfo.acknowledge ?
              <div className="bottomButton">
                <Button icon="tell-a-friend" className="medButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
              </div> : null}
          </Popup>
          {/* Menu Popup */}
          <Popup anchor={this.menuAnchor.current} show={this.state.showMenu} popupClass={'popupMenuO'} >
            <div className="mainMenu">
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`, backgroundPosition: 'left top', backgroundRepeat: 'no-repeat', backgroundSize: '100px 60px' }} href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
              <div className="menuTree">
                {SessionInfo.HasScanAndPay === false ?
                  <br /> : null}
                {/*{SessionInfo.defaultLogon === true ?*/}
                {/*  <br /> : null}*/}
                <TreeView
                  data={SessionInfo.MenuControlO}
                  size='large' draggable='false' selection='single'
                  onItemClick={this.onMenuItemClick}
                  className='menuTreeSub'
                  item={props => [<span key={props.item.key} className={props.item.className}>{props.item.icon}{props.item.space}{props.item.text}</span>]}
                />
              </div>
            </div>
          </Popup>
        </div>
        {SessionInfo.HasMenuImage ?
          <div className="orderListBG" style={{ backgroundImage: `url(${SessionInfo.BackgroundImageURL})` }} >
            {useAlt === false ?
              <ListView data={this.state.OrderItems} item={this.OrderItemFormat} header={this.OrderItemHeader} footer={this.OrderItemFooter} style={{ backgroundColor: SessionInfo.orderBGColor, backgroundRepeat: 'repeat-y', overflow: 'hidden' }} />
              :
              <ListView data={this.state.OrderItems} item={this.OrderItemFormatAlt} header={this.OrderItemHeader} footer={this.OrderItemFooter} style={{ backgroundColor: SessionInfo.orderBGColor, backgroundRepeat: 'repeat-y', overflow: 'hidden' }} />}
          </div>
          :
          <div className="orderListBG">
            {useAlt === false ?
              <ListView data={this.state.OrderItems} item={this.OrderItemFormat} header={this.OrderItemHeader} footer={this.OrderItemFooter} style={{ backgroundColor: SessionInfo.orderBGColor, backgroundRepeat: 'repeat-y', overflow: 'hidden' }} />
              :
              <ListView data={this.state.OrderItems} item={this.OrderItemFormatAlt} header={this.OrderItemHeader} footer={this.OrderItemFooter} style={{ backgroundColor: SessionInfo.orderBGColor, backgroundRepeat: 'repeat-y', overflow: 'hidden' }} />}
          </div>}
      </div>
    );
  }
}

export default withRouter(ScannerPage);