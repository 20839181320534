//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// LandingPage - Interact Menu Landing Page
//                      Version 1.00 - April 17, 2023
//                      Version 1.981 - September 17, 2023 - Reformatted Landing and Locations Page
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { SessionInfo } from './App';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { TreeView } from '@progress/kendo-react-treeview';
import { Input } from '@progress/kendo-react-inputs';
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import { Tooltip } from '@progress/kendo-react-tooltip';
import { FloatingLabel } from '@progress/kendo-react-labels'
import { ListView,ListViewHeader,ListViewFooter } from '@progress/kendo-react-listview';
import { Avatar } from '@progress/kendo-react-layout';
import { Slider,SliderLabel } from "@progress/kendo-react-inputs";
//
//import { withScriptjs,withGoogleMap,LoadScript,GoogleMap,Marker,DirectionsRenderer,} from 'react-google-maps'
//
import { GoogleMap,useJsApiLoader } from '@react-google-maps/api';
import MyGoogleMap from './googleMap';
//
import { CallOM,getImageFileFromServer,OMTrace,trace,imagePrep,GetLocationInfo,displayMessage,CTYP,TYP,NOTIFY,SetNotify } from './CommonCode.js';
//import { ENAppCnfg } from './CommonInfo.js';
import { GetMonth, GetDay } from './CommonFormat.js';
import { BrowserMultiFormatReader } from '@zxing/library';
import { withRouter } from './withRouter';

import { ReactComponent as MenuBar } from "./images/bars.svg";
//import { ReactComponent as CartAdd } from "./images/cart-plus.svg";
//import { ReactComponent as CartChoose } from "./images/cart-arrow-down.svg";
//import { ReactComponent as Favorite } from "./images/star.svg";
//import { ReactComponent as FavoriteSelect } from "./images/menu-favorite.svg";
import { ReactComponent as ButtonSearch } from "./images/search.svg";
import { ReactComponent as ButtonClear } from "./images/button-clear.svg";
//import { ReactComponent as Promotion } from "./images/menu-promotions.svg";
//import { ReactComponent as CirclePlus } from "./images/circle-plus.svg";
//import { ReactComponent as PageTop } from "./images/PageTop.svg";

const codeReader = new BrowserMultiFormatReader();
// Gauge - Current Guests
let range1 = [0, 50, 100, 150, 200];
let range2 = [0, 2000, 4000, 6000, 8000];
const CompaniesOID = 488610;
const CompaniesO = [{ n: 'CompanyID',t: 195,p: 100 },{ n: 'CompanyName',t: 21,p: 0 },{ n: 'CompanyCode',t: 21,p: 0 },{ n: 'CompanyDBA',t: 21,p: 0 },{ n: 'CompanyLegalName',t: 21,p: 0 },{ n: 'AccountNumber',t: 21,p: 0 },{ n: 'AccountStartDate',t: 34,p: 0 },{ n: 'CompanyTypeID',t: 15,p: 0 },{ n: 'CompanyStatusID',t: 195,p: 100 },{ n: 'ParentCompanyID',t: 195,p: 100 },{ n: 'MainBusinessPhone',t: 21,p: 0 },{ n: 'MainEmailAddress',t: 21,p: 0 },{ n: 'ContactSet',t: 42,p: 0 },{ n: 'PrimeContactName',t: 21,p: 0 },{ n: 'FaxNumber',t: 21,p: 0 },{ n: 'TollFreeNumber',t: 21,p: 0 },{ n: 'MainCellNumber',t: 21,p: 0 },{ n: 'AlternatePhoneNumber',t: 21,p: 0 },{ n: 'WebSite',t: 21,p: 0 },{ n: 'TAXNumber',t: 21,p: 0 },{ n: 'TAX2Number',t: 21,p: 0 },{ n: 'DUNSNumber',t: 21,p: 0 },{ n: 'UnitNumber',t: 21,p: 0 },{ n: 'Address',t: 21,p: 0 },{ n: 'City',t: 21,p: 0 },{ n: 'ProvinceStateID',t: 195,p: 100 },{ n: 'PostalCode',t: 21,p: 0 },{ n: 'CountryID',t: 195,p: 100 },{ n: 'SalesGroupID',t: 195,p: 100 },{ n: 'SalesPersonID',t: 195,p: 100 },{ n: 'InvoiceGroupID',t: 195,p: 100 },{ n: 'MonthPeriodType',t: 201,p: 0 },{ n: 'CurrentMonthEnd',t: 34,p: 0 },{ n: 'CompanyLogo',t: 41,p: 0 },{ n: 'CompanyImage',t: 41,p: 0 },{ n: 'CompanyUserName',t: 21,p: 0 },{ n: 'Description',t: 21,p: 0 },{ n: 'Comments',t: 21,p: 0 },{ n: 'IsRecordActive',t: 22,p: 0 },{ n: 'ControlFlags',t: 27,p: 0 },{ n: 'CreatedBy',t: 195,p: 100 },{ n: 'CreatedOn',t: 35,p: 0 },{ n: 'LastModifiedBy',t: 195,p: 100 },{ n: 'LastModifiedOn',t: 35,p: 0 },{ n: 'AppEntityID',t: 195,p: 100 },{ n: 'AppGroupID',t: 195,p: 100 }];
//       Object Value Definition For Companies
const CV = { 'CompanyID': 0,'CompanyName': '','CompanyCode': '','CompanyDBA': '','CompanyLegalName': '','AccountNumber': '','AccountStartDate': new Date(),'CompanyTypeID': 0,'CompanyStatusID': 0,'ParentCompanyID': 0,'MainBusinessPhone': '','MainEmailAddress': '','ContactSet': '','PrimeContactName': '','FaxNumber': '','TollFreeNumber': '','MainCellNumber': '','AlternatePhoneNumber': '','WebSite': '','TAXNumber': '','TAX2Number': '','DUNSNumber': '','UnitNumber': '','Address': '','City': '','ProvinceStateID': 0,'PostalCode': '','CountryID': 0,'SalesGroupID': 0,'SalesPersonID': 0,'InvoiceGroupID': 0,'MonthPeriodType': 0,'CurrentMonthEnd': new Date(),'CompanyLogo': '','CompanyImage': '','CompanyUserName': '','Description': '','Comments': '','IsRecordActive': false,'ControlFlags': 0,'CreatedBy': 0,'CreatedOn': new Date(),'LastModifiedBy': 0,'LastModifiedOn': new Date(),'AppEntityID': 0,'AppGroupID': 0 };
//       Object Index Definition For Companies
const CX = { 'CompanyID': 0,'CompanyName': 1,'CompanyCode': 2,'CompanyDBA': 3,'CompanyLegalName': 4,'AccountNumber': 5,'AccountStartDate': 6,'CompanyTypeID': 7,'CompanyStatusID': 8,'ParentCompanyID': 9,'MainBusinessPhone': 10,'MainEmailAddress': 11,'ContactSet': 12,'PrimeContactName': 13,'FaxNumber': 14,'TollFreeNumber': 15,'MainCellNumber': 16,'AlternatePhoneNumber': 17,'WebSite': 18,'TAXNumber': 19,'TAX2Number': 20,'DUNSNumber': 21,'UnitNumber': 22,'Address': 23,'City': 24,'ProvinceStateID': 25,'PostalCode': 26,'CountryID': 27,'SalesGroupID': 28,'SalesPersonID': 29,'InvoiceGroupID': 30,'MonthPeriodType': 31,'CurrentMonthEnd': 32,'CompanyLogo': 33,'CompanyImage': 34,'CompanyUserName': 35,'Description': 36,'Comments': 37,'IsRecordActive': 38,'ControlFlags': 39,'CreatedBy': 40,'CreatedOn': 41,'LastModifiedBy': 42,'LastModifiedOn': 43,'AppEntityID': 44,'AppGroupID': 45 };
//------ Object Definition For StoreLocations -- Date: 2023-06-14-2:52 PM ------
const StoreLocationsOID = 544596;
const StoreLocationsO = [{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'StoreName',t: 21,p: 0 },{ n: 'Description',t: 21,p: 0 },{ n: 'CompanyID',t: 195,p: 100 },{ n: 'LocationID',t: 195,p: 100 },{ n: 'StoreLocationControl',t: 15,p: 0 },{ n: 'AreaSize',t: 16,p: 0 },{ n: 'ContactID',t: 195,p: 100 },{ n: 'StoreGroupID',t: 195,p: 100 },{ n: 'TaxRate',t: 16,p: 0 },{ n: 'TaxRate2',t: 16,p: 0 },{ n: 'TaxRate3',t: 16,p: 0 },{ n: 'TaxRuleID',t: 195,p: 100 },{ n: 'MaximumPurchaseAmount',t: 28,p: 0 },{ n: 'StandardMarkupPercent',t: 16,p: 8 },{ n: 'ExtendedMarkupPercent',t: 16,p: 8 },{ n: 'SetPriceType',t: 201,p: 0 },{ n: 'SpecialtyList',t: 21,p: 0 },{ n: 'MarketingMessage',t: 21,p: 0 },{ n: 'PromotionalMessage',t: 21,p: 0 },{ n: 'ImageID',t: 41,p: 0 },{ n: 'StoreAmenities',t: 15,p: 0 },{ n: 'DayPeriods',t: 15,p: 0 },{ n: 'TipRate1',t: 16,p: 0 },{ n: 'TipRate2',t: 16,p: 0 },{ n: 'TipRate3',t: 16,p: 0 },{ n: 'CurrentInStoreWaitTime',t: 24,p: 0 },{ n: 'CurrentPickupWaitTime',t: 24,p: 0 },{ n: 'CurrentOrdersInQueue',t: 24,p: 0 },{ n: 'CurrentDeliveryWaitTime',t: 24,p: 0 }];
//       Object Value Definition For StoreLocations
const SLV = { 'StoreLocationID': 0,'StoreName': '','Description': '','CompanyID': 0,'LocationID': 0,'StoreLocationControl': 0,'AreaSize': 0,'ContactID': 0,'StoreGroupID': 0,'TaxRate': 0,'TaxRate2': 0,'TaxRate3': 0,'TaxRuleID': 0,'MaximumPurchaseAmount': 0,'StandardMarkupPercent': 0,'ExtendedMarkupPercent': 0,'SetPriceType': 0,'SpecialtyList': '','MarketingMessage': '','PromotionalMessage': '','ImageID': '','StoreAmenities': 0,'DayPeriods': 0,'TipRate1': 0,'TipRate2': 0,'TipRate3': 0,'CurrentInStoreWaitTime': 0,'CurrentPickupWaitTime': 0,'CurrentOrdersInQueue': 0,'CurrentDeliveryWaitTime': 0 };
//--------------- @React Google Maps/API ------------------------------------------------------------------------------------------------------
const containerStyle = { width: '100%',height: '200px' };
const dateFormat = (value) => new Intl.DateTimeFormat('en-CA',{ dateStyle: 'long',timeStyle: 'short' }).format(Date.parse(value));

//--------------- React Google Maps ------------------------------------------------------------------------------------------------------
////const MapWithAMarkerWithLabel = compose(withScriptjs,withGoogleMap)(props =>
//// https://tomchentw.github.io/react-google-maps
////                                            
//const { MarkerWithLabel } = require("react-google-maps/lib/components/addons/MarkerWithLabel");
let latLong = { lat: -34.397,lng: 150.644 };
let storeName = 'store';
//const MapWithAMarkerWithLabel = withScriptjs(withGoogleMap(props =>
//  <GoogleMap
//    defaultZoom={12}
//    defaultCenter={latLong}
//  >
//    <MarkerWithLabel position={latLong} labelAnchor={new google.maps.Point(0,0)} labelStyle={{ backgroundColor: "yellow",fontSize: "16px",padding: "3px", borderRadius: "9px" }} >
//      <div>{storeName}</div>
//    </MarkerWithLabel>
//  </GoogleMap>
//));
////const MapWithADirectionsRenderer = compose(
////  withProps({
////    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyBfCCwbCYQTfRjymntWxr__yuK85ypvRag&v=3.exp&libraries=geometry,drawing,places",
////    loadingElement: <div style={{ height: `100%` }} />,
////    containerElement: <div style={{ height: `400px` }} />,
////    mapElement: <div style={{ height: `100%` }} />,
////  }),
////  withScriptjs,
////  withGoogleMap,
////  lifecycle({
////    componentDidMount() {
////      const DirectionsService = new google.maps.DirectionsService();

////      DirectionsService.route({
////        origin: new google.maps.LatLng(41.8507300,-87.6512600),
////        destination: new google.maps.LatLng(41.8525800,-87.6514100),
////        travelMode: google.maps.TravelMode.DRIVING,
////      },(result,status) => {
////        if (status === google.maps.DirectionsStatus.OK) {
////          this.setState({
////            directions: result,
////          });
////        } else {
////          console.error(`error fetching directions ${result}`);
////        }
////      });
////    }
////  })
////)(props =>
////  <GoogleMap
////    defaultZoom={7}
////    defaultCenter={new google.maps.LatLng(41.8507300,-87.6512600)}
////  >
////    {props.directions && <DirectionsRenderer directions={props.directions} />}
////  </GoogleMap>
////);
//--------------- End React Google Maps ------------------------------------------------------------------------------------------------------

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
  }
  GoTo({ screenName }) {
    const navigation = useNavigation();
    trace(`go to: ${screenName}`);
    navigation.navigate(screenName);
  }
  menuAnchor = React.createRef();
  state = {
    StoreLocationID: 0,
    QuestionID: 0,
    Name: '',
    EmailAddress: '',
    QuestionTypeID: '',
    MessageDetail: '',
    showMenu: false,
    StoreName: '',
    Description: '',
    TableName: '',
    SeatCount: 0,
    SeatName: '',
    ItemCount: 0,
    NotifyMessage: '',
    activateMessageBox: false,
    ScanTitle: '',
    activeCustomers: 0,
    totalSales: 0,
    searchValue: '',
    lastSearchValue: '',
    currentTime: '',
    currentDate: '',

    HoursOfOperation: [],
    HoursLth: 0,
    HoursForToday: '',

    scannedString: '',
    showLocationList: false,
    showLocationInfo: false,
    showNotify: false,

    displayGuestCount: true,
    displayNextTable: true,
    displayMakeReservation: true,
    reservationToBeConfirmed: false,
    reservationsExist: false,
    displayMakeChoices: false,
    displayMessages: false,
    displayResList: false,
    requestDateTime: new Date(),
    TimeSlot1: '',
    TimeSlot2: '',
    TimeSlot3: '',
    TimeSlot4: '',
    reserveComments: '',
    ReservationsList: [],
    //------ String Values - StoreLocations ------
    SpecialtyList: '',
    PromotionalMessage: '',

    //------ Integer Values - Companies ------
    CompanyID: 0,
    AccountStartDate: 0,
    CompanyTypeID: 0,
    CompanyStatusID: 0,
    ParentCompanyID: 0,
    ProvinceStateID: 0,
    CountryID: 0,
    SalesGroupID: 0,
    SalesPersonID: 0,
    InvoiceGroupID: 0,
    MonthPeriodType: 0,
    CurrentMonthEnd: 0,
    CompanyLogo: 0,
    CompanyImage: 0,
    promo0ImageURL: '',
    promo1ImageURL: '',
    promo2ImageURL: '',
    promo0Desscription: '',
    promo1Desscription: '',
    promo2Desscription: '',
    MarketingMessage: 'Find out more about our Location and how to get here.',
    IsRecordActive: 0,
    ControlFlags: 0,
    CreatedBy: 0,
    CreatedOn: 0,
    LastModifiedBy: 0,
    LastModifiedOn: 0,
    AppEntityID: 0,
    AppGroupID: 0,

    //------ String Values - Companies ------
    CompanyName: '',
    CompanyCode: '',
    CompanyDBA: '',
    CompanyLegalName: '',
    AccountNumber: '',
    MainBusinessPhone: '',
    MainEmailAddress: '',
    PrimeContactName: '',
    FaxNumber: '',
    TollFreeNumber: '',
    MainCellNumber: '',
    AlternatePhoneNumber: '',
    WebSite: '',
    TAXNumber: '',
    TAX2Number: '',
    DUNSNumber: '',
    UnitNumber: '',
    Address: '',
    Address2: '',
    City: '',
    PostalCode: '',
    CompanyUserName: '',
    Comments: '',
    reservationMessage: '',
    numberOfGuests: 2,
    tableWaitNumber: 3,

    LocationsList: [],
    StoreLocationPhotoURL: '',
    map: {},
    showLandingPage: true,
  };
  //--- Class Variables ----
  currentType = '';
  refreshCount = 6;
  compUpdate = true;
  custCount = 0;
  messageColor = 'blue';
  messageCounter = 0;
  scanType = 0;
  locationCount = 0;
  isMarkerShown = true;
  listLocationsOffset = { left: 5,top: 50 };
  showLocationOffset = { left: 5,top: 50 };
  //
  backLandingPrompt = '< Back to Locations';
  mesgHT = 0;
  //
  scanTypes = {
    Table: 1,
    Order: 2,
    OrderItem: 3,
    CustomerCredit: 4,
    CustomerVerify: 5,
    CustomerCash: 6,
  }
  //-------------------------------------------------------------------------------
  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '') {
      return;
    } else {
      this.setState({ StoreLocationID: SessionInfo.StoreLocationID });
      trace(`==> LandingPage Did Mount`);
      this.setState({ currentTime: this.getTime() });
      this.setState({ showLandingPage: true });
      this.setState({ Name: SessionInfo.FullName });
      this.setState({ EmailAddress: SessionInfo.logonEmailAddress });
      storeName = SessionInfo.SLV.StoreName;
      if (!SessionInfo.GeoLocation) {
        SessionInfo.GeoLocation = SessionInfo.DefaultGeoLocation;
        trace(`SessionInfo.GeoLocation: ${JSON.stringify(SessionInfo.GeoLocation)}`);
        this.setLatLong();
        trace(`latLong: ${JSON.stringify(SessionInfo.LatLong)}`);
      }
      SessionInfo.currentPage = "LandingPage";
      this.setState({ QuestionID: 0 });
      //await this.initLocation();  
      if (SessionInfo.AfterSignIn === true) {
        SessionInfo.AfterSignIn = false;
        if (SessionInfo.CustomOrderID > 0) {
          let CD = await CallOM("SwitchCustomer",0,SessionInfo.CustomOrderID,0);
          //  let CustomerID = CD.x.d;
          //  this.setState({ CustomerID });
        }
      }
      this.initLocation();
      // This brings up the Scan&Pay Logo - I have no idea where it is coming from
      //let CD = await CallOM("GetLogoImage",0,0); // From Company record
      //SessionInfo.LogoImageURL = imagePrep(CD.d.Item2);
      //trace(`====> LogoImage Landing: length: ${SessionInfo.LogoImageURL}`);
      this.monitorProcess(true);
      if (SessionInfo.traceDebug) {
        if (SessionInfo.testScriptLoad === true) { // Load Script - the InteractMenuInit.js is really just for test - need to bypass this for non-payment version
          trace(`after load LandingPage`);
          trace(`TestVal: ${TestVal}`);
          TestScriptX();
        }
      }
      const endTimestamp = Date.now();
      const executionTime = endTimestamp - SessionInfo.StartDate;
      console.log(`==> Startup time: ${executionTime} milliseconds`);
    }
  }
  componentDidUpdate() {
    //let value = this.context;
  }
  componentWillUnmount() {
    trace(`==> LandingPage UnMount`);
    this.runMonitorProcess = false;
    if (this.timeout)
      clearTimeout(this.timeout);
  }
  shouldComponentUpdate() {
    //trace("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  initLocation = async () => {
    try {
      let startTime = performance.now();
      this.setState({ StoreName: SessionInfo.StoreName });
      this.setState({ TableName: SessionInfo.CurrentTableName });
      this.setState({ SeatName: SessionInfo.SeatName });
      this.setState({ SeatCount: SessionInfo.SeatCount });
      this.setState({ ItemCount: SessionInfo.ItemCount });
      this.setState({ NotifyMessage: '' });
      this.setState({ StoreLocationID: SessionInfo.StoreLocationID });
      this.forceUpdate();
      //await this.GetHoursOfOperation();
      //await this.GetStoreHoursOfOperationForToday();
      //await this.getCompanyInfo();
      //await this.getStoreImage();
      //await this.getLocationInfo(); 
      this.getLocationInfo();
      this.GetHoursOfOperation();
      this.GetStoreHoursOfOperationForToday();
      this.getCompanyInfo();
      this.getStoreImage();
      console.log(`get promo Images`);
      this.getPromoImages(); // Note - Not waiting for Images to load
      console.log(`finished get Images`);
      const endTime = performance.now();
      let executionTime = Math.round(endTime - startTime);
      console.log(`==> initLocation execution time: ${executionTime} milliseconds`);
      const endTimestamp = Date.now();
      executionTime = Math.round(endTimestamp - SessionInfo.StartDate);
      console.log(`==> Total Startup time: ${executionTime} milliseconds`);
    } catch (ex) {
      console.log(`failure initLocation - ex: ${ex}`);
    }
  }
  //-----------------------------------------------------------------
  getTime() { // Get CLOCK time
    const curTime = new Date();
    if (curTime) {
      try {
        let currentDate = GetDay(curTime.getDay()) + ' - ' + GetMonth(curTime.getMonth()) + ' ' + curTime.getDate(); // + ', ' + curTime.getFullYear();
        this.setState({ currentDate });
        const currentTime =
        {
          hours: curTime.getHours(),
          minutes: curTime.getMinutes(),
          //seconds: curTime.getSeconds(),
          //ampm: curTime.getHours() >= 12 ? "pm" : "am"
        };
        return currentTime;
      } catch (ex) {
        console.log(`getTime: ${ex}`);
      }
    }
    return 'Time';
  }
  runMonitorProcess = false;
  setTimer() {
    if (this.runMonitorProcess === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this),200); // 200 microseconds  
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess = async (force) => { // Note - Monitor only runs if logged on and only runs when on LandingPage - it starts again when return to LandingPage
    if (force)
      this.runMonitorProcess = true;
    //trace(`monitor logoff: ${SessionInfo.forceLogoff}, On: ${SessionInfo.loggedOn}`);
    if (SessionInfo.forceLogoff === true) {
      trace(`monitor force logoff`);
      SessionInfo.forceLogoff = false;
      this.props.navigate("/Login");
    }
    if (this.runMonitorProcess === true && SessionInfo.loggedOn === true) {
      //trace(`monitor active - LandingPage`);
      try {
        this.setTimer();
        if (this.refreshCount++ > 4) { // Once a second
          this.setState({ currentTime: this.getTime() });
          this.refreshCount = 0;
        }
        if (this.custCount++ > 10) {
          this.custCount = 0;
          this.setState({ activeCustomers: this.state.activeCustomers + 1 });
          this.setState({ totalSales: this.state.totalSales + 10 });
        }
        if (this.messageCounter > 0) {
          //trace(`messageCnt: ${this.messageCounter}`);
          this.messageCounter--;
          if (this.messageCounter <= 0)
            this.messageClear();
        }
        //---- Ping and Notification Processing -------------
        if (1 === 1 || document.hasFocus() === true) { // Execute monitor process
          //---- Ping and Notification Processing -------------
          if (SessionInfo.setShowNotify > 0 && SessionInfo.notifyMessage.length > 0) {
            this.showMessage(SessionInfo.notifyMessage,"blue",SessionInfo.notifyTime,SessionInfo.notifyRing);
          }
          SessionInfo.setShowNotify = 0;
          if (SessionInfo.notifyTimer > 0) {
            if (--SessionInfo.notifyTimer <= 0)
              this.setState({ showNotify: false });
          } else if (SessionInfo.showNotify === true)
            this.setState({ showNotify: false }); // Hide the Notify Message
          if (SessionInfo.pingCounter++ > 50) {
            SessionInfo.pingCounter = 0;
            let CD = await CallOM("PingApp",SessionInfo.StoreLocationID,SessionInfo.CustomOrderID,CTYP.STR,"");
            if (CD && CD.d && CD.d.rows) {
              //trace(`Return from PingApp - CD: ${JSON.stringify(CD)}`);
              let rows = CD.d.rows;
              for (let ix = 0; ix < rows.length; ix++) {
                let row = rows[ix];
                SetNotify(row["NotificationMessage"],row["NotificationTypeID"]);
                SessionInfo.AppNotificationID = row["AppNotificationID"];
              }
            }
          }
        }
        //---- End of Notification Processing -------------
        // Ping Processing 
        if (SessionInfo.pingCounter++ > 50) {
          SessionInfo.pingCounter = 0;
          let CD = await CallOM("PingApp",SessionInfo.StoreLocationID,SessionInfo.CustomOrderID,CTYP.STR,"");
          if (CD && CD.d && CD.d.rows) {
            //trace(`Return from PingApp - CD: ${JSON.stringify(CD)}`);
            let rows = CD.d.rows;
            for (let ix = 0; ix < rows.length; ix++) {
              let row = rows[ix];
              SetNotify(row["NotificationMessage"],row["NotificationTypeID"]);
              SessionInfo.AppNotificationID = row["AppNotificationID"];
            }
          }
        }
        //---- End of Monitor and Notification Processing -------------
      } catch (ex) {
        console.log(`Error in monitorProcess - ex: ${ex}`);
      }
      //trace(`monitor done - LandingPage`);
      // The following does not happen in the monitor
      //  Uncaught(in promise) SyntaxError: "[object Object]" is not valid JSON
      //    at JSON.parse(<anonymous>)
      //    at l._storageChangeDispatcher (content.js:2:912656)
      //    at _storageChangeDispatcherCallback (content.js:2:912104)
    }
  }
  acknowledgeMessage = async () => {
    let CD = await CallOM("AcknowledgeNotification",0,SessionInfo.AppNotificationID);
    this.setState({ showNotify: false });
    SessionInfo.notifyTimer = 50;
    SessionInfo.acknowledge = false;
    this.forceUpdate();
  }
  closeAll = () => {
    this.setState({ showNotify: false });
    this.setState({ showMenu: false });
    this.setState({ showLocationInfo: false });
    this.setState({ showLocationList: false });
  }
  openMenu = () => {
    //this.setState({ showItemsDetail: false });
    //this.keepMenu = true;
    this.setState({ showMenu: true });
    //trace(`open Menu - showMenu: ${this.state.showMenu}`);
  }
  signIn = async () => {
    trace(`logontype: ${SessionInfo.appEntityLogonType}`);
    if (SessionInfo.appEntityLogonType === 1) {
      SessionInfo.isPhoneLogin = false;
    } else if (SessionInfo.appEntityLogonType === 8) {
      SessionInfo.isPhoneLogin = true;
    }
    SessionInfo.logonReturn = 1;
    SessionInfo.PersonInfo.FirstName = undefined;
    this.props.navigate(`/Login`);
  }
  onMenuItemClick = async (event) => {
    trace(`menu event Item: ${event.item.text}`);
    if (SessionInfo.currentItem !== undefined) {
      //trace('Current Item: ' + SessionInfo.currentItem.text);
      SessionInfo.currentItem.selected = false;
    }
    SessionInfo.currentItem = event.item;
    trace('2 Current Item: ' + SessionInfo.currentItem.text);
    event.item.selected = true;
    event.item.expanded = true;
    //trace(`Event - name: ${JSON.stringify(event.item)}`);
    SessionInfo.MenuSelect = event.item;
    this.keepMenu = false;
    this.setState({ showMenu: false });
    if (SessionInfo.menuAction !== undefined) {
      await SessionInfo.menuAction(); // onMenuItemClick in AppMain.js
    }
    this.forceUpdate();
  }
  //
  getStoreImage = async () => {
    //trace(`SLV: ${JSON.stringify(SessionInfo.SLV)}`); 
    trace(`get Location Image: ${SessionInfo.SLV.ImageID}`);
    let imageURL = await getImageFileFromServer(SessionInfo.SLV.ImageID,4);
    this.setState({ StoreLocationPhotoURL: imageURL });
  }
  getPromoImages = async () => {
    let CD = await CallOM("GetPromotionsImageDesc",0,SessionInfo.StoreLocationID,0);
    let cnt = 0;
    trace(`PromotionImages - CD.d: ${JSON.stringify(CD.d)}`);
    let elem = CD.d.rows;
    let count = CD.d.rows.length;
    if (count > 3)
      count = 3;
    //CD.d.rows.forEach(async (elem) => 
    for (let ix = 0; ix < count; ix++) {
      //trace(`landing cnt: ${ix}, type: ${elem[ix].PromotionType}, image: ${elem[ix].ImageID}, desc: ${elem[ix].PromotionDescription}`);
      try {
        let URL = await getImageFileFromServer(elem[ix].ImageID,3);
        //trace(`cnt: ${ix}, URL: ${JSON.stringify(URL)}`);
        if (ix == 0) {
          this.setState({ promo0ImageURL: URL }); // Top Image  
          this.setState({ promo0Description: elem[ix].PromotionDescription }); // Top Description
        } else if (ix == 1) {
          this.setState({ promo1ImageURL: URL }); // Left Image    
          this.setState({ promo1Description: elem[ix].PromotionDescription }); // Left Description
        } else if (ix == 2) {
          this.setState({ promo2ImageURL: URL }); // Right 
          this.setState({ promo2Description: elem[ix].PromotionDescription }); // Right Description
        }
      } catch (ex) {
        console.log(`Error in getPromoImages - ex: ${ex}`);
      }
    }
    trace(`Landing - Finished getPromoImages`);
  }
  GetStoreHoursOfOperationForToday = async () => {
    trace(`Get Hours Of Op For Today`);
    try {
      let CD = await CallOM("GetStoreHoursOfOperationForToday",0,SessionInfo.StoreLocationID,0);
      SessionInfo.HoursForToday = CD.d.toString();
    }
    catch (Ex) {
      await OMTrace(2,`Failure in GetStoreHoursOfOperationForToday - Error: ${Ex}`);
    }
    await this.setState({ HoursForToday: SessionInfo.HoursForToday });
    trace(`Landing Page - HoursForToday: ${SessionInfo.HoursForToday}`);
  }
  GetHoursOfOperation = async () => {
    let DaysHours = [];
    try {
      let CD = await CallOM("GetStoreHoursOfOperation",0,SessionInfo.StoreLocationID,0);
      let str = CD.d.toString();
      trace(`DayHours: ${JSON.stringify(CD.d)}`);
      DaysHours = str.split(',');
      trace(`str: ${str}, DayHours: ${JSON.stringify(DaysHours)}`);
    }
    catch (Ex) {
      await OMTrace(2,`Failure in GetHoursOfOperation - Error: ${Ex}`);
    }
    this.setState({ HoursOfOperation: DaysHours });
    this.setState({ HoursLth: DaysHours.length / 2 });
  }
  getCompanyInfo = async () => {
    let CD = await CallOM("GetBasicCompanyInfo",0,0,0);
    //trace("after GetBasicCompanyInfo: " + JSON.stringify(CD.d));
    let row = CD.d;
    this.locationCount = row.LocationsCount;
    //this.setState({ Companies: row });
    // Retrieved Value Assignment for: Companies
    CompaniesO.forEach((elem) => {
      if (elem.n in row) {
        //trace(`Set ${elem.n}: ${row[elem.n]}`);
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            //sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
            sobj[elem.n] = row[elem.n];
            CV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = row[elem.n];
            //trace(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            CV[elem.n] = row[elem.n];
          } else if (elem.t === 28) { // Money
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
            //trace(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            CV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            sobj[elem.n] = row[elem.n];
            CV[elem.n] = row[elem.n];
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
          } else if (elem.t === 15) { // Encoded
            //------ Assign Encoded Values ------
            if ("CompanyTypeID" === elem.n) {
              // Assignment for CompanyTypeID
              let EncodeList = [];
              let EncodeVal = row[elem.n];
              if ((EncodeVal & (0x001 << 6)) === (0x001 << 6))
                EncodeList.push('Account Owner');
              if ((EncodeVal & (0x001 << 1)) === (0x001 << 1))
                EncodeList.push('SKU Owner');
              if ((EncodeVal & (0x001 << 4)) === (0x001 << 4))
                EncodeList.push('Producer');
              if ((EncodeVal & (0x001 << 5)) === (0x001 << 5))
                EncodeList.push('Shipper');
              if ((EncodeVal & (0x001 << 11)) === (0x001 << 11))
                EncodeList.push('Supplier');
              if ((EncodeVal & (0x001 << 8)) === (0x001 << 8))
                EncodeList.push('Carrier');
              if ((EncodeVal & (0x001 << 7)) === (0x001 << 7))
                EncodeList.push('Consignee');
              if ((EncodeVal & (0x001 << 3)) === (0x001 << 3))
                EncodeList.push('Broker');
              if ((EncodeVal & (0x001 << 2)) === (0x001 << 2))
                EncodeList.push('Retailer');
              if ((EncodeVal & (0x001 << 12)) === (0x001 << 12))
                EncodeList.push('Regulatory');
              if ((EncodeVal & (0x001 << 20)) === (0x001 << 20))
                EncodeList.push('Other');
              this.setState({ CompanyTypeIDVals: EncodeList });
            }
            sobj[elem.n] = row[elem.n];
            CV[elem.n] = row[elem.n];
          } else {
            sobj[elem.n] = row[elem.n];
            CV[elem.n] = row[elem.n];
          }
          //trace(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${CV[elem.n]}`);
          this.setState(sobj);
          // Add item specific code here
          //if (elem.n === 'ImageID')
          //  ImageID = row[elem.n];
        }
        catch (error) {
          trace(`Set CompaniesO Field: ${elem.n}, Error: ${error.message}`);
        }
      }
    });
    if (SessionInfo.traceDebug)
      trace(`get company image for company ID: ${this.state.CompanyID}, CV: ${CV.CompanyID}, Logo: ${CV.CompanyLogo}, Image: ${CV.CompanyImage}`);
    if (CV.CompanyLogo !== 0) {
      SessionInfo.LogoImageURL = await getImageFileFromServer(CV.CompanyLogo,3);
      //trace(`====> LogoImage Land-2: ${CV.CompanyLogo} length: ${SessionInfo.LogoImageURL.length}`);
    }
    if (CV.CompanyImage !== 0)
      SessionInfo.CompanyImageURL = await getImageFileFromServer(CV.CompanyImage,3);
  }
  getLocationInfo = async (event) => {
    let CD = await CallOM("GetStoreInfo",0,SessionInfo.StoreLocationID,0);
    let row = CD.d;
    // Retrieved Value Assignment for: StoreLocations
    StoreLocationsO.forEach(elem => {
      if (elem.n in row && elem.n in this.state) {
        //trace(`Set ${elem.n}: ${row[elem.n]}`);
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
            //sobj[elem.n] = row[elem.n];
            SLV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = row[elem.n];
            //trace(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            SLV[elem.n] = row[elem.n];
          } else if (elem.t === 28) { // Money
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
            //trace(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            SLV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            sobj[elem.n] = row[elem.n];
            SLV[elem.n] = row[elem.n];
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
          } else if (elem.t === 15) { // Encoded
            //------ Assign Encoded Values ------

            let AList = [];

            if (elem.n === 'StoreLocationControl') {
              // Assignment for StoreLocationControl
              let EncodeVal = row[elem.n];
              StoreLocationControlFixedValues.forEach((ent) => {
                if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                  AList.push(ent.n);
              });
            }
            if (elem.n === 'StoreAmenities') {
              // Assignment for StoreAmenities
              let EncodeVal = row[elem.n];
              StoreAmenitiesFixedValues.forEach((ent) => {
                if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                  AList.push(ent.n);
              });
            }
            if (elem.n === 'DayPeriods') {
              // Assignment for DayPeriods
              let EncodeVal = row[elem.n];
              DayPeriodsFixedValues.forEach((ent) => {
                if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                  AList.push(ent.n);
              });
            }
            let sobjV = {};
            sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
            this.setState(sobjV); // Set the Vals var
            sobj[elem.n] = row[elem.n];
            //obj[elem.n] = row[elem.n];
            ECV[elem.n] = row[elem.n];
            trace(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${ECV[elem.n]}`);
          } else {
            sobj[elem.n] = row[elem.n];
            SLV[elem.n] = row[elem.n];
          }
          //trace(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${SLV[elem.n]}`);
          if (this.state[elem.n] === undefined)
            trace(`=== ${elem.n} is not defined in state ===`);
          this.setState(sobj);
          // Add item specific code here
          //if (elem.n === 'ImageID')
          //  ImageID = row[elem.n];
        }
        catch (error) {
          trace(`Set StoreLocationsO Field: ${elem.n}, Error: ${error.message}`);
        }
      }
    });
  }
  //-------------- Search -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  searchButton = async (event) => {
    trace('search Button: ' + this.state.searchValue);
    event.preventDefault();
    event.stopPropagation();
    await this.searchCurrent(this.state.searchValue);
  }
  searchCurrent = async (search) => {
    if (!search || typeof search !== 'string' || search === 'all' || search === 'ALL')
      search = "";
    if (search === undefined)
      search = "";
    trace(`Search for: ${search}`);
    this.locationSearch(search);
  }
  updateSearchValue = async (evt) => { // Original Search Value event handler
    let newValue = evt.value;
    //trace(`searchValue: ${this.state.searchValue}, targetname: ${evt.name}, event.value: ${evt.target.value}, ${newValue}`);
    if (!(newValue.length > 0 && (newValue[0] === '*' || newValue[0] === '`'))) {
      if (newValue.length >= 2 || this.state.lastSearchValue.length > newValue.length) {
        this.setState({ lastSearchValue: newValue });
        this.locationSearch(newValue);
      }
      else {
        this.setState({ lastSearchValue: newValue });
      }
    }
    this.setState({ searchValue: newValue }); // Note - moved save to State to last operation
  }
  chgSrchVal(event) {
    this.setState({ searchValue: event.target.value });
    this.setState({ stateHasChanged: true });
    trace(`searchValue: ${this.state.searchValue}, targetname: ${event.target.name}, event.value: ${event.target.value}`);
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
    if (!(event.target.value.length > 0 && (event.target.value[0] === '*' || event.target.value[0] === '`'))) {
      if (event.target.value.length >= 2 || this.state.lastSearchValue.length > event.target.value.length) {
        this.setState({ lastSearchValue: event.target.value });
        this.locationSearch(event.target.value);
      }
      else {
        this.setState({ lastSearchValue: event.value });
      }
    }
  }
  locationSearch = async (srchStr) => { // Search for Match
    trace(`locationSearch - search: ${srchStr}`);
    let CD = await CallOM("GetStores",0,0,CTYP.TypParm,[TYP.DBL,0.0,TYP.DBL,0.0,TYP.STR,srchStr]);
    let LocationsList = CD.d.rows;
    let cnt = LocationsList.length;
    this.setState({ LocationsList });
    trace(`location match: ${cnt}`);
    if (cnt === 0) {
      this.setState({ StoreItems: SessionInfo.saveStoreItems });
      displayMessage('No Items Found'); // Note - Display message not implemented for landingpage
    }
  }
  clearSearch = async (event) => { // ClearDisplay, Clear Messages - see updateSearchValue
    //trace(`clear search: ${this.state.searchValue}`);
    if (this.state.searchValue !== '') {
      await this.viewLocations('');
      this.setState({ searchValue: '' });
      this.setState({ lastSearchValue: '' });
      this.setState({ clearSetAll: true });
      this.setState({ stateHasChanged: true });
    }
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------------------------------- MultiItems List Template -------------------------------------------------------------------------------------------------------------------------------------------------------
  LocationListHeader = () => {
    return (
      <ListViewHeader className='pl-4 pb-2 pt-2'>
        <span style={{ fontSize: 16,fontWeight: 'bold',color: "darkblue" }} >&nbsp;&nbsp; Restaurant Locations</span> {/* {this.state.chooseItemName} */}
      </ListViewHeader>
    );
  }
  LocationListFormat = props => {
    let item = props.dataItem;
    //let imageVal = undefined;
    //if (item["Image"]) {
    //  let value = item["Image"];
    //  //trace("ImageCell value: " + value);
    //  const byteCharacters = atob(value);
    //  const byteNumbers = new Array(byteCharacters.length);
    //  for (let i = 0; i < byteCharacters.length; i++) {
    //    byteNumbers[i] = byteCharacters.charCodeAt(i);
    //  }
    //  const byteArray = new Uint8Array(byteNumbers);
    //  let image = new Blob([byteArray], { type: 'image/jpeg' });
    //  //trace("image: " + JSON.stringify(image));
    //  imageVal = URL.createObjectURL(image);
    //}
    return (
      <div className="listRow">
        <div className="listRowLoc1">
          <span style={{ fontSize: 16,color: "black",fontWeight: 'bold' }} >{item.StoreName}</span><br />
        </div>
        <div className="listRowLoc2">
          <span style={{ fontSize: 16,color: "black",fontWeight: 'bold' }} >{item.Address}</span><br />
        </div>
        {/*<div className="listRowLoc2">*/}
        {/*  <span style={{ fontSize: 16,color: "black",fontWeight: 'bold' }} >Phone: {item.PhoneNumber}</span><br />*/}
        {/*</div>*/}
        <div className="listRowLoc3">
          <span style={{ fontSize: 16,color: "black",fontWeight: 'bold' }} >Hours: {item.OperationHours}</span>&nbsp;
          <span className="rightMargin"><Button className="minButton" style={{ right: '2px' }} icon="marker-pin-target" onClick={(e) => this.storeLocationRowClick(item)}> View </Button></span>
        </div>
      </div>
    );
  }
  LocationListFooter = () => {
    return (
      <ListViewFooter className='listFooter3'>
        <div>
        </div>
      </ListViewFooter>
    );
  }
  //-------- Button Functions -----------------------------------------------------------------------------------------------------------------------------
  viewMenu = () => {
    trace(`view orderpage`);
    //this.showError("This is it");
    this.props.navigate("/OrderPage");
  }
  viewLocations = async () => {
    window.scrollTo(0,0); // Scroll to the top
    let CD = await CallOM("GetStores",0,0,CTYP.TypParm,[TYP.DBL,0.0,TYP.DBL,0.0,TYP.STR,""]);
    //trace("Locations: " + JSON.stringify(CD));
    let LocationsList = CD.d.rows;
    //trace("Locations: " + JSON.stringify(LocationsList));
    this.setState({ LocationsList });
    this.setState({ showLocationList: true });
  }
  viewCurrentLocation = async () => {
    window.scrollTo(0,0); // Scroll to the top   
    this.getStoreImage();
    await this.GetStoreHoursOfOperationForToday();
    this.setState({ showLocationInfo: true });
  }
  //-------- Locations -
  initMap = async () => {
    // The location of the restaurant
    const position = { lat: -25.344,lng: 131.031 };
    // Request needed libraries.
    const { Map } = await google.maps.importLibrary("maps");
    const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");

    // The map, centered at Uluru
    map = new Map(document.getElementById("map"),{
      zoom: 4,
      center: position,
      mapId: "Restaruant",
    });

    // The marker, positioned at Uluru
    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: position,
      title: "Restaurant",
    });
  }
  //-------- Reservations -----------------------------------------------------------------------------------------------------------------------------
  reserveTable = async () => { // Popup to Reserve a table or make a reservation   
    window.scrollTo(0,0); // Scroll to the top
    let DT = new Date();
    let hour = DT.getHours();
    hour++;
    DT.setHours(hour);
    DT.setMinutes(0);
    DT.setSeconds(0);
    this.setState({ requestDateTime: DT });
    this.runMonitorProcess = false;
    this.setState({ showReserveTable: true });
    let CD = await CallOM("GetReservationCount",SessionInfo.StoreLocationID,0,0);
    let ResCnt = CD.d;
    if (ResCnt > 0)
      this.setState({ reservationsExist: true });
  }
  reserveNextTable = async () => { // Add to table wait queue 'font-size:large'
    let guestCnt = Math.round(this.state.numberOfGuests);
    this.setState({ displayMessages: true });
    this.setState({ displayGuestCount: false });
    this.setState({ displayNextTable: false });
    this.setState({ displayMakeReservation: false });
    this.setState({ numberOfGuests: guestCnt });
    let CD = await CallOM("RequestTable",SessionInfo.StoreLocationID,guestCnt,0);
    //let msg = `<span style='color:black;font-size:14px;line-height:18px;'>You been added to the <span style='color:blue'>Table Waitlist</span> at<br/><span style='color:blue;font-size:16px;'> &nbsp; ${SessionInfo.SLV.StoreName}</span><br/>Requesting a table for <span style='color:red;font-size:16px;'>${guestCnt}</span> people<br/>You are currently number <span style='color:red;font-size:16px;'>${this.state.tableWaitNumber}</span> on the waitlist</span>`;       
    let dict = CD.d;
    this.setState({ reservationMessage: dict.MSG });
    SetNotify(NOTIFY.Alert,"You will receive a Message when your Table is ready");
    //this.setState({ showReserveTable: false });
  }
  showReservations = async () => {
    let CD = await CallOM("GetCurrentReservations",SessionInfo.StoreLocationID,0,0);
    let ReservationsList = CD.d.rows;
    let cnt = ReservationsList.length;
    this.setState({ ReservationsList });
    this.setState({ displayResList: true });
    trace(`Reservations Count: ${cnt}`);
  }
  makeAReservation = async () => { // Test if we can make a reservation
    let requestDT = this.state.requestDateTime;
    let guestCnt = Math.round(this.state.numberOfGuests);
    let hours = requestDT.getHours();
    let minutes = requestDT.getMinutes(); // toLocaleTimeString() ${hours}:${minutes}
    let AMPM = "AM";
    if (hours >= 12) {
      AMPM = "PM";
      if (hours > 12)
        hours -= 12;
    }
    let resTime = `${hours}:${minutes} ${AMPM}`;
    if (minutes < 10)
      resTime = `${hours}:0${minutes} ${AMPM}`;
    trace(`Make a reservation - time: ${resTime}, guest Count: ${guestCnt}`)
    let CD = await CallOM("MakeReservation",SessionInfo.StoreLocationID,guestCnt,CTYP.TypParm,[TYP.DT,requestDT,TYP.STR,this.state.reserveComments,TYP.BL,true]); // Test is True
    //let msg = `<span style='color:black;font-size:14px;line-height:18px;'>The time your requested <span style='color:blue'>${resTime}</span> at<br/><span style='color:blue;font-size:16px;'> &nbsp; ${SessionInfo.SLV.StoreName}</span><br/>For <span style='color:red;font-size:16px;'>${guestCnt}</span> guests on <span style='color:red;font-size:16px;'>${requestDT.toDateString()}</span><br/><span style='color:red;font-size:16px;'>Is NOT Available</span><br/><span>Please choose one of the Times below</span>`;
    //this.setState({ reservationMessage: msg });
    //this.setState({ TimeSlot1: '7:00 PM' });
    //this.setState({ TimeSlot2: '7:30 PM' });
    //this.setState({ TimeSlot3: '8:30 PM' });
    //this.setState({ TimeSlot4: '9:30 PM' });
    trace(`CD: ${JSON.stringify(CD)}`);
    let dict = CD.d;
    this.setState({ reservationMessage: dict.MSG });
    if (!dict.Time1) { // Successful Reservation Test - Confirm to make reservation
      this.setState({ displayGuestCount: false });
      this.setState({ displayNextTable: false });
      this.setState({ displayMakeReservation: false });
      this.setState({ reservationToBeConfirmed: true });
      this.setState({ displayMakeChoices: false });
      this.setState({ displayMessages: true });

    } else { // Unsuccessful Reservation
      this.setState({ TimeSlot1: dict.Time1 });
      this.setState({ TimeSlot2: dict.Time2 });
      this.setState({ TimeSlot3: dict.Time3 });
      this.setState({ TimeSlot4: dict.Time4 });
      this.setState({ displayGuestCount: false });
      this.setState({ displayNextTable: false });
      this.setState({ displayMakeReservation: false });
      this.setState({ displayMessages: true });
      this.setState({ displayMakeChoices: true });
    }
  }
  confirmReservation = async () => {
    let requestDT = this.state.requestDateTime;
    let guestCnt = Math.round(this.state.numberOfGuests);
    let hours = requestDT.getHours();
    let minutes = requestDT.getMinutes(); // toLocaleTimeString() ${hours}:${minutes}
    let AMPM = "AM";
    if (hours >= 12) {
      AMPM = "PM";
      if (hours > 12)
        hours -= 12;
    }
    let resTime = `${hours}:${minutes} ${AMPM}`;
    if (minutes < 10)
      resTime = `${hours}:0${minutes} ${AMPM}`;
    trace(`Make a reservation - time: ${resTime}, guest Count: ${guestCnt}`)
    let CD = await CallOM("MakeReservation",SessionInfo.StoreLocationID,guestCnt,CTYP.TypParm,[TYP.DT,requestDT,TYP.STR,this.state.reserveComments,TYP.BL,false]); // Test is False
    trace(`CD: ${JSON.stringify(CD)}`);
    let dict = CD.d;
    this.setState({ reservationMessage: dict.MSG });
    if (!dict.Time1) { // Successful Reservation  
      this.setState({ reservationToBeConfirmed: false });
      this.setState({ displayGuestCount: false });
      this.setState({ displayNextTable: false });
      this.setState({ displayMakeReservation: false });
      this.setState({ displayMakeChoices: false });
      this.setState({ displayMessages: true });
    }
  }
  deleteReservationClick = async (item) => {
    trace(`delete reservation: ${JSON.stringify(item)}`);
    let CD = await CallOM("DeleteReservation",SessionInfo.StoreLocationID,0,CTYP.INT,item.ReservationID);
    this.showReservations();
  }
  updateReserveComments = async (evt) => {
    this.setState({ reserveComments: evt.target.value });
  }
  makeAReservation1 = () => {
  }
  makeAReservation2 = () => {
  }
  makeAReservation3 = () => {
  }
  makeAReservation4 = () => {
  }
  resetReservations = () => {
    this.setState({ showReserveTable: false });
    this.setState({ displayMessages: false });
    this.setState({ displayGuestCount: true });
    this.setState({ displayNextTable: true });
    this.setState({ displayMakeReservation: true });
    this.setState({ numberOfGuests: 2 });
    this.setState({ reserveComments: '' });
    this.setState({ reservationToBeConfirmed: false });
    this.setState({ displayMakeChoices: false });
    this.setState({ displayResList: false });
  }
  cancelReserve = () => {
    if (this.state.displayResList === true) {
      this.setState({ displayResList: false });
    } else {
      if (this.state.displayMakeReservation === true) {
        this.resetReservations();
      } else {
        if (this.state.reservationToBeConfirmed === true) {
          this.setState({ displayMakeReservation: true });
          this.setState({ displayMessages: false });
        } else
          this.resetReservations();
      }
      if (this.state.reservationToBeConfirmed === true) {
        this.setState({ showReserveTable: true });
        this.setState({ displayGuestCount: true });
        this.setState({ displayMakeChoices: false });
      } else if (this.state.displayMakeChoices === true) {
        this.setState({ showReserveTable: true });
        this.setState({ displayGuestCount: true });
        this.setState({ displayMakeChoices: false });
      }
    }
    this.monitorProcess(true);
  }
  ResListHeader = () => {
    return (
      <ListViewHeader className='pl-4 pb-2 pt-2'>
        <span style={{ fontSize: 16,fontWeight: 'bold',color: "darkblue" }} >&nbsp;&nbsp; Current Reservations</span>
      </ListViewHeader>
    );
  }
  ResListFormat = props => {
    let item = props.dataItem;
    return (
      <div className="listRow">
        <span style={{ fontSize: 16,color: "black",fontWeight: 'bold' }} >{dateFormat(item.ReservationDateTime)}</span>
        <div className="listRowLoc1">
          <span style={{ fontSize: 16,fontWeight: 'bold',color: "darkblue" }} >&nbsp; At: {item.StoreLocationID}</span>
        </div>
        <div className="listRowLoc2">
        </div>
        <div className="listRowLoc3">
          <span style={{ fontSize: 16,color: "black",fontWeight: 'bold' }} >For {item.NumberOfPeople} Guests</span>
          <span className="rightMargin"><Button className="minButton" style={{ right: '2px' }} icon="marker-pin-target" onClick={(e) => this.deleteReservationClick(item)}> Delete </Button></span>
        </div>
      </div>
    );
  }
  ResListFooter = () => {
    return (
      <ListViewFooter className='listFooter3'>
        <div>
        </div>
      </ListViewFooter>
    );
  }
  //-------- End of Reservations -----------------------------------------------------------------------------------------------------------------------------
  storeLocationRowClick = async (item) => {
    trace(`store Location: ${JSON.stringify(item)}`);
    SessionInfo.StoreLocationID = item.StoreLocationID;
    this.setState({ StoreLocationID: item.StoreLocationID });
    if (SessionInfo.debug == true) {
      await GetLocationInfo(this.state.StoreLocationID,SessionInfo.CurrentTableID);
      trace(`SessionInfo.GeoLocation: ${JSON.stringify(SessionInfo.GeoLocation)}`); 
      this.setLatLong();
      trace(`latLong: ${JSON.stringify(latLong)}, imageID: ${SessionInfo.SLV.ImageID}`);
    }
    this.getStoreImage();
    await this.GetStoreHoursOfOperationForToday();
    let imageURL = await getImageFileFromServer(SessionInfo.SLV.ImageID);
    this.setState({ StoreLocationPhotoURL: imageURL });
    this.setState({ showLocationInfo: true });
  }
  setLatLong = () => {
    let locs = SessionInfo.GeoLocation.split(',');
    let lat = Number(locs[0]);
    let long = Number(locs[1]);
    SessionInfo.LatLong = { lat: lat,lng: long };
  }
  cancelLocation = () => {
    this.setState({ showLocationInfo: false });
    this.setState({ showLocationList: false });
    this.setState({ showReserveTable: false });
  }
  //-------------------------------------------------------------------------------------------------------------------------------------
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
  chgSliderVal(event) {
    //trace(event);
    this.setState({ [event.target.props.name]: event.value });
    this.setState({ stateHasChanged: true });
  }
  chgDTPickerVal(event) {
    //trace(event);
    this.setState({ requestDateTime: event.value });
    this.setState({ stateHasChanged: true });
  }
  //-------------------------------------------------------------------------------------------------------------------------------------
  //showMessage = (msg) => {
  //  this.messageColor = 'blue';
  //  this.messageCounter = 25;
  //  this.setState({ NotifyMessage: msg });
  //  this.setState({ activateMessageBox: true });
  //}
  showMessage = async (msg,displayColor,displayTime,displayRing) => {
    this.mesgHT = 90;
    document.documentElement.style.setProperty('--header-land-msg-ht',this.mesgHT + 'px');
    let LHT = 13 + this.mesgHT;
    document.documentElement.style.setProperty('--header-land-info',LHT + 'px');
    let HHT = 175 + this.mesgHT;
    document.documentElement.style.setProperty('--header-land-ht',HHT + 'px');
    this.setState({ NotifyMessage: SessionInfo.notifyMessage });
    SessionInfo.notifyTimer = SessionInfo.notifyTime;
    this.setState({ showNotify: true }); // Show the Message
    if (displayRing && (SessionInfo.SoundOn === true || SessionInfo.SoundOverride === true)) {
      //trace(`Play Notify Sound: ${SessionInfo.notifyRing}`);
      try {
        await displayRing.play();
      }
      catch (Ex) {
        trace(`Ring Ex: ${Ex}`);
      }
      SessionInfo.notifyRing = undefined;
      SessionInfo.SoundOverride = false;
    }
    if (displayColor)
      this.messageColor = displayColor;
    else
      this.messageColor = 'blue';
    if (displayTime)
      this.messageCounter = displayTime;
    else
      this.messageCounter = 25;
    this.setState({ NotifyMessage: msg });
    this.setState({ activateMessageBox: true });
  }
  messageClear = (msg) => {
    this.mesgHT = 0;
    document.documentElement.style.setProperty('--header-land-msg-ht',this.mesgHT + 'px');
    let LHT = 0;
    document.documentElement.style.setProperty('--header-land-info',LHT + 'px');
    let HHT = 160;
    document.documentElement.style.setProperty('--header-land-ht',HHT + 'px');
    this.messageColor = 'blue';
    this.messageCounter = 0;
    this.setState({ NotifyMessage: '' });
    this.setState({ activateMessageBox: false });
  }
  showWarn = (msg) => {
    this.showMessage(msg,"orange",30);
  }
  showError = (msg) => {
    this.showMessage(msg,"red",35);
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
  }
  updateType(evt) {
    trace("Type: " + evt.target.value);
    this.setState({ QuestionTypeID: evt.target.value });
  }
  scrollLocations = (event) => { // scroll the locations when too tall to fit in popup
    trace(`Scroll locations - posistion: ${event.currentTarget.scrollTop}`);
  }
  render() {
    const { hours,minutes } = this.state.currentTime;
    return (
      <div id="LandingPage" className="pageMainFx">
        {this.state.showLandingPage === true ?
          <>
          <div id="hdr" className="headerLanding">
            <div className="headerLogoLeft">
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }} onClick={(e) => this.gotoLanding()} />
            </div>
            <div className="headerCenter">
              <div className="headerCenterMenu">
                {SessionInfo.loggedOn === true && SessionInfo.accountLogon == true ?
                  <MenuBar alt="" fill={SessionInfo.headingTextColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} /> :
                  <span className="signin" style={{ color: 'white' }} onClick={(e) => this.signIn()}>Sign In</span>}
              </div>
              <div className="headerCenterTop">
                &nbsp; &nbsp; &nbsp;&nbsp;
                <span className='landingHeadText'>
                  {
                    hours === 0 ? 12 : (hours > 12) ? hours - 12 : hours
                  }:{
                    minutes > 9 ? minutes : `0${minutes}`
                  }
                </span> &nbsp; &nbsp; &nbsp;
                <span className='landingHeadText'>
                  {this.state.currentDate}
                </span>
              </div>

              <div className="headerCenterMidH">
                <span className='landingHeadText'>&nbsp;&nbsp; {SessionInfo.PersonInfo.FirstName} {SessionInfo.PersonInfo.LastName}</span>
                {SessionInfo.traceDebug ?
                  <span className='landingHeadTextSmall'>&nbsp; &nbsp; &nbsp;V: {SessionInfo.appVersion}-{SessionInfo.Platform}&nbsp;&nbsp;</span> : <span>&nbsp;&nbsp;</span>}
              </div>
            </div>
            {this.state.activateMessageBox ?
              <div className="headerLandMiddle">
                <div className="headerLandMessage">
                  <div dangerouslySetInnerHTML={{ __html: this.state.NotifyMessage }} style={{ color: this.messageColor }} onClick={(e) => { this.acknowledgeMessage(); }} />
                  {SessionInfo.acknowledge ?
                    <div className="bottomButton">
                      <Button icon="tell-a-friend" className="medButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
                    </div> : null} 
                </div>
              </div> : null}
            <div className="landingInformation">
              <div className="landingMessages" onClick={(e) => this.closeAll()}>
                {this.state.StoreLocationID === 0 ?
                  <div>
                    <div className="landingTextXXXL" >{SessionInfo.ECV.GreetingMessage}</div>
                    <div className="landingTextXXL" >{SessionInfo.ECV.SecondMessage}</div>
                    <span> &nbsp;  &nbsp;  &nbsp;  &nbsp; </span><Button className="landingButton" icon="marker-pin-target" onClick={this.viewLocations}> &nbsp; View Locations &nbsp; </Button>
                  </div>
                  :
                  <div>
                    <div className="landingTextXXXL" >{SessionInfo.SLV.Description}</div>
                    <div className="landingTextXXL" >&nbsp; {this.state.HoursForToday}</div>
                    <div>
                      <Button className="landingButton" icon="select-box" onClick={this.viewMenu}> &nbsp;View Menu &nbsp; </Button>
                    </div>
                  </div>}
              </div>
            </div> 
          </div> 

          <div className="landingImagesInfo">
              {this.state.StoreLocationID === 0 ?
                <div className="landingStoreInfo">
                  <div className="headerGreeting">
                    <span style={{ fontSize: 18,color: "white",fontWeight: "bold" }} >{this.state.GreetingMessage}</span>
                    {this.state.SecondMessage ?
                      <span style={{ fontSize: 18,color: "white",fontWeight: "bold" }} >{this.state.GreetingMessage}</span> : null}
                  </div>
                  <div className="headerTopImage">
                    <a id="companyImage" style={{ backgroundImage: `url(${this.state.companyImage})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />
                  </div>
                  <div className="headerLogoLeft">
                    <a id="brandLogo" style={{ backgroundImage: `url(${SessionInfo.BrandImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />
                  </div>
                  <div className="headerRight">
                    <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />
                  </div>
                  {/*--------------------------------------------------------------------*/}
                </div>
                :
                <div className="landingInfo">
                  <div className="headerTopImage">
                    <div className="frameFlexPic">
                      <Avatar rounded='large' size='large' type='image' className='avatarFramePic'>
                        <img src={this.state.promo0ImageURL} style={{ objectFit: 'fill',}} />
                      </Avatar>
                      <div className="landingTextP" >{this.state.promo0Description}</div>
                    </div>
                  </div>
                  <div>
                    <div className="headerPicLeft">
                      <div className="frameFlexPic">
                        <Avatar rounded='large' size='large' type='image' className='avatarFramePic'>
                          <img src={this.state.promo1ImageURL} style={{ objectFit: 'fill',}} />
                        </Avatar>
                        <div className="landingTextP" >{this.state.promo1Description}</div>
                      </div>
                    </div>
                    <div className="headerPicRight">
                      <div className="frameFlexPic">
                        <Avatar rounded='large' size='large' type='image' className='avatarFramePic'>
                          <img src={this.state.promo2ImageURL} style={{ objectFit: 'fill',}} />
                        </Avatar>
                        <div className="landingTextP" >{this.state.promo2Description}</div>
                      </div>
                    </div>
                    <br />
                  </div>
                  <div className="landingAdditional">
                    <div className="landingSubDiv">
                      <div className="landingSubTextDiv">
                        <span className="landingTextPS">There are <span style={{ fontWeight: 'bold',color: 'blue',}}>{this.locationCount}</span> <span style={{ fontWeight: 'bold',}}>{this.state.CompanyName}</span> locations ready to serve you and your guests.</span>
                      </div>
                      <Button className="landingSButton" icon="layout-1-by-4" onClick={this.viewLocations}>&nbsp;Locations List</Button>
                    </div>
                    <div className="landingSubDiv">
                      <div className="landingSubTextDiv">
                        <span className="landingTextPS">Welcome to <span style={{ fontWeight: 'bold',}}>{this.state.StoreName}</span>. {this.state.MarketingMessage}</span>
                      </div>
                      <Button className="landingSButton" icon="marker-pin-target" onClick={this.viewCurrentLocation}>&nbsp;Location Info&nbsp;&nbsp;</Button>
                    </div>
                    <div className="landingSubDiv">
                      <div className="landingSubTextDiv">
                        <span className="landingTextPS">Request the next available table for {this.state.StoreName} or make a reservation for a later date.</span>
                      </div>
                      <Button className="landingSButton" icon="marker-pin-target" onClick={this.reserveTable}>&nbsp;Request Table&nbsp;</Button>
                    </div>
                  </div>
                </div>}
            </div>
          </>
          :
          <div id="hdr" className="headerLanding">
          </div>}
        {/*----- List Locations ---------------------------------------------------------------*/}
        <Popup offset={this.listLocationsOffset} show={this.state.showLocationList} popupClass={'locationListPU'} >

          <div className="locationPopupDiv" onScroll={evt => this.scrollOptions(evt)}>   {/* Scrolling Div */}
            <div className="locationTopInfo">
            <div className="itemDetailHeader">
              <div className="optionLeft">
                 <div onClick={this.cancelLocation}>&nbsp; {this.backLandingPrompt}</div>
              </div>
            </div>

              {/*<div classname="headerloclist">*/}
            <div className="locationHeaderDesc">
              <span className='landingHeadTextLarge' >Restaurant Locations</span>

              <div className="headerListBottom">
              <div className="headerOrderCenterBot">
                <div id="headerSearch">
                  <div id="searchBtnDiv">
                    <Tooltip position="top" anchorElement="pointer">
                      <ButtonSearch alt="" fill={SessionInfo.searchColor} title={`Search Menu`} className="functionImage" onClick={this.searchButton} />
                    </Tooltip>
                  </div>
                  <div id="searchBoxDiv">
                    <FloatingLabel label='Search Locations' style={{ width: '300',color: 'darkblue' }} editorId={'search-id'} editorValue={this.state.searchValue}>
                      <Input value={this.state.searchValue} autoComplete="off" onChange={evt => this.updateSearchValue(evt)} className='searchInput' />
                    </FloatingLabel>
                  </div>
                  {this.state.searchValue.length > 0 ?
                    <div id="clearSearchBtnDiv">
                      <Tooltip position="top" anchorElement="pointer">
                        <ButtonClear alt="" fill={SessionInfo.searchColor} title={`Clear Search Box`} className="functionImage" onClick={this.clearSearch} />
                      </Tooltip>
                    </div> : null}
                </div>
              </div>
                </div>
              </div>

            </div>
            <div className="receiptList">
              <ListView data={this.state.LocationsList} item={this.LocationListFormat} style={{ width: "100%" }} /> {/* header={this.LocationListHeader} footer={this.LocationListFooter}*/}
            </div>
          </div>
        </Popup>
        {/*----- Location Detail ---------------------------------------------------------------*/}
        <Popup offset={this.showLocationOffset} show={this.state.showLocationInfo} popupClass={'locationListPU'} >
          <div className="locationPopupDiv" onScroll={evt => this.scrollOptions(evt)}>   {/* Scrolling Div */}
            <div className="itemDetailHeader">
              <div className="optionTopInfo">  {/* Back and Image */}
                <div className="optionTop">
                  <div className="optionLeft">
                    <div onClick={this.cancelLocation}>&nbsp; {this.backLandingPrompt}</div>
                  </div>
                </div>
                {/*-------- Image -----------------------------------------*/}
                <div className="optionTopImage">
                  <div className="optionFlexPic">
                    <Avatar rounded='large' size='large' type='image' className='optionFramePic'>
                      <img src={this.state.StoreLocationPhotoURL} style={{ objectFit: 'fill',}} />
                    </Avatar>
                  </div>
                </div> 
              </div>
            </div>

            <div className="locationTitle">
              <div className="landingRTextXL" >{SessionInfo.SLV.StoreName}</div>
              <div className="landingRSubText" >{SessionInfo.SLV.MarketingMessage}</div>
              <div className="landingRButtons" >
                <Button className="smallButton" icon="select-box" onClick={this.viewMenu}>View Menu </Button>
                <span> &nbsp;  &nbsp;  &nbsp;  &nbsp; </span>
                {SessionInfo.AC.HasReservations ?
                  <Button className="smallButton" icon="select-box" onClick={this.reserveTable}>Make Reservation</Button> : null}
              </div>
            </div>
            <br />
            <div id="locationHours">
              <span className="HoursToday">Hours of Operation</span>
              <br />
              {this.state.HoursLth > 0 ?
                <div id="HoursOfOpTop">
                  <span className="HoursToday" >{this.state.HoursForToday}</span>
                </div>
                : null}
              <div className="HoursOfOp">
                <span className="HoursOfOpLeft" >{this.state.HoursOfOperation[0]}</span> &nbsp; <span className="HoursOfOpTimeLeft" >{this.state.HoursOfOperation[this.state.HoursLth]}</span>
              </div>
              {this.state.HoursLth > 1 ?
                <div className="HoursOfOp">
                  <span className="HoursOfOpLeft" >{this.state.HoursOfOperation[1]}</span> &nbsp; <span className="HoursOfOpTimeLeft" >{this.state.HoursOfOperation[this.state.HoursLth + 1]}</span>
                </div>
                : null}
              {this.state.HoursLth > 2 ?
                <div className="HoursOfOp">
                  <span className="HoursOfOpLeft" >{this.state.HoursOfOperation[2]}</span> &nbsp; <span className="HoursOfOpTimeLeft" >{this.state.HoursOfOperation[this.state.HoursLth + 2]}</span>
                </div>
                : null}
              {this.state.HoursLth > 3 ?
                <div className="HoursOfOp">
                  <span className="HoursOfOpLeft" >{this.state.HoursOfOperation[3]}</span> &nbsp; <span className="HoursOfOpTimeLeft" >{this.state.HoursOfOperation[this.state.HoursLth + 3]}</span>
                </div>
                : null}
            </div>
            <br />
            <div id="mapDiv">
              <MyGoogleMap />
              {/*  <MapWithAMarkerWithLabel*/}
              {/*    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfCCwbCYQTfRjymntWxr__yuK85ypvRag&v=3.exp&libraries=geometry,drawing,places"*/}
              {/*    loadingElement={<div style={{ height: `100%` }} />}*/}
              {/*    containerElement={<div style={{ height: `200px` }} />}*/}
              {/*    mapElement={<div style={{ height: `100%` }} />}*/}
              {/*  />*/}
            </div>
            <div id="div2" className="landingBottom">
            </div>
          </div>
        </Popup>
        {/* Message Notify Popup */}
        {/*<Popup offset={SessionInfo.popupNotifyOffset} show={this.state.showNotify} popupClass={'popup-content'} >
          <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.NotifyMessage }} onClick={(e) => { this.changeOrder(this.acknowledgeMessage); }} />
            {SessionInfo.acknowledge ?
              <div className="bottomButton">
                <Button icon="tell-a-friend" className="medButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
              </div> : null}
          </Popup>*/}
        {/* Reserve Table */}
        <Popup offset={SessionInfo.popupNotifyOffset} show={this.state.showReserveTable} popupClass={'reservePU'} >
          <div>
            <div className="reserveDiv">
              <br />
              <span className="landingLTextXLB" >{SessionInfo.SLV.StoreName}</span><br /><br />
              <div className="landingLTextLB" >{SessionInfo.SLV.MarketingMessage}</div><br />
              <br />
              {this.state.displayResList ?
                <div id="div2" className="reservationListBlock">
                  <ListView data={this.state.ReservationsList} item={this.ResListFormat} style={{ width: "100%" }} header={this.ResListHeader} footer={this.ResListFooter} />
                </div> :
                <div>
                  <h4>Reserve Your Table</h4>
                  {this.state.displayGuestCount ?
                    <div>
                      <br />
                      <h4>Select Number of Guests</h4>
                      <br />
                      <Slider name="numberOfGuests" buttons={true} step={1} value={this.state.numberOfGuests} min={1} max={16} style={{ width: '98%' }} onChange={evt => this.chgSliderVal(evt)}>
                        <SliderLabel position={1}>1</SliderLabel>
                        <SliderLabel position={2}>2</SliderLabel>
                        <SliderLabel position={3}>3</SliderLabel>
                        <SliderLabel position={4}>4</SliderLabel>
                        <SliderLabel position={5}>5</SliderLabel>
                        <SliderLabel position={6}>6</SliderLabel>
                        <SliderLabel position={7}>7</SliderLabel>
                        <SliderLabel position={8}>8</SliderLabel>
                        <SliderLabel position={10}>10</SliderLabel>
                        <SliderLabel position={12}>12</SliderLabel>
                        <SliderLabel position={14}>14</SliderLabel>
                        <SliderLabel position={16}>16</SliderLabel>
                      </Slider>
                      <br />
                    </div> : null}
                  {this.state.displayNextTable ?
                    <div>
                      <br />
                      <br />
                      <div style={{ left: '20px',right: '20px' }}>
                        <Button className="smallButton" style={{ left: '20px',right: '20px' }} icon="marker-pin-target" onClick={this.reserveNextTable}> Request Next Available Table </Button>
                      </div>
                      <br />
                    </div> : null}
                  {this.state.displayMakeReservation ?
                    <div>
                      <br />
                      <h4>Reserve a Date and Time</h4>
                      <br />
                      <DateTimePicker name="requestDateTime" value={this.state.requestDateTime} onChange={evt => this.chgDTPickerVal(evt)} className="editDateInput" />
                      <br />
                      <br />
                      <div>
                        <FloatingLabel label="Reservation Comments" style={{ width: '200px',color: 'darkblue',marginTop: '5px' }} editorId={'reserveComments'} editorValue={this.state.reserveComments}>
                          <Input id={'reserveComments'} value={this.state.reserveComments} style={{ width: '275px',height: '40px' }} onChange={evt => this.updateReserveComments(evt)} />
                        </FloatingLabel>
                      </div>
                      <br />
                      <Button className="smallButton" style={{ left: '40px',right: '30px' }} icon="marker-pin-target" onClick={this.makeAReservation}> Make Your Reservation </Button>
                      <br />
                      <br />
                      {this.state.reservationsExist ?
                        <Button className="smallButton" style={{ left: '40px',right: '30px' }} icon="marker-pin-target" onClick={this.showReservations}> Show Reservations </Button> : null}
                      <br />
                    </div> : null}
                  <br />
                  {this.state.displayMessages ?
                    <div>
                      <div id="reserveMsgBox" className="reserveMessage" dangerouslySetInnerHTML={{ __html: this.state.reservationMessage }} />
                      <br />
                      {this.state.reservationToBeConfirmed ?
                        <>
                          <br />
                          <Button className="smallButton" style={{ left: '40px',right: '30px' }} icon="marker-pin-target" onClick={this.confirmReservation}> Confirm Your Reservation </Button></> : null}
                    </div> : null}
                  {this.state.displayMakeChoices ?
                    <div>
                      <h4>Select One of the Available Times</h4>
                      <br />
                      <Button className="smallButtonLeft" icon="clock" onClick={this.makeAReservation1}>&nbsp; &nbsp;{this.state.TimeSlot1}&nbsp; &nbsp;</Button>
                      <Button className="smallButtonRight" icon="clock" onClick={this.makeAReservation2}>&nbsp; &nbsp;{this.state.TimeSlot2}&nbsp; &nbsp;</Button><br />
                      <br />
                      <Button className="smallButtonLeft" icon="clock" onClick={this.makeAReservation3}>&nbsp; &nbsp;{this.state.TimeSlot3}&nbsp; &nbsp;</Button>
                      <Button className="smallButtonRight" icon="clock" onClick={this.makeAReservation4}>&nbsp; &nbsp;{this.state.TimeSlot4}&nbsp; &nbsp;</Button>
                    </div> : null}
                </div>}
              <div id="div2" className="landingBottomRes">
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                <Button id="cancelButton" icon="close-circle" className="smallButton" onClick={(e) => { this.cancelReserve() }} >&nbsp;Go Back &nbsp;</Button>
              </div>
            </div>
          </div>
        </Popup>
        {/*----- Menu -----*/}
        <Popup anchor={this.menuAnchor.current} show={this.state.showMenu} popupClass={'popupMenu'} >
          <div className="mainMenu">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
            <div className="menuTree">
              {SessionInfo.AppCfg.HasScanAndPay === false ?
                <br /> : null}
              <TreeView
                data={SessionInfo.MenuControlNQR}
                size='large' draggable='false' selection='single'
                onItemClick={this.onMenuItemClick}
                className='menuTreeSub'
                item={props => [<span key={props.item.key} className={props.item.className}>{props.item.icon}{props.item.space}{props.item.text}</span>]}
              />
            </div>
          </div>
        </Popup>

      </div>
    );
  }
}

export default withRouter(LandingPage)

//import React,{ Component } from 'react'
//import { LoadScript,GoogleMap,Marker } from '@react-google-maps/api'

//class NewMap extends Component {

//  state = { map: {} }

//  boundsCallBack = () => {
//    const { map } = this.state;
//    trace('map: ',map)
//  }

//  handleMapLoad = (map) => {
//    this.setState((state) => ({ ...state,map }));
//  }

//  render() {
//    return (
//      <LoadScript googleMapsApiKey={process.env.REACT_APP_MAP_KEY}>
//        <GoogleMap
//          {...this.props.theProps}
//          onDragEnd={this.boundsCallBack}
//          onLoad={this.handleMapLoad}
//        >
//          <Marker position={this.props.theProps.center} />
//        </GoogleMap>
//      </LoadScript>
//    )
//  }
//}

//export default NewMap