//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// CustomerProfile - Interact Suite Customer  - Detailed information about the Customer, Customer settings, and information regarding subscribed projects
//                      Version 1.007 - June 5, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';
import { withRouter } from './withRouter';
import { Buffer } from 'buffer';
import './App.css';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { Popup } from '@progress/kendo-react-popup';
import { TreeView } from '@progress/kendo-react-treeview';
import { Switch } from "@progress/kendo-react-inputs";
import { Avatar } from '@progress/kendo-react-layout';
import { Upload } from "@progress/kendo-react-upload";
import { Input } from "@progress/kendo-react-inputs";
import { FloatingLabel } from '@progress/kendo-react-labels'
import { SessionInfo } from './App';
import { ENAppCnfg,ENLayoutCnfg } from './CommonInfo.js';
import { CallOM,DeleteRowTry,trace,displayMessage,displayWarning,ChangePassword,deleteCookie,GetTableSearch,testBit,LogOffResetMsg,getImageFileFromServer,GetDropdownData,getDocumentType,saveImageFileToServer,CTYP,TYP,NOTIFY,SetNotify } from './CommonCode.js';
import { cellDateTime, cellCurrency } from './CommonFormat.js';

import { ReactComponent as MenuBar } from "./images/bars.svg";

let ItemCategoriesDD = [];
let playerORImage = undefined;

class CategoryDropDownCell extends React.Component {
  handleChange = (e) => { // e.target.value is the object {ddID: n, ddName: 'n'}
    //trace("change - field: " + this.props.field + "  value: " + JSON.stringify(e.target.value) + " dataitem: " + JSON.stringify(this.props.dataItem));
    this.props.onChange({
      dataItem: this.props.dataItem,
      field: this.props.field,
      syntheticEvent: e.syntheticEvent,
      value: e.target.value
    });
    this.props.dataItem[this.props.field] = e.target.value.ddID;
    this.forceUpdate();
  }
  render() {
    let dataValue = this.props.dataItem[this.props.field];
    if (dataValue === undefined || dataValue === null)
      dataValue = 0;
    if (!this.props.dataItem.inEdit) {
      if (dataValue === 0)
        return (<td>{" "}</td>);
      else if (ItemCategoriesDD.find(c => c.ddID === dataValue) === undefined)
        return (
          <td>{"Unknown: " + dataValue}</td>
        );
      else
        return (
          <td>
            {ItemCategoriesDD.find(c => c.ddID === dataValue).ddName}
          </td>
        );
    }
    return (
      <td>
        <DropDownList style={{ width: "100px" }} data={ItemCategoriesDD} textField="ddName" dataItemKey="ddID" value={ItemCategoriesDD.find(c => c.ddID === dataValue)} onChange={this.handleChange} />
      </td>
    );
  }
}
//------ Object Definition For Customers -- Date: 2023-09-15-7:49 AM ------
const CustomersOID = 1020151;
const CustomersO = [{ n: 'CustomerID',t: 195,p: 100 },{ n: 'FirstName',t: 21,p: 0 },{ n: 'AdditionalName',t: 21,p: 0 },{ n: 'LastName',t: 21,p: 0 },{ n: 'Address',t: 21,p: 0 },{ n: 'City',t: 21,p: 0 },{ n: 'ProvinceStateID',t: 195,p: 100 },{ n: 'CountryID',t: 195,p: 100 },{ n: 'PostalCode',t: 21,p: 0 },{ n: 'DateOfBirth',t: 34,p: 0 },{ n: 'Gender',t: 201,p: 0 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'PhoneNumber2',t: 21,p: 0 },{ n: 'EmailAddress',t: 21,p: 0 },{ n: 'EmailReceipts',t: 22,p: 0 },{ n: 'EmailPromotions',t: 22,p: 0 },{ n: 'SMSReminders',t: 22,p: 0 },{ n: 'SoundOn',t: 22,p: 0 },{ n: 'AutoScanLocation',t: 22,p: 0 },{ n: 'FlashOnScan',t: 22,p: 0 },{ n: 'CustomerIsInStore',t: 22,p: 0 },{ n: 'CustomerIsActive',t: 22,p: 0 },{ n: 'CustomerOrderActive',t: 22,p: 0 },{ n: 'MultiPersonOrder',t: 22,p: 0 },{ n: 'PaymentInProcess',t: 22,p: 0 },{ n: 'HasAbandonedOrder',t: 22,p: 0 },{ n: 'CustomerHasPaid',t: 22,p: 0 },{ n: 'HasExceededMax',t: 22,p: 0 },{ n: 'TemporaryCustomer',t: 22,p: 0 },{ n: 'TemporaryInUse',t: 22,p: 0 },{ n: 'TotalPurchaseAmount',t: 28,p: 0 },{ n: 'MaximumPurchaseAmount',t: 28,p: 0 },{ n: 'TodayPurchaseAmount',t: 28,p: 0 },{ n: 'PersonPhotoID',t: 41,p: 0 },{ n: 'DeviceProfileID',t: 195,p: 100 },{ n: 'OMUserID',t: 195,p: 100 },{ n: 'AccessToken',t: 21,p: 0 },{ n: 'TransactionCount',t: 24,p: 0 },{ n: 'AbandonedSalesCount',t: 24,p: 0 },{ n: 'CardAddCount',t: 24,p: 0 },{ n: 'CustomerConfig',t: 15,p: 0 },{ n: 'CustomerControls',t: 15,p: 0 },{ n: 'GeoLocation',t: 21,p: 0 },{ n: 'RoleID',t: 195,p: 100 },{ n: 'LastTimeActive',t: 35,p: 0 },{ n: 'LastStoreVisitedID',t: 24,p: 0 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'SublocationID',t: 195,p: 100 },{ n: 'StorePromotionID',t: 195,p: 100 },{ n: 'PurchaseFrequency',t: 24,p: 0 },{ n: 'PaceToken',t: 21,p: 0 },{ n: 'PaceID',t: 21,p: 0 },{ n: 'PacePassword',t: 21,p: 0 },{ n: 'UseCount',t: 24,p: 0 }];
//       Object Value Definition For Customers
const CV = { 'CustomerID': 0,'FirstName': '','AdditionalName': '','LastName': '','Address': '','City': '','ProvinceStateID': 0,'CountryID': 0,'PostalCode': '','DateOfBirth': new Date(),'Gender': 0,'PhoneNumber': '','PhoneNumber2': '','EmailAddress': '','EmailReceipts': false,'EmailPromotions': false,'SMSReminders': false,'SoundOn': false,'AutoScanLocation': false,'FlashOnScan': false,'CustomerIsInStore': false,'CustomerIsActive': false,'CustomerOrderActive': false,'MultiPersonOrder': false,'PaymentInProcess': false,'HasAbandonedOrder': false,'CustomerHasPaid': false,'HasExceededMax': false,'TemporaryCustomer': false,'TemporaryInUse': false,'TotalPurchaseAmount': 0,'MaximumPurchaseAmount': 0,'TodayPurchaseAmount': 0,'PersonPhotoID': '','DeviceProfileID': 0,'OMUserID': 0,'AccessToken': '','TransactionCount': 0,'AbandonedSalesCount': 0,'CardAddCount': 0,'CustomerConfig': 0,'CustomerControls': 0,'GeoLocation': '','RoleID': 0,'LastTimeActive': new Date(),'LastStoreVisitedID': 0,'StoreLocationID': 0,'SublocationID': 0,'StorePromotionID': 0,'PurchaseFrequency': 0,'PaceToken': '','PaceID': '','PacePassword': '','UseCount': 0 };
//       Object Index Definition For Customers
const CX = { 'CustomerID': 0,'FirstName': 1,'AdditionalName': 2,'LastName': 3,'Address': 4,'City': 5,'ProvinceStateID': 6,'CountryID': 7,'PostalCode': 8,'DateOfBirth': 9,'Gender': 10,'PhoneNumber': 11,'PhoneNumber2': 12,'EmailAddress': 13,'EmailReceipts': 14,'EmailPromotions': 15,'SMSReminders': 16,'SoundOn': 17,'AutoScanLocation': 18,'FlashOnScan': 19,'CustomerIsInStore': 20,'CustomerIsActive': 21,'CustomerOrderActive': 22,'MultiPersonOrder': 23,'PaymentInProcess': 24,'HasAbandonedOrder': 25,'CustomerHasPaid': 26,'HasExceededMax': 27,'TemporaryCustomer': 28,'TemporaryInUse': 29,'TotalPurchaseAmount': 30,'MaximumPurchaseAmount': 31,'TodayPurchaseAmount': 32,'PersonPhotoID': 33,'DeviceProfileID': 34,'OMUserID': 35,'AccessToken': 36,'TransactionCount': 37,'AbandonedSalesCount': 38,'CardAddCount': 39,'CustomerConfig': 40,'CustomerControls': 41,'GeoLocation': 42,'RoleID': 43,'LastTimeActive': 44,'LastStoreVisitedID': 45,'StoreLocationID': 46,'SublocationID': 47,'StorePromotionID': 48,'PurchaseFrequency': 49,'PaceToken': 50,'PaceID': 51,'PacePassword': 52,'UseCount': 53 };

//------ Encoded Values - CustomerConfig ------
const CustomerConfigFixedValues = [{ n: 'Customer Reset',k: 1 },{ n: 'Diet Vegetarian',k: 5 },{ n: 'Diet Vegan',k: 6 },{ n: 'Diet Nut Alergy',k: 7 },{ n: 'Diet Lactose',k: 8 },{ n: 'Diet Gluten',k: 9 },{ n: 'Diet Other',k: 12 }];
//------ Encoded Entries - CustomerConfig ------
const CustomerConfigList = ['Customer Reset','Diet Vegetarian','Diet Vegan','Diet Nut Alergy','Diet Lactose','Diet Gluten','Diet Other'];
//------ Encoded Values - CustomerControls ------
const CustomerControlsFixedValues = [{ n: 'Customer Active',k: 1 },{ n: 'Customer On Hold',k: 2 },{ n: 'Customer Verified',k: 3 },{ n: 'Customer Password Verified',k: 4 },{ n: 'Customer Has Credit',k: 5 },{ n: 'Customer Direct Pay',k: 6 },{ n: 'Customer Watch',k: 7 },{ n: 'Common Customer',k: 20 }];
//------ Encoded Entries - CustomerControls ------
const CustomerControlsList = ['Customer Active','Customer On Hold','Customer Verified','Customer Password Verified','Customer Has Credit','Customer Direct Pay','Customer Watch','Common Customer'];

//------ Object Definition For Addresses -- Date: 2023-11-28-7:00 PM ------
const AddressesOID = 110191;
const AddressesO = [{ n: 'AddressID',t: 195,p: 100 },{ n: 'CompanyID',t: 195,p: 100 },{ n: 'CustomerID',t: 195,p: 100 },{ n: 'UnitNumber',t: 21,p: 0 },{ n: 'Address',t: 21,p: 0 },{ n: 'Address2',t: 21,p: 0 },{ n: 'City',t: 21,p: 0 },{ n: 'CityID',t: 195,p: 100 },{ n: 'ProvinceStateID',t: 195,p: 100 },{ n: 'PostalCode',t: 21,p: 0 },{ n: 'CountryID',t: 195,p: 100 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'IsPrime',t: 22,p: 0 }];
//       Object Value Definition For Addresses
const AV = { 'AddressID': 0,'CompanyID': 0,'CustomerID': 0,'UnitNumber': '','Address': '','Address2': '','City': '','CityID': 0,'ProvinceStateID': 0,'PostalCode': '','CountryID': 0,'PhoneNumber': '','IsPrime': false };
//       Object Index Definition For Addresses
const AX = { 'AddressID': 0,'CompanyID': 1,'CustomerID': 2,'UnitNumber': 3,'Address': 4,'Address2': 5,'City': 6,'CityID': 7,'ProvinceStateID': 8,'PostalCode': 9,'CountryID': 10,'PhoneNumber': 11,'IsPrime': 12 };

//------ Object Definition For CustomerAccounts -- Date: 2024-01-26-9:02 AM ------
const CustomerAccountsOID = 545756;
const CustomerAccountsO = [{ n: 'CustomerID',t: 195,p: 100 },{ n: 'Amount',t: 28,p: 0 },{ n: 'TransactionCount',t: 24,p: 0 },{ n: 'MinusTransactions',t: 24,p: 0 },{ n: 'PlusTransactions',t: 24,p: 0 },{ n: 'TotalMinusAmount',t: 28,p: 0 },{ n: 'TotalPlusAmount',t: 28,p: 0 },{ n: 'MaximumAmount',t: 28,p: 0 },{ n: 'LastUsedOn',t: 35,p: 0 },{ n: 'CustomerAccountStatus',t: 201,p: 0 },{ n: 'Checksum',t: 27,p: 0 }];
//       Object Value Definition For CustomerAccounts
const CAV = { 'CustomerID': 0,'Amount': 0,'TransactionCount': 0,'MinusTransactions': 0,'PlusTransactions': 0,'TotalMinusAmount': 0,'TotalPlusAmount': 0,'MaximumAmount': 0,'LastUsedOn': new Date(),'CustomerAccountStatus': 0,'Checksum': 0 };
//       Object Index Definition For CustomerAccounts
const CAX = { 'CustomerID': 0,'Amount': 1,'TransactionCount': 2,'MinusTransactions': 3,'PlusTransactions': 4,'TotalMinusAmount': 5,'TotalPlusAmount': 6,'MaximumAmount': 7,'LastUsedOn': 8,'CustomerAccountStatus': 9,'Checksum': 10 };

const uploadRef = React.createRef();

class ProfilePage extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    //trace("CustomerProfile");
    if (!SessionInfo || !SessionInfo.session)
      this.props.navigate("/AppMain");
    else {
      if (SessionInfo.CustomerProfile) { // Data saved for this session
        this.state = SessionInfo.CustomerProfile;
        this.loadExistingPage = true;
      }
      else {
        this.loadExistingPage = false;
      }
    }
  }
  menuAnchor = React.createRef();
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  // State
  state = {
    //------ Integer Values - Customers ------
    CustomerID: 0,
    ProvinceStateID: 0,
    Gender: 0,
    EmailReceipts: false,
    EmailPromotions: false,
    SMSReminders: false,
    SoundOn: false,
    AutoScanLocation: false,
    FlashOnScan: false,
    TemporaryCustomer: false,
    TemporaryInUse: false,
    TotalPurchaseAmount: 0,
    MaximumPurchaseAmount: 0,
    DeviceProfileID: 0,
    OMUserID: 0,
    AbandonedSalesCount: 0,
    CardAddCount: 0,
    RoleID: 0,
    LastTimeActive: 0,

    //CustomOrderStatus: 0,
    //OrderCreationDate: 0,
    //OrderPaidDate: 0,
    //AmountOwing: 0,
    //ItemCount: 0,

    CountryID: 0,
    DateOfBirth: 0,
    TodayPurchaseAmount: 0,
    PersonPhotoID: 0,
    Gender: 0,
    EmailReceipts: false,
    EmailPromotions: false,
    SMSReminders: false,
    SoundOn: false,
    AutoScanLocation: false,
    FlashOnScan: false,
    CustomerIsInStore: false,
    CustomerIsActive: false,
    CustomerOrderActive: false,
    MultiPersonOrder: false,
    PaymentInProcess: false,
    HasAbandonedOrder: false,
    CustomerHasPaid: false,
    HasExceededMax: false,
    TemporaryCustomer: false,
    //--------------------------------
    // CustomerAccounts
    Amount: 0,
    TransactionCount: 0,
    MinusTransactions: 0,
    PlusTransactions: 0,
    TotalMinusAmount: 0,
    TotalPlusAmount: 0,
    MaximumAmount: 0,
    LastUsedOn: 0,
    CustomerAccountStatus: 0,
    CustomerConfig: 0,
    //------ Encoded Bit Values For CustomerConfig -------
    CustomerReset: false,
    DietVegetarian: false,
    DietVegan: false,
    DietNutAlergy: false,
    DietLactose: false,
    DietGluten: false,
    DietOther: false,
    CustomerControls: 0,
    //------ Integer Values - CustomOrders ------
    CustomOrderID: 0,
    StoreLocationID: 0,
    CustomOrderStatus: 0,
    CustomOrderType: 0,
    RequestDateTime: 0,
    ScheduledPrepDateTime: 0,
    PickupDeliveryDateTime: 0,
    TotalPrice: 0,
    AmountOwing: 0,
    TotalTaxAmount: 0,
    TotalTax2Amount: 0,
    TotalTax3Amount: 0,
    AmountPaid: 0,
    DiscountAmount: 0,
    CreditAmountUsed: 0,
    RefundAmount: 0,
    TipAmount: 0,
    AmountPaidWithTip: 0,
    ItemCount: 0,
    TotalQuantity: 0,
    OrderCreationDate: '',
    OrderPaidDate: 0,
    PaymentMethodID: 0,
    RefundTaxAmount: 0,
    IndividualItems: 0,
    TransactionID: 0,
    VerifiedDate: 0,
    VerifiedBy: 0,
    OrderPreparationTypeID: 0,
    ReturnCycle: 0,
    CompletedReturnCycle: 0,
    RecordProcessed: 0,
    //------ String Values - CustomOrders ------
    PaidComment: '',
    OrderNotes: '',
    PaymentAuthCode: '',
    OrderNumber: '',
    FirstName: '',
    AdditionalName: '',
    LastName: '',
    Address: '',
    City: '',
    PostalCode: '',
    PhoneNumber: '',
    PhoneNumber2: '',
    EmailAddress: '',
    AccessToken: '',
    GeoLocation: '',

    selectedTopTab: 0,
    selectedBottomTab: 0,

    isMainSearch: true,
    currentCustomerID: 0,
    //PersonPhoto: '',
    PersonPhotoURL: undefined,
    isWalletUser: false,
    contractsVisible: false,
    StoreLocationID: 0,
    selectCustomerID: 0,

    CustomOrders: [],
    customOrdersSkip: 0,
    customOrdersTake: 8,
    receiptFilter: { logic: "and",filters: [] },
    receiptSort: [],

    CustomOrderItems: [],
    customOrderItemsSkip: 0,
    customOrderItemsTake: 8,

    accountActive: false,
    accountHold: false,
    accountHasCredit: false,
    customerDirectPay: false,
    customerWatch: false,

    Customers: [],
    saveCustomers: [],
    customersSkip: 0,
    customersTake: 20,

    Roles: [],
    CustomerAccountStatusInfo: [],
    CreditAmount: 0,
    loginMessage: '',
    mesgColor: 'black',

    emReadOnly: false,
    password: '',
    confirmPassword: '',
    showPassword: false,
    stayLogedOn: false,
    changeDone: false,
    hasChanged: false,

    showChangePWPopup: false,
    showAccountPopup: false,
    showTakePicturePopup: false,
    imageDataURL: null,
    //------ Integer Values - CustomerAccounts ------
    Amount: 0,
    AmountS: '',
    TransactionCount: 0,
    MinusTransactions: 0,
    PlusTransactions: 0,
    TotalMinusAmount: 0,
    TotalPlusAmount: 0,
    MaximumAmount: 0,
    MaximumAmountS: '',
    LastUsedOn: 0,
    CustomerAccountStatus: 0,
    Checksum: 0,
    //------ Dictionary Arrays - CustomOrders ------
    CustomOrdersDict: [],
    StoreLocationsDict: [],
    CustomersDict: [],
    CustomOrderStatuses: [],
    CustomOrderTypes: [],
    PaymentMethodsDict: [],
    OrderPreparationTypesDict: [],
    ProvinceStateCodesDict: [],
    CountriesDict: [],

    horizontalPanes: [{ size: '20%',min: '20px',collapsible: false },{ min: '300px',collapsible: false },{ size: '20%',collapsed: true,collapsible: true }], // Left, Right
    verticalPanes: [{ min: '300px' },{ size: '50%',collapsible: true,collapsed: true }],  // Top, Bottom
  };
  vertSplitC = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":true,"resizable":true,"scrollable":true,"size":"50%"}]');
  vertSplitO = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"50%"}]');

  horizSplitOC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCC = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": true, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitCO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"50px"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');
  horizSplitOO = JSON.parse('[{"0":"[","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"size":"20%"},{"0":"{","collapsible":false,"collapsed":false,"resizable":true,"scrollable":true}, { "collapsible": true, "collapsed": false, "resizable": true, "scrollable": true, "size": "20%" }]');

  saveEmailAddress = "";
  createKeys;
  loadExistingPage = false;
  lastSelectedPID = 0;
  compUpdate = true;
  popupOffset = { left: 20,top: 70 };
  popupAccountOffset = { left: 2,top: 70 };
  popupPictureOffset = { left: 10,top: 200 };
  cameraNumber = 0;

  tabs = {
    CustomerInfo: 0,
    Setup: 1,
    Receipts: 2,
    Account: 3
  }
  bottomTabs = {
    Receipts: 0
  }

  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
    SessionInfo.currentPage = "CustomerProfile";
    let windowHt = window.innerHeight;
    trace(`refresh: ${SessionInfo.refreshCustomerProfile}`);
    if (SessionInfo.refreshCustomerProfile === true) {
      this.loadExistingPage = false;
      SessionInfo.refreshCustomerProfile = false;
    }
    this.setState({ showMenu: false });
    this.setState({ hasChanged: false });
    if (this.loadExistingPage === true) {
      this.state = SessionInfo.CustomerProfile;
      this.forceUpdate();
    } else {
      this.setState({ selectCustomerID: SessionInfo.PersonInfo.CustomerID });
      this.lastSelectedPID = SessionInfo.PersonInfo.CustomerID;
      //if (SessionInfo.SelectedCustomerID > 0) {
      //trace("Get Customer Info for: " + SessionInfo.SelectedCustomerID); 
      await this.getCustomerRecord(SessionInfo.PersonInfo.CustomerID);
      if (this.state.ProvinceStateCodesDict.length <= 1) {
        let CD = await GetDropdownData(489030);
        this.setState({ ProvinceStateCodesDict: CD.d });
      }
      if (this.state.CountriesDict.length <= 1) {
        let CD = await GetDropdownData(154824);
        this.setState({ CountriesDict: CD.d });
      }
    }
    this.setState({ StoreName: SessionInfo.StoreName });
    this.setState({ TableName: SessionInfo.CurrentTableName });
    this.setState({ SeatName: SessionInfo.SeatName });
    this.setState({ SeatCount: SessionInfo.SeatCount });
    this.setState({ ItemCount: SessionInfo.ItemCount });
    this.setState({ showChangePWPopup: false });
    this.setState({ showTakePicturePopup: false });
    this.monitorProcess(true);
    trace(`Check Camera Persmission`);
    navigator.permissions.query({ name: 'camera' })
      .then((permissionObj) => {
        console.log(permissionObj.state);
        permission = permissionObj.state;
        console.log(`permission: ${permissionObj}`);
      })
      .catch((error) => {
        console.log(`Enable Camera: ${error}`);
      });
  }
  async componentWillUnmount() {
    trace(`ProfilePage will unmount`);
    if (SessionInfo.session !== '') {
      this.setState({ showMenu: false });
      this.saveRecord();
      SessionInfo.CustomerProfile = this.state;
    }
  }
  shouldComponentUpdate() {
    //trace("Should Update?");
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  runMonitorProcess = false;
  setTimer() {
    if (SessionInfo.loggedOn === true && this.runMonitorProcess === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this),200); // 200 microseconds  
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess = async (force) => { // Note - Monitor only runs if logged on
    if (force)
      this.runMonitorProcess = true;
    //trace(`monitor logoff: ${SessionInfo.forceLogoff}, On: ${SessionInfo.loggedOn}`); 
    if (SessionInfo.forceLogoff === true) {
      SessionInfo.forceLogoff = false;
      this.props.navigate("/Login");
    }
    if (this.runMonitorProcess === true && SessionInfo.loggedOn === true) {
      this.setTimer();
      if (1 === 1 || document.hasFocus() === true) { // Execute monitor process
        //---- Ping and Notification Processing -------------
        if (SessionInfo.setShowNotify > 0 && SessionInfo.notifyMessage.length > 0) {
          this.setState({ notifyMessage: SessionInfo.notifyMessage });
          SessionInfo.notifyTimer = SessionInfo.notifyTime;
          this.setState({ showNotify: true }); // Show the Message  
          if (SessionInfo.notifyRing && (SessionInfo.SoundOn === true || SessionInfo.SoundOverride === true)) {
            //trace(`Play Notify Sound: ${SessionInfo.notifyRing}`);
            try {
              await SessionInfo.notifyRing.play();
            }
            catch (Ex) {
              trace(`Ring Ex: ${Ex}`);
            }
            SessionInfo.notifyRing = undefined;
            SessionInfo.SoundOverride = false;
          }
        }
        SessionInfo.setShowNotify = 0;
        if (SessionInfo.notifyTimer > 0) {
          if (--SessionInfo.notifyTimer <= 0)
            this.setState({ showNotify: false });
        } else if (SessionInfo.showNotify === true)
          this.setState({ showNotify: false }); // Hide the Notify Message
        if (SessionInfo.pingCounter++ > 50) {
          SessionInfo.pingCounter = 0;
          let CD = await CallOM("PingApp",SessionInfo.StoreLocationID,SessionInfo.CustomOrderID,CTYP.STR,"");
          if (CD && CD.d && CD.d.rows) {
            //trace(`Return from PingApp - CD: ${JSON.stringify(CD)}`);
            let rows = CD.d.rows;
            for (let ix = 0; ix < rows.length; ix++) {
              let row = rows[ix];
              SetNotify(row["NotificationMessage"],row["NotificationTypeID"]);
              SessionInfo.AppNotificationID = row["AppNotificationID"];
            }
          }
        }
      }
      //---- End of Notification Processing -------------
    }
  }
  acknowledgeMessage = async () => {
    let CD = await CallOM("AcknowledgeNotification",0,SessionInfo.AppNotificationID);
    this.setState({ showNotify: false });
    SessionInfo.notifyTimer = 50;
    SessionInfo.acknowledge = false;
    this.forceUpdate();
  }
  //-------------------------------- Picture Taking ----------------------------------
  initializeMedia = async () => {
    try {
      this.setState({ imageDataURL: null });
      if (!("mediaDevices" in navigator)) {
        navigator.mediaDevices = {};
      }
      if (!("getUserMedia" in navigator.mediaDevices)) {
        navigator.mediaDevices.getUserMedia = function (constraints) {
          var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia;
          if (!getUserMedia) {
            return Promise.reject(new Error("getUserMedia Not Implemented"));
          }
          return new Promise((resolve,reject) => {
            getUserMedia.call(navigator,constraints,resolve,reject);
          });
        };
      }
      // Get the details of video inputs of the device
      let videoInputs = await this.getListOfVideoInputs();
      if (videoInputs == undefined || videoInputs.length < 1) {
        await this.askCameraPermission();
        videoInputs = await this.getListOfVideoInputs();
      }
      // The device has a camera
      if (videoInputs.length) {
        navigator.mediaDevices
          .getUserMedia({ video: { deviceId: { exact: videoInputs[this.cameraNumber].deviceId } } })
          .then((stream) => {
            this.player.srcObject = stream;
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        alert("The device does not have a camera");
      }
    }
    catch (error) {
      trace(`Failure in InitializeMedia: ${error}`,11);
    }
  }
  stopCamera = () => {
    trace(`Stop Camera`);
    this.player.srcObject.getVideoTracks().forEach((track) => { track.stop(); });
    this.setState({ showTakePicturePopup: false });
  }
  capturePicture = async () => { // Take a snapshot 
    try {
      var canvas = document.createElement("canvas");
      //trace(`width: ${this.player.videoWidth}, height: ${this.player.videoHeight}`);
      canvas.width = this.player.videoWidth;
      canvas.height = this.player.videoHeight;
      var contex = canvas.getContext("2d");
      contex.drawImage(this.player,0,0,canvas.width,canvas.height);
      this.player.srcObject.getVideoTracks().forEach((track) => { if (track.readyState === 'live' && track.kind === 'video') track.stop(); });
      //trace(canvas.toDataURL());      
      var img = canvas.toDataURL("image/jpeg",0.75);
      this.setState({ PersonPhotoURL: img });
      let imageType = 'jpg';
      //trace(`imageType: ${imageType}`);
      let Uint8Ar = this.base64ToArrayBuffer(img.replace('data:image/jpeg;base64,',''));
      let iVal = await saveImageFileToServer(Uint8Ar,imageType,CustomersOID,SessionInfo.PersonInfo.CustomerID,1040572,2);
      this.setState({ showTakePicturePopup: false });
    }
    catch (error) {
      trace(`Failure in Photo Capture: ${error}`,11);
    }
  };
  base64ToArrayBuffer = (base64) => {
    let binary_string = window.atob(base64);
    let len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes;
  }
  // could try converting using memorystream later - rather than for loop
  //getJpegBytes = (canvas) => {
  //  return new Promise((resolve,reject) => {
  //    const fileReader = new FileReader()
  //    fileReader.addEventListener('loadend',function () {
  //      if (this.error) {
  //        reject(this.error)
  //      } else {
  //        resolve(this.result)
  //      }
  //    })
  //    canvas.toBlob(blob => fileReader.readAsArrayBuffer(blob),'image/jpeg')
  //  })
  //}

  switchCamera = async () => {
    const listOfVideoInputs = await this.getListOfVideoInputs();
    // The device has more than one camera
    if (listOfVideoInputs.length > 1) {
      if (this.player.srcObject) {
        this.player.srcObject.getVideoTracks().forEach((track) => {
          track.stop();
        });
      }
      // Switch to second camera
      if (this.cameraNumber === 0) {
        this.cameraNumber = 1;
      }
      // switch to first camera
      else if (this.cameraNumber === 1) {
        this.cameraNumber = 0;
      }
      // Restart based on camera input
      this.initializeMedia();
    } else if (listOfVideoInputs.length === 1) {
      alert("The device has only one camera");
    } else {
      alert("The device does not have a camera");
    }
  };
  askCameraPermission = async () => {
    //Promise < MediaStream | null > =>
    await navigator.mediaDevices.getUserMedia({ video: true });
  }
  getListOfVideoInputs = async () => {
    // Get the details of audio and video output of the device
    const enumerateDevices = await navigator.mediaDevices.enumerateDevices();
    //Filter video outputs (for devices with multiple cameras)
    return enumerateDevices.filter((device) => device.kind === "videoinput");
  };
  takePicture = async () => {
    await this.showCamera();
  }
  showCamera = async () => {
    playerORImage = Boolean(this.state.imageDataURL) ?
      <img src={this.state.imageDataURL} alt="cameraPic" /> :
      <video ref={(refrence) => { this.player = refrence; }} autoPlay width="292px" height="222px" top='0' margin='1' padding='1' style={{ border: '1px solid gray',}} ></video>;
    await this.initializeMedia();
    this.setState({ showTakePicturePopup: true });
  }
  //---------------------------------------------------------------------------------------------
  //--------------- Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  saveRecord = async () => { // saveCustomer
    trace(`Save Customer - RoleID: ${this.state.RoleID}, PermLevel: ${SessionInfo.PermLevel}`);
    //trace(`Save Customer - EmailAddress: ${SessionInfo.logonEmailAddress}, saveEmailAddress: ${this.saveEmailAddress}`);
    let custCtrl = 0;
    //custCtrl += this.saveBit(this.state.accountActive, 1);
    //custCtrl += this.saveBit(this.state.accountHold, 2);
    //custCtrl += this.saveBit(this.state.accountHasCredit, 5);
    //custCtrl += this.saveBit(this.state.customerDirectPay, 6);
    //custCtrl += this.saveBit(this.state.customerWatch, 7);
    await this.getCustomerConfigBitEncoded();
    await this.setState({ CustomerControls: custCtrl });
    trace("CustomerControls: " + this.state.CustomerControls);
    let props = {};
    //trace("Save StoreLocation");
    CustomersO.forEach((elem) => {
      if (elem.n in this.state) { // this.state.hasOwnProperty(elem.n)
        props[elem.n] = this.state[elem.n];
      }
    });
    //-----------
    trace("props: " + JSON.stringify(props));
    //await UpdateRow(1020151, 0, undefined, copyState, this.state.currentCustomerID, "CustomerID"); // Save to OM   
    //await UpdateRow(1020151, 0, undefined, props, this.state.currentCustomerID, "CustomerID"); // Save to OM  
    let CD = await CallOM("UpdateCustomerInfo",0,0,13,props); // Based on Signed on ID
    //await this.getCustomerRecords();
    //await this.getProjectRecords();
    // Address Information
    let propState = {};
    AddressesO.forEach((elem) => {
      if (elem.n in this.state) {
        propState[elem.n] = this.state[elem.n];
      }
    });
    propState['IsPrime'] = true;
    CD = await CallOM("SaveCustomerAddress",0,0,CTYP.DICTNO,propState,0); // Save to OM
    this.setState({ hasChanged: false });
    this.showMessage("Profile Saved");
  }
  deleteRecord = async () => {
    //trace("Delete CustomerProfile: " + this.state.CustomerID);
    let CD = await DeleteRowTry(543619,this.state.currentCustomerID);
    trace("Delete Customer - o: " + CD.x.o);
    this.setState({ hasChanged: false });
  }
  clearRecord = () => {
    trace("Clear Screen - state: " + JSON.stringify(this.state));
    let type = 0;
    //Object.keys(this.state).map
    //  ((prop) => trace("Clear - " + prop + " type: " + type));
    for (var prop in this.state) {
      //trace("Clear - " + prop + " type: " + type);
      if (prop === "selectedTopTab")
        break;
      else if (prop === "string")
        type = 1;
      else if (type === 0) {
        let obj = {};
        obj[prop] = 0;
        this.setState(obj);
      }
      else if (type === 1) {
        let obj = {};
        obj[prop] = '';
        this.setState(obj);
      }
    }
    this.setState({ hasChanged: false });
  }
  //---------------------------------------------------
  showPasswordChange = () => {
    this.setState({ EmailAddress: SessionInfo.Email });
    this.setState({ showChangePWPopup: true });
  }
  showMessage = (msg) => {
    this.setState({ loginMessage: msg });
    //this.setShowMsg(true);
  }
  setMesgColor = (color) => {
    this.setState({ mesgColor: color });
  }
  showWarning = (msg) => {
    this.setMesgColor("orange");
    this.showMessage(`Warn: ${msg}`);
  }
  showError = (msg) => {
    this.setMesgColor("red");
    this.showMessage(`Error: ${msg}`);
  }
  changePassword = async (event) => {
    SessionInfo.Email = this.state.EmailAddress;
    SessionInfo.Password = this.state.password;
    SessionInfo.confirmPassword = this.state.confirmPassword;
    trace(`Email: ${this.state.EmailAddress}, Password: ${this.state.password}, Confirm Password: ${this.state.confirmPassword}`);
    //setShowMsg(false);
    //event.preventDefault();
    if (this.state.password !== this.state.confirmPassword) {
      this.showWarning("Passwords must match");
    }
    else if (this.state.password.length < 8) {
      this.showWarning("Password must be at least 8 characters");
    }
    else {
      trace("----> Call ChangePassword");
      let m = await ChangePassword(this.state.EmailAddress,this.state.password,this.state.confirmPassword,"Session");
      trace("Return from ChangePassword - m: " + m);
      if (m === 'ok' || m === 'Password Changed') {
        this.showMessage(`Password Change Successful`);
        if (SessionInfo.EntCookie === true)
          deleteCookie(`IMSecure${+SessionInfo.AppEntityID.toString()}ID`); // Delete the Menu logon token
        else
          deleteCookie(`IMSecureID`); // Delete the Menu logon token
        this.setMesgColor("black");
        this.setState({ changeDone: true });
        //this.setState({ showChangePWPopup: false });
      }
      else if (m === 'pa') {
        this.showWarning("Passwords must match");
      }
      else if (m)
        this.showError(m);
      else
        this.showWarning("Change Password Error");
    }
    this.setState({ hasChanged: true });
  }
  closeAccountDetail = () => {
    this.setState({ showAccountPopup: false });
  }
  cancelChangePW = () => {
    this.setState({ showChangePWPopup: false });
  }
  updatePassword = (event) => {
    this.setState({ password: event.target.value });
  }
  updateConfirm = (event) => {
    this.setState({ confirmPassword: event.target.value });
  }
  chgShowPW = (event) => {
    if (this.state.showPassword === true)
      this.setState({ showPassword: false });
    else
      this.setState({ showPassword: true });
    //trace(`showPassword: ${showPassword}`);
  }
  //---------------------------------------------------
  //clickMain = async (event) => {
  //  this.setState({ showChangePWPopup: false });
  //  await this.stopCamera();
  //  this.setState({ showTakePicturePopup: false });
  //}
  //--------------- End of Standard Page Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  CommandCell;
  //-------------------- Page Edit ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  //getCustomerRecords = async (search) => { // Get the available Customers
  //  let CD;
  //  if (!search)
  //    CD = await GetTableData(1020151, "CustomerID,FirstName,LastName,PhoneNumber,EmailAddress,ProvinceStateID"); // Get Customers
  //  else
  //    CD = await GetTableSearch(1020151, search); // Get Customers by Search
  //  if (CD.x.o === 0)
  //    LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`, 1);
  //  else if (CD.x.o < 9500) {
  //    try {
  //      const Customers = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
  //      //trace("Customers: " + JSON.stringify(Customers));
  //      this.setState({ Customers });
  //    } catch (error) {
  //      trace(error.message);
  //    }
  //  }
  //}     
  addProfileImage = async (event) => {
    try {
      trace(event);
      const fileObj = event.affectedFiles[0].getRawFile();
      trace(fileObj);
      trace(`ext: ${event.affectedFiles[0].extension}`);
      let imageType = getDocumentType(event.affectedFiles[0].extension,"Profile Image");
      trace(`imageType: ${imageType}`);
      let reader = new FileReader();
      reader.readAsArrayBuffer(fileObj);
      reader.onloadend = async () => {
        let imageFile = await Buffer.from(reader.result);
        trace(`imageFile Lth: ${imageFile.length}`);
        //trace(imageFile);
        let Uint8Ar = new Uint8Array(imageFile)
        trace(`Uint8Ar Lth: ${Uint8Ar.length}`);
        //trace(Uint8Ar);
        this.setState({ PersonPhotoURL: URL.createObjectURL(fileObj) });
        let iVal = await saveImageFileToServer(Uint8Ar,imageType,CustomersOID,SessionInfo.PersonInfo.CustomerID,1040572,2);
        this.setState({ ImageID: iVal });
      }
      event.target.value = null;
    }
    catch (error) {
      trace(`addProfileImage Error: ${error}`);
    }
  };
  getCustomerRecord = async (Key) => {
    //trace("Get Customer Key: " + Key);
    let CD = await CallOM("GetCustomerInfo",3,0); // Based on Signed on ID
    if (CD.x.o > 9990) { // Error Level in OMAPI
      trace(`Call LogOffReset in getCustomerRecord`);
      await LogOffReset(`LogOff Error: ${CD.x.o} operation: ${operation}`,1); // Error Forced Logoff and Relogon
    } else {
      //trace("Get Customer CD: " + JSON.stringify(CD));
      //this.setState({ currentCustomerID: Key });
      //this.setState({ CustomerID: Key });

      // Retrieved Value Assignment for: Customers
      let row = CD.d;
      CustomersO.forEach(elem => {
        if (elem.n in row) {
          //trace(`Set ${elem.n}: ${row[elem.n]}`);
          let sobj = {};
          try {
            if (elem.t === 34 || elem.t === 35) { // Date, DateTime
              sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
              //sobj[elem.n] = row[elem.n];
              CV[elem.n] = new Date(row[elem.n]);
            } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = row[elem.n];
              //trace(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS); // Set the String shadow var
              CV[elem.n] = row[elem.n];
            } else if (elem.t === 22) { // Bool
              let val = row[elem.n].toString().charAt();
              if (val === '1' || val === 't' || val === 'T') {
                sobj[elem.n] = true;
                CV[elem.n] = true;
              } else {
                sobj[elem.n] = false;
                CV[elem.n] = false;
              }
            } else if (elem.t === 23) { // Decimal
              if (elem.n.indexOf('Percent') >= 0 || elem.n.indexOf('Markup') >= 0) {
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
                this.setState(sobjS); // Set the String shadow var
                CV[elem.n] = row[elem.n];
              } else { // Decimal
                sobj[elem.n] = row[elem.n];
                CV[elem.n] = row[elem.n];
              }
            } else if (elem.t === 28) { // Money
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              //trace(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
              this.setState(sobjS); // Set the String shadow var
              CV[elem.n] = row[elem.n];
            } else if (elem.t === 41) { // Media - Image or Document
              sobj[elem.n] = row[elem.n];
              CV[elem.n] = row[elem.n];
              if (elem.n === 'ImageID')
                currentImageID = row[elem.n];
            } else if (elem.t === 15) { // Encoded
              //------ Assign Encoded Values ------
              let AList = [];
              if (elem.n === 'CustomerConfig') {
                // Assignment for CustomerConfig
                let EncodeVal = row[elem.n];
                this.setCustomerConfigBitEncoded(EncodeVal);
                CustomerConfigFixedValues.forEach((ent) => {
                  if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                    AList.push(ent.n);
                });
              }
              if (elem.n === 'CustomerControls') {
                // Assignment for CustomerControls
                let EncodeVal = row[elem.n];
                CustomerControlsFixedValues.forEach((ent) => {
                  if ((EncodeVal & (0x001 << ent.k)) === (0x001 << ent.k))
                    AList.push(ent.n);
                });
              }
              let sobjV = {};
              sobjV[`${elem.n}Vals`] = AList; // Set the Vals Array
              this.setState(sobjV); // Set the Vals var
              sobj[elem.n] = row[elem.n];
              //obj[elem.n] = row[elem.n];
              CV[elem.n] = row[elem.n];
              trace(`Encoded sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${CV[elem.n]}`);
            } else { // Default - Number
              sobj[elem.n] = row[elem.n];
              CV[elem.n] = row[elem.n];
            }
            //trace(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${CV[elem.n]}`);
            if (this.state[elem.n] === undefined)
              trace(`=== ${elem.n} is not defined in state ===`);
            this.setState(sobj);
            // Add item specific code here
            //if (elem.n === 'ImageID')
            //  ImageID = row[elem.n];
          }
          catch (error) {
            trace(`Set Customers Field: ${elem.n}, type: ${elem.t}, Error: ${error.message}`);
          }
        }
      });
      if (SessionInfo.PersonInfo.RoleID === 279)
        SessionInfo.defaultLogon = true;
      else {
        this.setState({ hasChanged: false });
        SessionInfo.FullName = this.state.FirstName + " " + this.state.LastName;
        SessionInfo.headerTitle = SessionInfo.FullName;
        this.saveEmailAddress = this.state.EmailAddress;
        //let actv = this.state.CustomerControls & 0x00000002;
        this.setState({ accountActive: testBit(this.state.CustomerControls,1) });
        this.setState({ accountHold: testBit(this.state.CustomerControls,2) });
        this.setState({ accountHasCredit: testBit(this.state.CustomerControls,5) });
        this.setState({ customerDirectPay: testBit(this.state.CustomerControls,6) });
        this.setState({ customerWatch: testBit(this.state.CustomerControls,7) });
      }
      trace(`this.state.PersonPhotoID: ${CV.PersonPhotoID}`);
      let imageURL = await getImageFileFromServer(CV.PersonPhotoID,4);
      this.setState({ PersonPhotoURL: imageURL });
    }
  }
  // Convert Encoded Bit-Values For CustomerConfig
  // ** Add to saveRecord  await getCustomerConfigBitEncoded()
  getCustomerConfigBitEncoded = async () => {
    let CustomerConfig = 0x0000000000000000;
    if (this.state.CustomerReset === true)
      CustomerConfig |= (0x001 << 1);
    if (this.state.DietVegetarian === true)
      CustomerConfig |= (0x001 << 5);
    if (this.state.DietVegan === true)
      CustomerConfig |= (0x001 << 6);
    if (this.state.DietNutAlergy === true)
      CustomerConfig |= (0x001 << 7);
    if (this.state.DietLactose === true)
      CustomerConfig |= (0x001 << 8);
    if (this.state.DietGluten === true)
      CustomerConfig |= (0x001 << 9);
    if (this.state.DietOther === true)
      CustomerConfig |= (0x001 << 12);
    await this.setState({ CustomerConfig });
  }
  // Set State Variables for Encoded Bit-Values For CustomerConfig
  // ** Add to getRecord  await setCustomerConfigBitEncoded(CustomerConfigVals)
  setCustomerConfigBitEncoded = async (CustomerConfigVals) => {
    if ((CustomerConfigVals & (0x001 << 1)) === (0x001 << 1))
      this.setState({ CustomerReset: true });
    else
      this.setState({ CustomerReset: false });
    if ((CustomerConfigVals & (0x001 << 5)) === (0x001 << 5))
      this.setState({ DietVegetarian: true });
    else
      this.setState({ DietVegetarian: false });
    if ((CustomerConfigVals & (0x001 << 6)) === (0x001 << 6))
      this.setState({ DietVegan: true });
    else
      this.setState({ DietVegan: false });
    if ((CustomerConfigVals & (0x001 << 7)) === (0x001 << 7))
      this.setState({ DietNutAlergy: true });
    else
      this.setState({ DietNutAlergy: false });
    if ((CustomerConfigVals & (0x001 << 8)) === (0x001 << 8))
      this.setState({ DietLactose: true });
    else
      this.setState({ DietLactose: false });
    if ((CustomerConfigVals & (0x001 << 9)) === (0x001 << 9))
      this.setState({ DietGluten: true });
    else
      this.setState({ DietGluten: false });
    if ((CustomerConfigVals & (0x001 << 12)) === (0x001 << 12))
      this.setState({ DietOther: true });
    else
      this.setState({ DietOther: false });
  }
  // Select tabs
  //-------------------- End of Page Edit -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------- Field Edit ------------------------------------------------------------------  
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ hasChanged: true });
    this.setState({ hasChanged: true });
  }
  chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    this.setState({ [event.target.name]: newVal });
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    //trace("percent newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setState({ hasChanged: true });
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setState({ hasChanged: true });
  }
  chgCheckboxVal(event) {
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setState({ hasChanged: true });
  }
  //------------------------- Field Edit ------------------------------------------------------------------ 
  customersPageChange = (event) => {
    this.setState({ customersSkip: event.page.skip,customersTake: event.page.take });
  }
  onLayoutChange = (event) => {
    this.setState({ horizontalPanes: event.newState });
  }
  onVerticalLayoutChange = (event) => {
    //trace("vertical: " + JSON.stringify(updatedState));
    this.setState({ verticalPanes: event.newState });
  }
  onHorizontalLayoutChange = (event) => {
    //trace("horizontal: " + JSON.stringify(updatedState));
    this.setState({ horizontalPanes: event.newState });
  }
  //------------------------- Customer Order List ------------------------------------------------------------------

  moneyFormat = (value) => new Intl.NumberFormat('en-US',{ style: 'currency',currency: 'USD' }).format(value);

  getCustomOrders = async (search) => { // Get Receipts
    if (!search)
      search = '';
    trace("Get CustomOrders - ID: " + this.state.currentCustomerID);
    let CD = await GetTableSearch(545072,search + "*CustomerID=" + this.state.currentCustomerID);
    //trace("CustomOrders: " + JSON.stringify(CD.d));
    try {
      const CustomOrders = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      this.setState({ CustomOrders });
    } catch (error) {
      trace("Failure in getCustomOrders - Error: " + error.message);
    }
  }
  customOrdersPageChange = (event) => {
    this.setState({ customOrdersSkip: event.page.skip,customOrdersTake: event.page.take });
  }

  openMenu = () => {
    //this.setState({ showItemsDetail: false });
    //this.keepMenu = true;
    this.setState({ showMenu: true });
    trace(`open Menu - showMenu: ${this.state.showMenu}`);
  }
  onMenuItemClick = async (event) => {
    trace(`menu event Item: ${event.item.text}`);
    if (SessionInfo.currentItem !== undefined) {
      //trace('Current Item: ' + SessionInfo.currentItem.text);
      SessionInfo.currentItem.selected = false;
    }
    SessionInfo.currentItem = event.item;
    trace('2 Current Item: ' + SessionInfo.currentItem.text);
    event.item.selected = true;
    event.item.expanded = true;
    //trace(`Event - name: ${JSON.stringify(event.item)}`);
    SessionInfo.MenuSelect = event.item;
    this.keepMenu = false;
    this.setState({ showMenu: false });
    if (SessionInfo.menuAction !== undefined) {
      await SessionInfo.menuAction(); // onMenuItemClick in AppMain.js
    }
    this.forceUpdate();
  }
  //------------------------- Get Customer Account ------------------------------------------------------------------
  getCustomerAccount = async (event) => {
    trace(`Get (1) Customer Account: ${this.state.CustomerID}`);
    //let CD = await GetTableRowRaw(545756, this.state.CustomerID);
    let CD = await CallOM("GetCustomerAccount",0,this.state.CustomerID,0);
    // Note - 12Oct22 - Note - with change to CallOM - may have changed from CD.d.row - to just CD.d
    //trace("after Get: " + JSON.stringify(CD));
    for (var prop in CD.d.row) {
      if (prop in this.state) {
        //trace("Set " + prop + ": " + CD.d.row[prop]);
        let obj = {};
        obj[prop] = CD.d.row[prop];
        if (prop.indexOf("LastUsedOn") >= 0) {
          obj[prop] = new Date(CD.d.row[prop]); //new Date()
        }
        this.setState(obj);
      }
    }
    this.setState({ verticalPanes: this.vertSplitC });
  }
  onGiveCreditAmount = async () => {
    if (this.state.CreditAmount === 0)
      displayWarning("A Credit Amount must be provided.")
    else {
      displayMessage("Give Credit Amount (" + this.state.CreditAmount + ") to " + this.state.FirstName + " : " + this.state.LastName);
      let CD = await CallOM("GiveCreditAmount",0,this.state.CustomerID,3,this.state.CreditAmount);
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        try {
          displayMessage(CD.m);
          trace("Amount : " + JSON.stringify(CD));
        } catch (error) {
          trace(error.message);
        }
        await this.getCustomerAccount();
      }
    }
  }
  onUseCreditAmount = async () => {
    let Amount = 0;
    if (this.state.CreditAmount === 0)
      displayWarning("An Amount must be provided.")
    else {
      displayMessage("Decrease Credit Amount (" + this.state.CreditAmount + ") to " + this.state.FirstName + " : " + this.state.LastName);
      let CD = await CallOM("UseCreditAmount",0,this.state.CustomerID,3,this.state.CreditAmount);
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        try {
          displayMessage(CD.m);
          trace("Amount : " + JSON.stringify(CD));
          Amount = CD.d
        } catch (error) {
          trace(error.message);
        }
        await this.getCustomerAccount();
      }
    }
  }
  showAccountInfo = async () => {                       
    trace(`Get (2) Customer Account: ${this.state.CustomerID}`);
    if (!this.state.CustomerID)
      this.setState({ CustomerID:SessionInfo.PersonInfo.CustomerID });
    let CD = await CallOM("GetCustomerAccount",0,SessionInfo.PersonInfo.CustomerID,0);
    // Retrieved Value Assignment for: CustomerAccounts  
    //trace("CustomerAccount : " + JSON.stringify(CD));
    let row = CD.d;
    CustomerAccountsO.forEach(elem => {
      if (elem.n in row) {
        //trace(`Set ${elem.n}: ${row[elem.n]}`);
        let sobj = {};
        try {
          if (elem.t === 34 || elem.t === 35) { // Date, DateTime
            sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
            //sobj[elem.n] = row[elem.n];
            CAV[elem.n] = new Date(row[elem.n]);
          } else if (elem.t === 21 || elem.t === 195 || elem.t === 201) { // String, FK-Translated, Enum
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = row[elem.n];
            //trace(`Set String Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            CAV[elem.n] = row[elem.n];
          } else if (elem.t === 22) { // Bool
            let val = row[elem.n].toString().charAt();
            if (val === '1' || val === 't' || val === 'T') {
              sobj[elem.n] = true;
              CAV[elem.n] = true;
            } else {
              sobj[elem.n] = false;
              CAV[elem.n] = false;
            }
          } else if (elem.t === 23) { // Decimal
            if (elem.n.indexOf('Percent') >= 0 || elem.n.indexOf('Markup') >= 0) {
              sobj[elem.n] = row[elem.n];
              let sobjS = {};
              sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
              this.setState(sobjS); // Set the String shadow var
              CAV[elem.n] = row[elem.n];
            } else { // Decimal
              sobj[elem.n] = row[elem.n];
              CAV[elem.n] = row[elem.n];
            }
          } else if (elem.t === 28) { // Money
            sobj[elem.n] = row[elem.n];
            let sobjS = {};
            sobjS[elem.n + 'S'] = this.moneyFormat(row[elem.n]);
            //trace(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
            this.setState(sobjS); // Set the String shadow var
            CAV[elem.n] = row[elem.n];
          } else if (elem.t === 41) { // Media - Image or Document
            sobj[elem.n] = row[elem.n];
            CAV[elem.n] = row[elem.n];
            if (elem.n === 'ImageID')
              currentImageID = row[elem.n];
          } else { // Default - Number
            sobj[elem.n] = row[elem.n];
            CAV[elem.n] = row[elem.n];
          }
          //trace(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${CAV[elem.n]}`);
          if (this.state[elem.n] === undefined)
            trace(`=== ${elem.n} is not defined in state ===`);
          this.setState(sobj);
          // Add item specific code here
          //if (elem.n === 'ImageID')
          //  ImageID = row[elem.n];
        }
        catch (error) {
          trace(`Set CustomerAccountsO Field: ${elem.n}, Error: ${error.message}`);
        }
      }
    });
    this.setState({ showAccountPopup: true });
  }
  //------------------------- Render ------------------------------------------------------------------
  render() {
    return (
      <div id="CustomerProfile" className="pageMainFx">
        <div id="hdr" className="headerShort">
          <div className="headerLogoLeft">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />
          </div>
          <div className="headerCenter">
            <div className="headerCenterMenu">
              <MenuBar alt="" fill={SessionInfo.headingTextColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} />
            </div>
            <div className="headerCenterTop">
              <span className='landingHeadTextLarge' >&nbsp;&nbsp;&nbsp;My Account</span>
              <br />
            </div>
          </div>
        </div>
        {/* Menu Popup */}
        <Popup anchor={this.menuAnchor.current} show={this.state.showMenu} popupClass={'popupMenu'} >
          <div>
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
            <div className="menuTree">
              {SessionInfo.HasScanAndPay === false ?
                <br /> : null}
              {/*{SessionInfo.defaultLogon === true ?*/}
              {/*  <br /> : null}*/}
              <TreeView
                data={SessionInfo.MenuControlNQR}
                size='large' draggable='false' selection='single'
                onItemClick={this.onMenuItemClick}
                className='menuTreeSub'
                item={props => [<span key={props.item.key} className={props.item.className}>{props.item.icon}{props.item.space}{props.item.text}</span>]}
              />
            </div>
          </div>
        </Popup>
        {/* Message Notify Popup */}
        <Popup offset={SessionInfo.popupNotifyOffset} show={this.state.showNotify} popupClass={'popup-content'} >
          <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }} onClick={(e) => { this.acknowledgeMessage(); }} />
          {SessionInfo.acknowledge ?
            <div className="bottomButton">
              <Button icon="tell-a-friend" className="medButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
            </div> : null}
        </Popup>

        <div id="div1" className="profileEdit"> {/*onClick={evt => this.clickMain(evt)}>*/}
          {/*<h2>&nbsp; &nbsp;Contact Information</h2>*/}
          <br />
          <div id="div2" className="editField">
            <span className="editFieldLabel">First Name</span>
            <input value={this.state.FirstName} name="FirstName" onChange={evt => this.chgFldVal(evt)} className="editInputS" />
          </div>
          <div id="div2" className="editField">
            <span className="editFieldLabel">Last Name</span>
            <input value={this.state.LastName} name="LastName" onChange={evt => this.chgFldVal(evt)} className="editInputS" />
          </div>
          <div id="div2" className="editField">
            <span className="editFieldLabel">Email Address</span>
            <input value={this.state.EmailAddress} name="EmailAddress" onChange={evt => this.chgFldVal(evt)} className="editInputL" />
          </div>
          <div id="div2" className="editField">
            <span className="editFieldLabel">Address</span>
            <input value={this.state.Address} name="Address" onChange={evt => this.chgFldVal(evt)} className="editInputL" />
          </div>
          <div id="div2" className="editField">
            <span className="editFieldLabel">City</span>
            <input value={this.state.City} name="City" onChange={evt => this.chgFldVal(evt)} className="editInputM" />
          </div>
          <div id='div2' className='editField'>
            <span className='editFieldLabel'>State/Province</span>
            <div className='editDDPos'>
              <DropDownList data={this.state.ProvinceStateCodesDict} textField='ddName' dataItemKey='ddID' value={this.state.ProvinceStateCodesDict.find(c => c.ddID === this.state.ProvinceStateID)} name='ProvinceStateID' onChange={evt => this.chgDDFldVal(evt)} className='editInputDD' />
            </div>
          </div>
          <div id="div2" className="editField">
            <span className="editFieldLabel">Postal Code</span>
            <input value={this.state.PostalCode} name="PostalCode" onChange={evt => this.chgFldVal(evt)} className="editInputS" />
          </div>
          <div id="div2" className="editField">
            <span className="editFieldLabel">Phone Number</span>
            <input value={this.state.PhoneNumber} name="PhoneNumber" onChange={evt => this.chgFldVal(evt)} className="editInputS" />
          </div>
          <br />
          <div className="editField">
            {this.state.hasChanged ? 
              <Button id="saveButton" icon="save" look="outline" className="medButton" style={{ left: "2px" }} onClick={this.saveRecord} >&nbsp;Save Profile &nbsp;</Button> : null}
            <span>&nbsp; &nbsp; &nbsp; </span>
            {SessionInfo.AppCfg.HasAccountInfo === true ?
              <Button id="showAccount" icon="layout" look="outline" className="medButton" style={{ right: "2px" }} onClick={this.showAccountInfo} >&nbsp;View Account &nbsp;</Button> : null}
          </div>
          <h2>&nbsp; &nbsp; &nbsp; Profile Image</h2>
          <br />
          <div className="framePic">
            <div className="frameProfile">
              <Avatar rounded='large' size='large' type='image' style={{ height: '200px',width: '200px' }}>
                <img src={this.state.PersonPhotoURL} alt='' style={{ height: '200px',width: '200px',}} title={`${this.state.FirstName}`} />
              </Avatar>
            </div>
            <div className="framePicUpload">
              <div style={{ position: "relative",top: "-140px" }}>
                <Upload
                  ref={uploadRef}
                  batch={false}
                  multiple={true}
                  files={this.state.files}
                  onAdd={this.addProfileImage}
                  onRemove={this.onRemove}
                  onStatusChange={this.onStatusChange}
                  saveUrl={null}
                  defaultFiles={[]}
                  withCredentials={false}
                />            
              <Button id="takePicture" icon="photo-camera" look="outline" className="medButton" style={{ left: "30px" }} onClick={this.takePicture} >&nbsp;Take Photo &nbsp;</Button>
              </div> <span>&nbsp; &nbsp; &nbsp;</span>
            </div>
          </div>
          <Button id="changePWButton" icon="lock" look="outline" className="medButton" style={{ left: "20px" }} onClick={this.showPasswordChange} >&nbsp;Change Password &nbsp;</Button>
          <h2> &nbsp; &nbsp;Diet Preferences </h2>
          <div id="div2" className="editField">
            <Switch checked={this.state.DietVegetarian} name="DietVegetarian" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Vegetarian</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.DietVegan} name="DietVegan" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Vegan</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.DietNutAlergy} name="DietNutAlergy" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Nut Alergies</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.DietLactose} name="DietLactose" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Lactose Intolerance</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.DietGluten} name="DietGluten" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Gluten Free</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.DietOther} name="DietOther" onChange={evt => this.chgCheckboxVal(evt)} /><span className="editFieldLabel"> &nbsp; Diet Other</span>
          </div>
          <br />
          <h2>&nbsp;&nbsp;Settings</h2>
          <div id="div2" className="editField">
            <Switch checked={this.state.EmailReceipts} name="EmailReceipts" onChange={evt => this.chgCheckboxVal(evt)} />
            <span className="editFieldLabel"> &nbsp; Email Receipts</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.EmailPromotions} name="EmailPromotions" onChange={evt => this.chgCheckboxVal(evt)} />
            <span className="editFieldLabel"> &nbsp; Email Promotions</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.SMSReminders} name="SMSReminders" onChange={evt => this.chgCheckboxVal(evt)} />
            <span className="editFieldLabel"> &nbsp; SMS Reminders</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.SoundOn} name="SoundOn" onChange={evt => this.chgCheckboxVal(evt)} />
            <span className="editFieldLabel"> &nbsp; Sound On</span>
          </div>
          <div id="div2" className="editField">
            <Switch checked={this.state.AutoScanLocation} name="AutoScanLocation" onChange={evt => this.chgCheckboxVal(evt)} />
            <span className="editFieldLabel"> &nbsp; AutoScan Location</span>
          </div>       
          <br/>
          <div className="bottomButton"> 
          </div>
        </div>
        {/* Change Password Popup */}
        <Popup offset={this.popupPictureOffset} show={this.state.showTakePicturePopup} popupClass={'popupTakePicture'} >
          <div className="topRightCorner">
            <Button id="doneButton" icon="close-outline" className="smallButton" onClick={this.stopCamera}></Button>
          </div>
          {playerORImage}
          <div className="bottomButtonW">
            <Button id="takePhoto" icon="camera" look="outline" className="smallButton" style={{ left: "2px" }} onClick={this.capturePicture} >Take Photo&nbsp;</Button>
            <Button id="switchCamera" icon="lock" look="outline" className="smallButton" style={{ left: "24px" }} onClick={this.switchCamera} >Switch Camera</Button>
          </div>
        </Popup >
        {/* Change Password Popup */}
        <Popup offset={this.popupOffset} show={this.state.showChangePWPopup} popupClass={'popupChangePW'} >
          <div id="Login" className="pageLoginBorder">
            <div id="div1" className="insideFrame">
              {/*<a id="headerLogo" href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />*/}
              <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />}
              <div className="loginPageInside">
                <label className="loginHeadingS">&nbsp; Interact Menu Password Change</label>
                <br />
                <br />
                <label className="loginLabel" style={{ color: this.state.mesgColor }}>{this.state.loginMessage}</label>
                <br />
                <div id="div2" className="editField">
                  <FloatingLabel label="Email Address (User Name) *" style={{ width: '300',color: 'darkblue' }} editorId={'EmailAddress'} editorValue={this.state.EmailAddress}>
                    <Input id={'EmailAddress'} value={this.state.EmailAddress} style={{ width: 210 }} readOnly={true} />    {/*onChange={evt => this.updateEmailAddress(evt)} */}
                  </FloatingLabel>
                </div>
                <br />
                <div id="div2" className="editField">
                  <FloatingLabel label="Password *" style={{ width: '300',color: 'darkblue' }} editorId={'password'} editorValue={this.state.password}>
                    {this.state.showPassword === false ?
                      <Input id={'password'} value={this.state.password} style={{ width: 210 }} type="password" autoComplete="false" onChange={evt => this.updatePassword(evt)} />
                      :
                      <Input id={'password'} value={this.state.password} style={{ width: 210 }} autoComplete="false" onChange={evt => this.updatePassword(evt)} />}
                  </FloatingLabel>
                </div>
                <br />
                <div id="div2" className="pwField">
                  <input type="checkbox" checked={this.state.showPassword} name="ShowPassword" onChange={this.chgShowPW} />
                  <span className="editFieldLabel">&nbsp; Show Password</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                </div>
                Your Password must be 8 or more characters    With at least one Capital, one Number and one Symbol
                <br />
                <div id="div2" className="editField">
                  <FloatingLabel label="Confirm Password" style={{ width: '300',color: 'darkblue' }} editorId={'confirmPassword'} editorValue={this.state.confirmPassword}>
                    {this.state.showPassword === false ?
                      <Input id={'confirmPassword'} value={this.state.confirmPassword} style={{ width: 210 }} type="password" autoComplete="false" onChange={evt => this.updateConfirm(evt)} />
                      :
                      <Input id={'confirmPassword'} value={this.state.confirmPassword} style={{ width: 210 }} autoComplete="false" onChange={evt => this.updateConfirm(evt)} />}
                  </FloatingLabel>
                </div>
                <br />
                <br />
                <br />
                {this.state.changeDone === false ?
                  <>
                    &nbsp;<Button id="changePWButton" icon="lock" look="outline" className="smallButton" onClick={this.changePassword} >Change Password&nbsp;</Button>&nbsp; &nbsp; &nbsp;&nbsp;
                    <Button id="cancelButton" icon="close-circle" className="smallButton" onClick={this.cancelChangePW} >Cancel</Button>
                  </> :
                  <>
                    &nbsp; &nbsp; <Button id="cancelButton" icon="close-circle" className="smallButton" onClick={this.cancelChangePW} >&nbsp;Exit &nbsp;</Button>
                  </>}
              </div>
            </div>
          </div>
        </Popup>
        <Popup offset={this.popupAccountOffset} show={this.state.showAccountPopup} popupClass={'popupAccountDetail'} >
          {/*<div className="editTab">*/}
          <div className="editTabLeftNarrowFixed">
            <div id="div1" className="insideFrame">
              <h2>Account Details</h2>
              <br />
              <div id="div2" className="editField">
                <span className="editFieldLabel">Current Balance</span>
                <input value={this.moneyFormat(this.state.Amount)} name="Amount" onChange={evt => this.chgMoneyFldVal(evt)} readOnly={true} className="editMoneyInput" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Transactions</span>
                <input value={this.state.TransactionCount} name="TransactionCount" onChange={evt => this.chgFldVal(evt)} readOnly={true} className="editNumShortInput" />
              </div>
              <div id="div2" className="editField">
                <span className="editFieldLabel">Last Used On</span>
                <input value={this.state.LastUsedOn < new Date(2000,1,1) ? "" : new Intl.DateTimeFormat("en-CA",{ dateStyle: "long",timeStyle: "short" }).format(this.state.LastUsedOn)} name="LastUsedOn" onChange={evt => this.chgFldVal(evt)} className="editInputFM" readOnly="{true}" />
              </div>
              <div className="editField">
                <span className="editFieldLabel">Account Status</span>
                <input value={this.state.CustomerAccountStatus} name="City" onChange={evt => this.chgFldVal(evt)} readOnly={true} className="editInputM" />
              </div>
            </div>
          </div>    
          <div className="bottomButton">
            <Button id="cancelButton" icon="cancel" look="outline" className="smallButton" onClick={this.closeAccountDetail} >Exit Account</Button>
          </div>
        </Popup>
      </div>
    );
  }
}

export default withRouter(ProfilePage);