//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// Receipts Page - List of Receipts for the Guest
//              Version 1.001 - June 8, 2022
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React, { Component } from 'react';
import { withRouter } from './withRouter';
import { Avatar } from '@progress/kendo-react-layout';
import { Rating } from "@progress/kendo-react-inputs";
import { Popup } from '@progress/kendo-react-popup';
import { TreeView } from '@progress/kendo-react-treeview';
import { Button } from '@progress/kendo-react-buttons';
import { Input } from '@progress/kendo-react-inputs';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { Tooltip } from '@progress/kendo-react-tooltip';
//import { orderBy, filterBy } from '@progress/kendo-data-query';
import { ListView, ListViewHeader, ListViewFooter } from '@progress/kendo-react-listview';
import { SessionInfo } from './App';
import { CallOM,CallOMT,GetTableSearchRaw,displayMessage,LogOffResetMsg,trace,CTYP,TYP,NOTIFY,SetNotify } from './CommonCode.js';

import { ReactComponent as MenuBar } from "./images/bars.svg";
import { ReactComponent as Favorite } from "./images/star.svg";
import { ReactComponent as FavoriteSelect } from "./images/menu-favorite.svg";
import { ReactComponent as ButtonSearch } from "./images/search.svg";
import { ReactComponent as ButtonClear } from "./images/button-clear.svg";

//------ Object Definition For StoreItems -- Date: 2024-04-04-1:59 PM ------
const StoreItemsOID = 544619;
const StoreItemsO = [{ n: 'StoreItemID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'ReferenceItemID',t: 195,p: 100 },{ n: 'AssociatedItemID',t: 195,p: 100 },{ n: 'ItemName',t: 21,p: 0 },{ n: 'ItemDescription',t: 21,p: 0 },{ n: 'ItemFullDescription',t: 29,p: 0 },{ n: 'ItemNote',t: 21,p: 0 },{ n: 'ProductItemCategoryID',t: 195,p: 100 },{ n: 'ProductItemGroupID',t: 195,p: 100 },{ n: 'ProductItemTypeID',t: 195,p: 100 },{ n: 'BrandID',t: 195,p: 100 },{ n: 'UPCNumber',t: 21,p: 0 },{ n: 'BarcodeNumber',t: 21,p: 0 },{ n: 'ItemStatusID',t: 195,p: 100 },{ n: 'PackagingTypeID',t: 195,p: 100 },{ n: 'ItemSizeCodeID',t: 195,p: 100 },{ n: 'TotalQuantity',t: 16,p: 0 },{ n: 'TotalNetWeight',t: 16,p: 0 },{ n: 'ReservedQuantity',t: 16,p: 0 },{ n: 'LockedQuantity',t: 16,p: 0 },{ n: 'AvailableQuantity',t: 16,p: 0 },{ n: 'DocumentID',t: 41,p: 0 },{ n: 'ImageID',t: 41,p: 0 },{ n: 'ItemPrice',t: 28,p: 0 },{ n: 'OverridePrice',t: 28,p: 0 },{ n: 'PromotionPrice',t: 28,p: 0 },{ n: 'Promotion2Price',t: 28,p: 0 },{ n: 'PromotionType',t: 201,p: 0 },{ n: 'StorePromotionID',t: 195,p: 100 },{ n: 'ShelfLifeDays',t: 24,p: 0 },{ n: 'ItemNetWeight',t: 16,p: 0 },{ n: 'PricingTypeID',t: 195,p: 100 },{ n: 'TaxableTypeID',t: 195,p: 100 },{ n: 'RateTypeID',t: 195,p: 100 },{ n: 'SeasonCodeID',t: 195,p: 100 },{ n: 'DayOfWeek',t: 201,p: 0 },{ n: 'DaysOfWeek',t: 15,p: 0 },{ n: 'TimeOfDayID',t: 195,p: 100 },{ n: 'WeightUnitsCodeID',t: 195,p: 100 },{ n: 'InventoryControlTypeID',t: 201,p: 0 },{ n: 'StorageControlID',t: 195,p: 100 },{ n: 'ItemControl',t: 15,p: 0 },{ n: 'IngredientsText',t: 21,p: 0 },{ n: 'UsageInstructions',t: 21,p: 0 },{ n: 'Calories',t: 16,p: 0 },{ n: 'StoreInventoryLocationID',t: 195,p: 100 },{ n: 'TotalReceivedQuantity',t: 16,p: 0 },{ n: 'TotalSoldQuantity',t: 16,p: 0 },{ n: 'TotalDisposedQuantity',t: 16,p: 0 },{ n: 'TotalLostQuantity',t: 16,p: 0 },{ n: 'AssociatedItemCount',t: 24,p: 0 },{ n: 'IsRecordActive',t: 22,p: 0 }];
//       Object Value Definition For StoreItems
const SIV = { 'StoreItemID': 0,'StoreLocationID': 0,'ReferenceItemID': 0,'AssociatedItemID': 0,'ItemName': '','ItemDescription': '','ItemFullDescription': '','ItemNote': '','ProductItemCategoryID': 0,'ProductItemGroupID': 0,'ProductItemTypeID': 0,'BrandID': 0,'UPCNumber': '','BarcodeNumber': '','ItemStatusID': 0,'PackagingTypeID': 0,'ItemSizeCodeID': 0,'TotalQuantity': 0,'TotalNetWeight': 0,'ReservedQuantity': 0,'LockedQuantity': 0,'AvailableQuantity': 0,'DocumentID': '','ImageID': '','ItemPrice': 0,'OverridePrice': 0,'PromotionPrice': 0,'Promotion2Price': 0,'PromotionType': 0,'StorePromotionID': 0,'ShelfLifeDays': 0,'ItemNetWeight': 0,'PricingTypeID': 0,'TaxableTypeID': 0,'RateTypeID': 0,'SeasonCodeID': 0,'DayOfWeek': 0,'DaysOfWeek': 0,'TimeOfDayID': 0,'WeightUnitsCodeID': 0,'InventoryControlTypeID': 0,'StorageControlID': 0,'ItemControl': 0,'IngredientsText': '','UsageInstructions': '','Calories': 0,'StoreInventoryLocationID': 0,'TotalReceivedQuantity': 0,'TotalSoldQuantity': 0,'TotalDisposedQuantity': 0,'TotalLostQuantity': 0,'AssociatedItemCount': 0,'IsRecordActive': false };
const baseDate = new Date('1/1/1900');
const moneyFormat = (value) => new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
//percentFormat = this.frmt === true ? (value) => new Intl.NumberFormat('en-US', { style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value) : (value) => value;
//const dateFormat = (value) => { Date.parse(value) > baseDate ? new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(Date.parse(value)) : '  ' };
const dateFormat = (value) => new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(Date.parse(value));
//const dateFormat = (value) => new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(new Date);
const dateTimeFormat = (value) => new Intl.DateTimeFormat('en-CA', { dateStyle: 'long', timeStyle: 'short' }).format(Date.parse(value))
const dateTimeSFormat = (value) => new Intl.DateTimeFormat('en-CA',{ month: 'short',day: 'numeric',hour: 'numeric',minute: '2-digit' }).format(Date.parse(value))
//------ Object Definition For CustomOrders -- Date: 2024-05-23-9:31 AM ------
const CustomOrdersOID = 545072;
const CustomOrdersO = [{ n: 'CustomOrderID',t: 195,p: 100 },{ n: 'StoreLocationID',t: 195,p: 100 },{ n: 'CustomerID',t: 195,p: 100 },{ n: 'CustomOrderParentID',t: 195,p: 100 },{ n: 'CustomOrderStatus',t: 201,p: 0 },{ n: 'CustomOrderType',t: 201,p: 0 },{ n: 'RequestDateTime',t: 35,p: 0 },{ n: 'ScheduledPrepDateTime',t: 35,p: 0 },{ n: 'PickupDeliveryDateTime',t: 35,p: 0 },{ n: 'TotalPrice',t: 28,p: 0 },{ n: 'AmountOwing',t: 28,p: 0 },{ n: 'TotalTaxAmount',t: 28,p: 0 },{ n: 'TotalTax2Amount',t: 28,p: 0 },{ n: 'TotalTax3Amount',t: 28,p: 0 },{ n: 'AmountPaid',t: 28,p: 0 },{ n: 'DiscountAmount',t: 28,p: 0 },{ n: 'CreditAmountUsed',t: 28,p: 0 },{ n: 'RefundAmount',t: 16,p: 0 },{ n: 'TipAmount',t: 28,p: 0 },{ n: 'AmountOwingWithTip',t: 28,p: 0 },{ n: 'AddressID',t: 195,p: 100 },{ n: 'PhoneNumber',t: 21,p: 0 },{ n: 'ItemCount',t: 16,p: 0 },{ n: 'OptionCount',t: 24,p: 0 },{ n: 'OptionsPrice',t: 28,p: 0 },{ n: 'TotalQuantity',t: 16,p: 0 },{ n: 'OrderCreationDate',t: 35,p: 0 },{ n: 'OrderPaidDate',t: 35,p: 0 },{ n: 'Rating',t: 16,p: 0 },{ n: 'PaidComment',t: 21,p: 0 },{ n: 'CustomOrderPaymentID',t: 195,p: 100 },{ n: 'OrderNotes',t: 21,p: 0 },{ n: 'RefundTaxAmount',t: 28,p: 0 },{ n: 'PaymentAuthCode',t: 21,p: 0 },{ n: 'TransactionID',t: 24,p: 0 },{ n: 'OrderNumber',t: 21,p: 0 },{ n: 'OrderSourceID',t: 195,p: 100 },{ n: 'POSCheckNumber',t: 21,p: 0 },{ n: 'ThirdPartyOrderNumber',t: 21,p: 0 },{ n: 'VerifiedDate',t: 35,p: 0 },{ n: 'VerifiedBy',t: 24,p: 0 },{ n: 'OrderPreparationTypeID',t: 195,p: 100 },{ n: 'ReturnCycle',t: 24,p: 0 },{ n: 'CompletedReturnCycle',t: 24,p: 0 },{ n: 'SublocationID',t: 195,p: 100 },{ n: 'SeatNumber',t: 24,p: 0 },{ n: 'SeatName',t: 21,p: 0 },{ n: 'IndividualItems',t: 22,p: 0 },{ n: 'RecordProcessed',t: 22,p: 0 },{ n: 'ActiveCounted',t: 22,p: 0 },{ n: 'PaymentInProcess',t: 22,p: 0 },{ n: 'PaymentComplete',t: 22,p: 0 }];
//       Object Value Definition For CustomOrders
const COV = { 'CustomOrderID': 0,'StoreLocationID': 0,'CustomerID': 0,'CustomOrderParentID': 0,'CustomOrderStatus': 0,'CustomOrderType': 0,'RequestDateTime': new Date(),'ScheduledPrepDateTime': new Date(),'PickupDeliveryDateTime': new Date(),'TotalPrice': 0,'AmountOwing': 0,'TotalTaxAmount': 0,'TotalTax2Amount': 0,'TotalTax3Amount': 0,'AmountPaid': 0,'DiscountAmount': 0,'CreditAmountUsed': 0,'RefundAmount': 0,'TipAmount': 0,'AmountOwingWithTip': 0,'AddressID': 0,'PhoneNumber': '','ItemCount': 0,'OptionCount': 0,'OptionsPrice': 0,'TotalQuantity': 0,'OrderCreationDate': new Date(),'OrderPaidDate': new Date(),'Rating': 0,'PaidComment': '','CustomOrderPaymentID': 0,'OrderNotes': '','RefundTaxAmount': 0,'PaymentAuthCode': '','TransactionID': 0,'OrderNumber': '','OrderSourceID': 0,'POSCheckNumber': '','ThirdPartyOrderNumber': '','VerifiedDate': new Date(),'VerifiedBy': 0,'OrderPreparationTypeID': 0,'ReturnCycle': 0,'CompletedReturnCycle': 0,'SublocationID': 0,'SeatNumber': 0,'SeatName': '','IndividualItems': false,'RecordProcessed': false,'ActiveCounted': false,'PaymentInProcess': false,'PaymentComplete': false };
//       Object Index Definition For CustomOrders
const COX = { 'CustomOrderID': 0,'StoreLocationID': 1,'CustomerID': 2,'CustomOrderParentID': 3,'CustomOrderStatus': 4,'CustomOrderType': 5,'RequestDateTime': 6,'ScheduledPrepDateTime': 7,'PickupDeliveryDateTime': 8,'TotalPrice': 9,'AmountOwing': 10,'TotalTaxAmount': 11,'TotalTax2Amount': 12,'TotalTax3Amount': 13,'AmountPaid': 14,'DiscountAmount': 15,'CreditAmountUsed': 16,'RefundAmount': 17,'TipAmount': 18,'AmountOwingWithTip': 19,'AddressID': 20,'PhoneNumber': 21,'ItemCount': 22,'OptionCount': 23,'OptionsPrice': 24,'TotalQuantity': 25,'OrderCreationDate': 26,'OrderPaidDate': 27,'Rating': 28,'PaidComment': 29,'CustomOrderPaymentID': 30,'OrderNotes': 31,'RefundTaxAmount': 32,'PaymentAuthCode': 33,'TransactionID': 34,'OrderNumber': 35,'OrderSourceID': 36,'POSCheckNumber': 37,'ThirdPartyOrderNumber': 38,'VerifiedDate': 39,'VerifiedBy': 40,'OrderPreparationTypeID': 41,'ReturnCycle': 42,'CompletedReturnCycle': 43,'SublocationID': 44,'SeatNumber': 45,'SeatName': 46,'IndividualItems': 47,'RecordProcessed': 48,'ActiveCounted': 49,'PaymentInProcess': 50,'PaymentComplete': 51 };

class ReceiptsPage extends Component {
  //------------------------------------------------------------------------------------------------------
  constructor(props) {
    super(props);
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
    //trace("StoreLocationDetail Constructor");
    if (SessionInfo.ReceiptsPage) { // Data saved for this page and session
      trace("Restore ReceiptsPage state");
      this.state = SessionInfo.ReceiptsPage;
    }
    SessionInfo.ReceiptsPageRef = this;
  }
  menuAnchor = React.createRef();
  detailOptionAnchor = React.createRef();
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  state = {
    StoreLocationID: 0,
    CompanyID: 0,
    LocationID: 0,
    CurrentlyOpen: 0,
    AreaSize: 0,
    ContactID: 0,
    StoreGroupID: 0,
    TaxRate: 0,
    TaxRate2: 0,
    TaxRate3: 0,
    TaxRuleID: 0,
    TipRate1: 0,
    TipRate2: 0,
    TipRate3: 0,
    StoreAmenities: 0,
    MaximumPurchaseAmount: 0,
    MaximumPurchaseAmountS: '',
    StandardMarkupPercent: 0,
    ExtendedMarkupPercent: 0,
    CurrentInStoreWaitTime: 1,
    CurrentPickupWaitTime: 1,
    CurrentDeliveryWaitTime: 1,
    LocationTypeID: 0,

    LocationTypeID: 0,
    ProvinceStateID: 0,
    CountryID: 0,
    //-------------------------------- 
    StoreItemID: 0,
    StorePromotionID: 0,
    DiscountPercentage: 0,
    ProductItemCategoryID: 0,
    ProductItemGroupID: 0,
    ProductItemTypeID: 0,
    ShelfLifeDays: 0,
    ItemPrice: 0,
    ItemStatusID: 0,
    PackagingTypeID: 0,
    PricingTypeID: 0,
    RateTypeID: 0,
    PromoStoreItemID: 0,
    PromoItemCategoryID: 0,
    PromotionType: 0,
    //--------------------------------
    CustomOrderID: 0,
    TotalQuantity: 0,
    TotalNetWeight: 0,
    TotalPrice: 0,
    AmountOwing: 0,
    AmountPaid: 0,
    TotalAmount: 0,
    AmountOwingWithTip: 0,
    AmountOwingWithTipS: '',
    DiscountAmount: 0,
    DiscountAmountS: '',
    CreditAmountUsed: 0,
    CreditAmountUsedS: '',
    RefundAmount: 0,
    RefundAmountS: '',
    TipAmount: 0,
    TipAmountS: '',
    TotalTaxAmount: 0,
    TotalTaxAmountS: '',
    TotalTax2Amount: 0,
    TotalTax2AmountS: '',
    TotalTax3Amount: 0,
    TotalTax3AmountS: '',
    TotalDiscountAmount: 0,
    TotalDiscountAmountS: '',
    Price: 0,
    PriceS: '',
    PromotionPrice: 0,
    PromotionPriceS: '',
    TotalCost: 0,
    TotalCostS: '',
    TotalTime: 0,
    StartDateTime: 0,
    EndDateTime: 0,
    OrderCreationDate: 0,
    OrderPaidDate: 0,
    Rating: 0,
    PaidComment: '',
    PaymentMethodID: 0,
    IndividualItems: 0,
    PaymentVerifiedID: 0,
    DaysForDiscount: 0,
    PromotionIsActive: 0,
    ImageID: 0,
    //------ End of Field Values --------------------------------------------------------------------------------------------------------------------------
    string: '',
    ReceiptCount: 0,
    TotalReceiptsPrice: 0,
    itemIsFavorite: false,

    showMenu: false,
    // Promotion presentation
    showItemsDetail: false,
    PromotionPhotoURL: undefined,
    CustomOrderItems: [],

    favoriteReceipts: [], // CustomOrderID
    isViewAll: true,
    TableName: '',
    SeatName: '',
    SeatCount: 0,
    ItemCount: 0,

    StoreName: '',
    Description: '',
    SpecialtyList: '',
    MarketingMessage: '',
    PromotionalMessage: '',
    StoreImage: '',

    LocationName: '',
    UnitNumber: '',
    Address: '',
    CityName: '',
    PostalCode: '',
    PhoneNumber: '',
    EmailAddress: '',
    GeoLocation: '',

    FirstName: '',
    AdditionalName: '',
    LastName: '',
    AlternateName: '',
    Organization: '',
    BusinessPhone: '',
    ContractAddress: '',
    //--------------------------------  
    CustomerID: '',
    CustomOrderStatus: '',
    CustomOrderType: '',

    ItemName: '',
    ItemDescription: '',
    UPCNumber: '',
    BarcodeNumber: '',
    Comments: '',
    //--------------------------------
    PaidComment: '',
    OrderNotes: '',
    StorePromotionName: '',
    PromotionDescription: '',
    //--------------------------------

    selectedTopTab: 0,
    selectedSubTab: 0,
    //----------------------------------------
    searchValue: '',
    lastSearchValue: '',
    clearSetAll: false,
    //--------------------------------
    stateHasChanged: false,
    ScheduledPrepDateTime: undefined,
    SubTotalPrice: 0,
    StoreLocationPhotoURL: undefined,

    CustomerName: "",
    isMainSearch: true,
    CurrentStoreLocationID: 0,


    CustomOrders: [],
    saveCustomOrders: [],


    //--------------------------------
    ProductItemCategories: [],
    ProductItemGroups: [],
    ProductItemTypes: [],
    ItemStatusSet: [],
    PromotionTypes: [],
    PackagingTypes: [],
    PricingTypes: [],
    RateTypes: [],
    PaymentMethods: [],
    //------ Sublocations Data, Save, Page and Filter ------
    Sublocations: [],
    SublocationsSkip: 0,
    SublocationsTake: 8,
    saveSublocations: [],
    SublocationsFilter: { logic: "and",filters: [] },
    SublocationsSort: [],
    //------ Dictionary Arrays ------
    StorePromotions: [],
    StoreLocationsDict: [],
    StoreItemsDict: [],
    Locations: [],
    //--
    fullTake: 16,
    halfTake: 8,
    files: [],
    UploadFile: undefined,
    ImageType: "",
    DocumentTypes: [{ ddName: "jpg",ddID: 1 },{ ddName: "jpeg",ddID: 2 },{ ddName: "png",ddID: 3 }],
    DocumentTypeID: 0,
    notifyMessage: "",
    //--------------------------------
    LeftO: false,
    RightO: false,
    horizontalPanes: [{ size: '20%',min: '20px',collapsible: false },{ min: '300px',collapsible: false },{ size: '20%',collapsed: true,collapsible: true }], // Left, Middle, Right
    verticalPanes: [{ min: '300px' },{ size: '50%',collapsible: true }],  // Top, Bottom   
    //horizontalSubPanes: [{ size: '50%', min: '500px', collapsible: false, scrollable: true }, {  min: '400px', collapsible: false, scrollable: true }], // Left, Right
  };
  StoreLocationTableObjID = 544596;
  StoreItemsTableObjID = 544619;
  StorePromotionsTableObjID = 544701;
  SublocationsTableObjID = 517732;
  hasOptions = false; // selectedTopTab values (tabs)
  clearScreen = true;
  compUpdate = true;
  cancelAddClick = false;
  //-------------------
  backPrompt = "< Back to Locations";
  backMenuPrompt = '< Back to Receipts';
  //------------------
  CustomOrderID = 0;
  StoreLocationID = 0;
  SelectCustomOrderID = 0;
  //------------------

  vertSplitC = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":true,"resizable":true,"scrollable":true,"size":"50%"}]');
  vertSplitO = JSON.parse('[{"collapsible":false,"collapsed":false,"resizable":true,"scrollable":true,"min":"300px"},{"collapsible":true,"collapsed":false,"resizable":true,"scrollable":true,"size":"50%"}]');
  popupAnchor = null;
  popupOffset = { left: 50,top: 30 };
  itemsPopupOffset = { left: 20,top: 10 };
  setShowNotify = 0;

  async componentDidMount() {
    if (!SessionInfo.session || SessionInfo.session === '')
      return;
    let windowHt = window.innerHeight;
    SessionInfo.currentPage = "ReceiptsPage";
    SessionInfo.CopyOrderToCurrent = false;
    this.setState({ showMenu: false });
    this.setState({ showItemsDetail: false });
    await this.getCustomOrders('');
    this.setState({ StoreName: SessionInfo.StoreName });
    this.setState({ TableName: SessionInfo.CurrentTableName });
    //this.setState({ SeatName: SessionInfo.SeatName });
    this.setState({ SeatCount: SessionInfo.SeatCount });
    this.setState({ ItemCount: SessionInfo.ItemCount });
    this.runMonitorProcess = true;
    this.monitorProcess();
  }
  //--------------- Page Setup and Load Functions ----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  componentWillUnmount() {
    //trace("Store Location Will UnMount");
    SessionInfo.ReceiptsPage = this.state;
    SessionInfo.ReceiptsPage.showItemsDetail = false;
    this.runMonitorProcess = false;
    if (this.timeout)
      clearTimeout(this.timeout);
  }
  shouldComponentUpdate() {
    if (this.compUpdate === true)
      return true;
    else
      return false;
  }
  //----------------------------------
  runMonitorProcess = false;
  setTimer() {
    if (SessionInfo.loggedOn === true && this.runMonitorProcess === true)
      this.timeout = setTimeout(this.monitorProcess.bind(this),200); // 200 microseconds  
  }
  //----- Timer Process - Application Control Logic -------------------------------------------------
  monitorProcess = async (force) => { // Note - Monitor only runs if logged on
    if (force)
      this.runMonitorProcess = true;
    //trace(`monitor logoff: ${SessionInfo.forceLogoff}, On: ${SessionInfo.loggedOn}`);
    if (SessionInfo.forceLogoff === true) {
      SessionInfo.forceLogoff = false;
      this.props.navigate("/Login");
    }
    if (this.runMonitorProcess === true && SessionInfo.loggedOn === true) {
      this.setTimer();
      if (1 === 1 || document.hasFocus() === true) { // Execute monitor process
        //---- Ping and Notification Processing -------------
        if (SessionInfo.setShowNotify > 0 && SessionInfo.notifyMessage.length > 0) {
          this.setState({ notifyMessage: SessionInfo.notifyMessage });
          SessionInfo.notifyTimer = SessionInfo.notifyTime;
          this.setState({ showNotify: true }); // Show the Message   
          if (SessionInfo.notifyRing && (SessionInfo.SoundOn === true || SessionInfo.SoundOverride === true)) {
            //trace(`Play Notify Sound: ${SessionInfo.notifyRing}`);
            try {
              await SessionInfo.notifyRing.play();
            }
            catch (Ex) {
              trace(`Ring Ex: ${Ex}`);
            }
            SessionInfo.notifyRing = undefined;
            SessionInfo.SoundOverride = false;
          }
        }
        SessionInfo.setShowNotify = 0;
        if (SessionInfo.notifyTimer > 0) {
          if (--SessionInfo.notifyTimer <= 0)
            this.setState({ showNotify: false });
        } else if (SessionInfo.showNotify === true)
          this.setState({ showNotify: false }); // Hide the Notify Message
        if (SessionInfo.pingCounter++ > 50) {
          SessionInfo.pingCounter = 0;
          let CD = await CallOM("PingApp",SessionInfo.StoreLocationID,SessionInfo.CustomOrderID,CTYP.STR,"");
          if (CD && CD.d && CD.d.rows) {
            //trace(`Return from PingApp - CD: ${JSON.stringify(CD)}`);
            let rows = CD.d.rows;
            for (let ix = 0; ix < rows.length; ix++) {
              let row = rows[ix];
              SetNotify(row["NotificationMessage"],row["NotificationTypeID"]);
              SessionInfo.AppNotificationID = row["AppNotificationID"];
            }
          }
        }
      }
      //---- End of Notification Processing -------------
    }
  }
  acknowledgeMessage = async () => {
    let CD = await CallOM("AcknowledgeNotification",0,SessionInfo.AppNotificationID);
    this.setState({ showNotify: false });
    SessionInfo.notifyTimer = 50;
    SessionInfo.acknowledge = false;
    this.forceUpdate();
  }
  //--------------- Standard Page Functions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  //---- Top Tab -----------------------------------
  //handleTopTabSelect = async (e) => { // Tab Selection
  //  await this.selectTab(e.selected);
  //}
  //selectTab = async (tabNumber) => {
  //  //trace("selectTab - TabN=" + tabNumber);
  //  if (SessionInfo.clearSearch)
  //    SessionInfo.clearSearch();
  //  SessionInfo.setShowSave = 2; // Hide Save in Header 
  //  SessionInfo.setShowNew = 2;
  //  SessionInfo.setGridRefresh = 1; // Grid Refresh in Header  
  //  SessionInfo.setExcelExport = 1;
  //  SessionInfo.currExcelExportFunc = this.exportCustomOrdersExcel;
  //  SessionInfo.searchTitle = "Search Receipts";
  //  this.closeRightPane();
  //  this.closeLeftPane();
  //  this.setState({ verticalPanes: this.vertSplitO });
  //  if (this.state.customOrdersCount <= 1)
  //    await this.getCustomOrders('');
  //}
  searchCurrent = async (search) => {
    if (!search || typeof search !== 'string' || search === 'all' || search === 'ALL')
      search = "";
    displayMessage("Search CustomOrderItems");
    //this.setState({ customOrdersSkip: 0 });
    //this.setState({ customOrdersTake: this.state.halfTake })
    //this.setState({ customOrdersFilter: { logic: "and", filters: [] } });
    //this.setState({ customOrdersSort: [] });
    await this.getCustomOrders(search);
  }
  refreshGrid = async () => { // Note => functions do not bind their own this - if used clearRecord() - would have to bind this to the function
    //trace("Clear Screen - state: " + JSON.stringify(this.state)); 
    let fields = [];
    //  if (this.state.selectedTopTab === this.tabs.RECEIPTS) { // CustomOrders - Receipts
    //    this.getCustomOrders('');
    //  }
  }
  //--------------- End of Standard Page Functions ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- 
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------  
  frmt = true;
  // Load Receipts, Get Receipts
  getCustomOrders = async (search) => {
    if (!search)
      search = '';
    let favoriteReceipts = [];
    let CD = await CallOM("GetFavoriteReceipts",0,0,0);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      trace(`GetFavoritieReceipts CD.o: ${CD.x.o}  d: ${JSON.stringify(CD.d)}`);
      if (CD.d)
        favoriteReceipts = CD.d;
    }
    this.setState({ favoriteReceipts });
    trace(`favoriteReceipts: ${JSON.stringify(favoriteReceipts)}`);
    trace(`Get CustomOrders - locationID: ${SessionInfo.StoreLocationID}`);
    //public void GetCustomOrders(string Search, int SortType, int StoreLocationID, int SearchType, int Range)
    CD = await CallOMT("GetCustomOrders",TYP.STR,search,TYP.INT,1,TYP.INT,SessionInfo.StoreLocationID,TYP.INT,4,TYP.INT,5); // Sort-1, location, SearchType: 4-AmoutOwing=0,Status<15, Range: 5-All
    //let CD = await GetTableSearch(545072, search + "*StoreLocationID=" + this.state.CurrentStoreLocationID, "CustomerID,CustomOrderStatus,OrderCreationDate,OrderPaidDate,AmountOwing,ItemCount");
    //trace(`CustomOrders: ${JSON.stringify(CD.d)}`);
    try {
      let CustomOrders = CD.d.rows.map(dataItem => Object.assign({ IsFavorite: false },dataItem));
      let TotalReceiptsPrice = 0;
      CustomOrders.forEach((rw) => {
        TotalReceiptsPrice += rw.TotalPrice;
        let ix = favoriteReceipts.findIndex(order => order === rw.CustomOrderID);
        if (ix >= 0) {
          rw.IsFavorite = true;
        }
        //  this.state.favoriteReceipts.forEach(item => {
        //    let ix = StoreItems.findIndex(storItem => storItem.StoreItemID === item);
        //    if (ix >= 0) {
        //      StoreItems[ix].IsFavorite = true;
        //    }
        //  });
      });
      trace(`TotalReceiptsPrice: ${TotalReceiptsPrice}`);
      this.setState({ CustomOrders });
      this.setState({ ReceiptCount: CustomOrders.length });
      this.setState({ TotalReceiptsPrice });
      this.state.saveCustomOrders = CustomOrders.slice();
      //trace(`CustomOrders: ${JSON.stringify(CustomOrders)}`);
    } catch (error) {
      trace(`Failure in getCustomOrders - Error: ${error.message}`);
    }
  }
  //getCustomOrdersStatus = async (statusID) => {
  //  trace("Get CustomOrdersStatus - locationID: " + this.state.CurrentStoreLocationID);
  //  let CD = await GetTableSearch(545072, "*StoreLocationID=" + this.state.CurrentStoreLocationID + " and CustomOrderStatus=" + statusID + " and TotalPrice>0", "CustomerID,CustomOrderStatus,OrderCreationDate,OrderPaidDate,TotalPrice,ItemCount");
  //  try {
  //    const CustomOrders = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
  //    //trace("CustomOrders: " + JSON.stringify(CustomOrders));
  //    if (statusID === 2)
  //      this.setState({ CustomOrdersPending: CustomOrders });
  //    else if (statusID === 3)
  //      this.setState({ CustomOrdersAccepted: CustomOrders });
  //    else if (statusID === 5)
  //      this.setState({ CustomOrdersInProgress: CustomOrders });
  //    else if (statusID === 6)
  //      this.setState({ CustomOrdersReady: CustomOrders });
  //    else if (statusID === 10)
  //      this.setState({ CustomOrdersRejected: CustomOrders });
  //  } catch (error) {
  //    trace("Failure in getCustomOrdersStatus - Error: " + error.message);
  //  }
  //}
  //getCustomOrdersCategory = async (categoryID) => {
  //  let CD = await await CallOM("GetCustomOrdersCategory", this.state.StoreLocationID, categoryID, 0);
  //  //trace("CustomOrdersCategory: " + JSON.stringify(CD.d));
  //  try {
  //    const CustomOrders = CD.d.rows.map(dataItem => Object.assign({ selected: false }, dataItem));
  //    trace("CustomOrdersCategory: " + CustomOrders);
  //    this.setState({ CustomOrdersCategory: CustomOrders });
  //  } catch (error) {
  //    trace("Failure in getCustomOrdersCategory - Error: " + error.message);
  //  }
  //}
  getCustomOrderItems = async (search) => {
    if (!search)
      search = '';
    trace("Get CustomOrderItems - locationID: " + this.state.CurrentStoreLocationID + ", CustomOrderID=" + this.state.CustomOrderID);
    let CD = await GetTableSearchRaw(545113,search + "*CustomOrderID=" + this.state.CustomOrderID,"StoreItemID,Quantity,TotalPrice");
    //.log("CustomOrderItems: " + JSON.stringify(CD.d));
    try {
      const CustomOrderItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //trace("CustomOrderItems: " + CustomOrderItems);
      this.setState({ CustomOrderItems });
      this.setState({ customOrderItemsCount: CustomOrderItems.length });
    } catch (error) {
      trace("Failure in getCustomOrderItems - Error: " + error.message);
    }
  }
  getCustomOrderItemsStatus = async (statusID) => {
    trace("Get CustomOrderItemsStatus - locationID: " + this.state.CurrentStoreLocationID + ", CustomOrderID=" + this.state.CustomOrderID);
    let CD = await GetTableSearchRaw(545113,"*CustomOrderID=" + this.state.CustomOrderID,"StoreItemID,Quantity,TotalPrice");
    //.log("CustomOrderItems: " + JSON.stringify(CD.d));
    try {
      const CustomOrderItems = CD.d.rows.map(dataItem => Object.assign({ selected: false },dataItem));
      //trace("CustomOrderItems: " + CustomOrderItems);
      this.setState({ CustomOrderItemsPending: CustomOrderItems });
      //this.setState({ customOrderItemsCount: CustomOrderItems.length });
    } catch (error) {
      trace(`Failure in getCustomOrderItems - Error: ${error.message}`);
    }
  }
  //-------------------- End of Page Edit -----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  customOrdersPageChange = (event) => {
    this.setState({ customOrdersSkip: event.page.skip,customOrdersTake: event.page.take });
  }
  //--------------------------------------------------------------
  //--------------------------------------------------------------

  //--------------------- List Element Formats -----------------------------------------------
  OrderHeader = () => {
    return (
      <ListViewHeader style={{ color: 'rgb(160, 160, 160)',fontSize: 14 }} className='pl-4 pb-2 pt-2'>
        Orders {this.state.OrderState}
      </ListViewHeader>
    );
  }
  OrderFormat = props => {
    const minDate = new Date(2019,9,9);
    let item = props.dataItem;
    let imageVal = undefined;
    if (item["Image"]) {
      let value = item["Image"];
      //trace("ImageCell value: " + value);
      const byteCharacters = atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray],{ type: 'image/jpeg' });
      //trace("image: " + JSON.stringify(image));
      imageVal = URL.createObjectURL(image);
    }
    return (
      <div className="listRow" onClick={(e) => this.ViewDetail(item)}>
        <div className="listCol1">
          <span style={{ fontSize: 16,color: "black" }} >Order Type: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.CustomOrderType}</span><br />
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.CustomerID}</span><br />
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.PhoneNumber}</span><br />
        </div>
        <div className="listCol2">
          <span style={{ fontSize: 16,color: "black" }} >Total: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{moneyFormat(item.TotalPrice)}</span> <br />
          <span style={{ fontSize: 16,color: "black" }} >Items: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.ItemCount}</span>
        </div>
        <div className="listCol3">
          <span style={{ fontSize: 16,color: "black" }} >Ordered: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{!item.OrderCreationDate || Date.parse(item.OrderCreationDate) < minDate ? "" : this.dateTimeSFormat(item.OrderCreationDate)}</span>  <br />
          <span style={{ fontSize: 16,color: "black" }} >Requested: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{!item.OrderCreationDate || Date.parse(item.RequestDateTime) < minDate ? "ASAP" : this.dateTimeSFormat(item.RequestDateTime)}</span> <br />
          <span style={{ fontSize: 16,color: "black" }} >Scheduled: </span>
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{!item.ScheduledPrepDateTime || Date.parse(item.ScheduledPrepDateTime) < minDate ? "" : this.dateTimeSFormat(item.ScheduledPrepDateTime)}</span>
        </div>
        <div className="listOMImageDiv1">
          <img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Accept the current entry" className="functionImage" onClick={(e) => this.ClickPending(item)} /><br /> {/*onClick={this.ClickPending(item)} */}
          <TButton imageUrl={require("./images/delete.svg")} onClick={(e) => this.ClickPending(item)}>View Details</TButton> {/* */}
        </div>
      </div>
    );
  }
  OrderFooter = () => {
    return (
      <ListViewFooter className='listFooter'>
        <div className='footerLeft'>
          <span>SubTotal </span>
          <span>{moneyFormat(this.state.SubTotalPrice)}</span>
          <br />
          <span style={{ left: '180px',}}>Tax </span>
          <span>{moneyFormat(this.state.TotalTaxAmount)}</span>
        </div>
        <div className='footerRight'>
          <span>TOTAL </span>
          <span>{moneyFormat(this.state.TotalPrice)}</span>
          <br />
          <TButton id="checkoutButton" icon="restore" style={{ cursor: 'pointer',fontSize: 'large',color: 'black',fontWeight: 'bold',borderRadius: '10px',left: '80px',}} onClick={this.onCheckout} >Checkout</TButton>
        </div>
      </ListViewFooter>
    );
  }
  //---------------------------------------------------------------
  OrderItemHeader = () => {
    return (
      <ListViewHeader style={{ color: 'black',fontSize: 18 }} className='pl-4 pb-2 pt-2'>
        Order Details for: {this.state.CustomerName}
      </ListViewHeader>
    );
  }
  OrderItemFormat = props => {
    let item = props.dataItem;
    let imageVal = undefined;
    let optionsList = "<span style='color:#ff1a75'> OPTIONS </span><br/>";
    trace("optionList value: " + optionsList);
    if (item["Image"]) {
      let value = item["Image"];
      //trace("ImageCell value: " + value);
      const byteCharacters = atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray],{ type: 'image/jpeg' });
      //trace("image: " + JSON.stringify(image));
      imageVal = URL.createObjectURL(image);
    }
    return (
      <div className="listRow">
        <div className="topItems">
          <div className="listCol1">
            <span style={{ fontSize: 16,color: "black" }} >Item: </span>
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.StoreItemID}</span><br />
            <span style={{ fontSize: 16,color: "black" }} >{item.ItemDescription}</span>
          </div>
          <div className="listCol2">
            <span style={{ fontSize: 16,color: "black" }} >Price: </span>
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{moneyFormat(item.Price)}</span> <br />
            <span style={{ fontSize: 16,color: "black" }} >Options: </span>
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{moneyFormat(item.OptionsPrice)}</span>
          </div>
          {/*<div className="listOMImageDiv1">
            <Avatar shape='rounded' type='image' style={{ height: '50px', width: '50px', }}>
            <img src={imageVal} style={{ height: '50px', width: '50px', }} />
          </Avatar> 
          </div>  */}
          <div className="listCol3">
            <img src={require("./images/delete.svg")} alt="" id="GridRefresh" title="Delete the current entry" className="functionImage" onClick={(e) => window.confirm(`Delete ${item.ItemName.trimRight()}?`) && this.onDeleteItem(item)} /> {/* */}
          </div>
        </div>
        {this.hasOptions == true ?
          <div className="bottomItems">
            <div id="itemOptions" dangerouslySetInnerHTML={{ __html: optionsList }} ref={(div) => { this.anchor = div; }}></div>
          </div> : null}
      </div>
    );
  }
  OrderItemFooter = () => {
    return (
      <ListViewFooter className='listFooter'>
        <div className='footerLeft'>
          <span>SubTotal </span>
          <span>{moneyFormat(this.state.SubTotalPrice)}</span>
          <br />
          <span style={{ left: '180px',}}>Tax </span>
          <span>{moneyFormat(this.state.TotalTaxAmount)}</span>
        </div>
        <div className='footerRight'>
          <span>TOTAL </span>
          <span>{moneyFormat(this.state.TotalPrice)}</span>
          <br />
        </div>
      </ListViewFooter>
    );
  }
  //------------------- ListView Events ----------------------------------------------------------
  ClickPending = (item) => {
    trace("ClickPending: " + JSON.stringify(item));
    var CustomerID = item.CustomerID;
    //this.setState({ CustomerName: item.CustomOrderID }); 
    var CustomerName = item.CustomerID;
    trace("CustomerName: " + CustomerName);
    this.setState({ CustomerName: item.CustomerID });
    const curTime = new Date();
    this.setState({ ScheduledPrepDateTime: curTime.setMinutes(curTime.getMinutes() + 30) });
    this.setState({ showPopup: !this.state.showPopup });
    this.forceUpdate();
  }
  ClickMinus = () => {
    trace("ScheduledPrepDateTime: " + this.state.ScheduledPrepDateTime);
    //const curTime = Date.parse(this.state.ScheduledPrepDateTime);
    const curTime = new Date(this.state.ScheduledPrepDateTime);
    this.setState({
      ScheduledPrepDateTime: curTime.setMinutes(curTime.getMinutes() - 1)
    });
  }
  ClickPlus = () => {
    trace("ScheduledPrepDateTime: " + this.state.ScheduledPrepDateTime);
    //const curTime = Date.parse(this.state.ScheduledPrepDateTime);
    const curTime = new Date(this.state.ScheduledPrepDateTime);
    this.setState({
      ScheduledPrepDateTime: curTime.setMinutes(curTime.getMinutes() + 1)
    });
  }
  ViewDetail = (item) => {
    this.setState({ CustomOrderID: item.CustomOrderID });
    this.getCustomOrderItemsStatus(2);
    trace(`ViewDetail - item: ${JSON.stringify(item)}`);
    this.setState({ CustomerName: item.CustomerID });
    var TaxAmt = item.TotalTaxAmount + item.TotalTax2Amount + item.TotalTax3Amount;
    this.setState({ TotalTaxAmount: TaxAmt });
    this.setState({ TotalPrice: item.TotalPrice });
    this.setState({ SubTotalPrice: item.TotalPrice - TaxAmt });
    this.forceUpdate();
  }
  //---------------------
  addItemsToOrder = async () => {
    this.setState({ showItemsDetail: false });
    trace(`Add Items from Order: ${this.CustomOrderID}, LocationID: ${COV.StoreLocationID},${this.StoreLocationID}, Current: ${SessionInfo.StoreLocationID}`);
    // 1Dec22 - Need to Check Location match - and decline - later!  (Note COV.StoreLocationID is text)
    SessionInfo.CopyOrderToCurrent = true;
    trace(`CopyOrderToCurrent - CustomOrderD: ${SessionInfo.CopyCustomOrderID}, StoreLocationID: ${this.state.StoreLocationID}, Session-StoreLocationID: ${SessionInfo.StoreLocationID} - goto OrderPage`);
    this.props.navigate("/OrderPage");
  }
  doneItems = () => {
    this.setState({ showItemsDetail: false });
    this.forceUpdate();
  }
  /**
 * Adds time to a date. Modelled after MySQL DATE_ADD function.
 * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
 * https://stackoverflow.com/a/1214753/18511
 * 
 * @param date  Date to start with
 * @param interval  One of: year, quarter, month, week, day, hour, minute, second
 * @param units  Number of units of the given interval to add.
 */
  dateAdd(date,interval,units) {
    if (!(date instanceof Date))
      return undefined;
    var ret = new Date(date); //don't change original date
    var checkRollover = function () { if (ret.getDate() !== date.getDate()) ret.setDate(0); };
    switch (String(interval).toLowerCase()) {
      case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
      case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
      case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
      case 'week': ret.setDate(ret.getDate() + 7 * units); break;
      case 'day': ret.setDate(ret.getDate() + units); break;
      case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
      case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
      case 'second': ret.setTime(ret.getTime() + units * 1000); break;
      default: ret = undefined; break;
    }
    return ret;
  }
  _exportCustomOrders;
  exportCustomOrdersExcel = () => {
    this._exportCustomOrders.save();
  }
  handleChange = (event) => {
    this.setState({
      value: event.target.value
    });
  }
  chgFldVal(event) {
    this.setState({ [event.target.name]: event.target.value });
    //trace(`${event.target.name}:${event.target.value}`);
    if (this.clearScreen == true) {
      this.clearScreen = false;
      SessionInfo.setShowSave = 1; // Show Save in Header 
      SessionInfo.setShowNew = 1;
    }
    this.setState({ stateHasChanged: true });
  }
  async chgMoneyFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,''); // Matches any single single character not in the bracket [] and replaces with ''  - https://www.infobyip.com/regularexpressioncalculator.php
    //trace(`money newval: ${newVal}, target: ${this.state[event.target.name]}, name: ${event.target.name}`);
    //this.setState({ [event.target.name + 'S']: moneyFormat(newVal) });
    this.setState({ [event.target.name + 'S']: newVal });
    await this.setState({ [event.target.name]: newVal });
    //trace(`updated target: ${this.state[event.target.name]}, str: ${this.state[event.target.name + 'S']}`);
    this.setState({ stateHasChanged: true });
  }
  async focusMoneyFldVal(event) {
    //trace(`money target: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name + 'S']: this.state[event.target.name] });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
  }
  async blurMoneyFldVal(event) {
    //trace(`money target: ${this.state[event.target.name]}`);
    this.setState({ [event.target.name + 'S']: moneyFormat(this.state[event.target.name]) });
    //trace(`money target: ${this.state[event.target.name + 'S']}`);
  }
  chgPercentStart(event) {
    this.frmt = false;
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    trace("start newval: " + newVal);
    this.setState({ [event.target.name]: newVal });
    this.setState({ stateHasChanged: true });
  }
  chgPercFldVal(event) {
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    trace("perc newval: " + newVal);
    this.setState({ [event.target.name]: newVal });
    this.setState({ stateHasChanged: true });
  }
  chgPercentFldVal(event) {
    //var stateCopy = Object.assign({}, this.state[event.target.name]);
    let newVal = event.target.value.replace(/[^\d.-]/g,'');
    trace("percent blur newval: " + newVal);
    newVal /= 100.0;
    this.setState({ [event.target.name]: newVal });
    this.setState({ stateHasChanged: true });
    this.frmt = true;
  }
  chgDDFldVal = (event) => {
    this.setState({ [event.target.name]: event.target.value.ddID });
    this.setState({ stateHasChanged: true });
  }

  onChangeAmenities = (event) => {
    this.setState({ StoreAmenitiesVals: [...event.target.value] });
    trace("Amenities: " + this.state.StoreAmenitiesVals);
  }

  chgCheckboxVal(event) {
    //trace("stateCopy-" + event.target.name + " : " + JSON.stringify(this.state[event.target.name]));
    let setVal = true;
    if (this.state[event.target.name] === true)
      setVal = false;
    this.setState({ [event.target.name]: setVal });
    this.setState({ stateHasChanged: true });
  }
  //-------------------------------

  customOrderItemsPageChange = (event) => {
    this.setState({ customOrderItemsSkip: event.page.skip,customOrderItemsTake: event.page.take });
  }
  selectionCustomOrderItemsChange = (event) => {
    event.dataItem.selected = !event.dataItem.selected;
    this.forceUpdate();
  }
  //getCustomOrderItemsSort = (sort) => {
  //  return orderBy(this.state.CustomOrderItems, sort);
  //}
  openMenu = () => {
    this.setState({ showItemsDetail: false });
    this.keepMenu = true;
    this.setState({ showMenu: true });
    //trace(`open Menu - showMenu: ${this.state.showMenu}`);
  }
  onMenuItemClick = async (event) => {
    trace(`menu event Item: ${event.item.text}`);
    if (SessionInfo.currentItem !== undefined) {
      //trace('Current Item: ' + SessionInfo.currentItem.text);
      SessionInfo.currentItem.selected = false;
    }
    SessionInfo.currentItem = event.item;
    trace('2 Current Item: ' + SessionInfo.currentItem.text);
    event.item.selected = true;
    event.item.expanded = true;
    //trace(`Event - name: ${JSON.stringify(event.item)}`);
    SessionInfo.MenuSelect = event.item;
    this.keepMenu = false;
    this.setState({ showMenu: false });
    if (SessionInfo.menuAction !== undefined) {
      await SessionInfo.menuAction(); // onMenuItemClick in AppMain.js
    }
    this.forceUpdate();
  }
  closePopups = (id) => {
    this.setState({ showItemsDetail: false });
    this.setState({ showMenu: false });
  }
  setSelectedFavorites = async () => {
    trace(`set Selected Favorites`);
    let CustomOrders = this.state.CustomOrders;
    CustomOrders.forEach(item => {
      item.IsFavorite = false;
      //item.IsSelected = false;
    });
    let favoriteItems = [];
    let CD = await CallOM("GetFavoriteReceipts",0,this.CustomOrderID,0);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      trace(`GetFavorities CD.o: ${CD.x.o}  d: ${JSON.stringify(CD.d)}`);
      favoriteItems = CD.d;
    }
    this.setState({ favoriteItems });
    if (favoriteItems && favoriteItems.length > 0) {
      favoriteItems.forEach(item => {
        let ix = CustomOrders.findIndex(order => order.CustomOrderID === item);
        //trace(`fav item: ${item}, ix: ${ix}`);
        if (ix >= 0) {
          CustomOrders[ix].IsFavorite = true;
        }
      });
    }
  }
  addOrderToFavorites = async (item) => {
    this.cancelAddClick = true;
    trace("Favorite Order: " + item.CustomOrderID);
    let CD = await CallOM("AddFavoriteReceipts",0,item.CustomOrderID,0);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      trace(`favorite added for Order: ${item.CustomOrderID}`);
      SessionInfo.ReceiptsPageRef.setFavoriteItem(item.CustomOrderID);
    }
  }
  addCurrentOrderToFavorites = async () => {
    this.cancelAddClick = true;
    trace(`Favorite Order: ${this.CustomOrderID}, current: ${this.state.itemIsFavorite}`);
    let CD = await CallOM("AddFavoriteReceipts",0,this.CustomOrderID,0);
    if (CD.x.o === 0)
      await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
    else if (CD.x.o < 9500) {
      trace(`favorite added for Order: ${this.CustomOrderID}`);
      //SessionInfo.ReceiptsPageRef.setFavoriteItem(this.CustomOrderID);
      this.setFavoriteItem(this.CustomOrderID);
    }
  }
  setFavoriteItem = (customOrderID) => {
    let ix = this.state.CustomOrders.findIndex(order => order.CustomOrderID === customOrderID);
    trace(`favorite customOrderID: ${customOrderID}, ix: ${ix}`);
    if (ix >= 0) { // Manipulate state array items; https://www.robinwieruch.de/react-state-array-add-update-remove/?msclkid=f39d880ebcdc11ec85f269db7902fa0b
      let CustomOrders = this.state.CustomOrders.slice();
      trace(`favorite current: ${this.state.CustomOrders[ix].IsFavorite}`);
      CustomOrders[ix].IsFavorite = !this.state.CustomOrders[ix].IsFavorite;
      //this.state.CustomOrders[ix].IsFavorite = !this.state.CustomOrders[ix].IsFavorite;
      //this.setState({ itemIsFavorite: this.state.CustomOrders[ix].IsFavorite });
      this.setState({ CustomOrders });
      this.setState({ itemIsFavorite: CustomOrders[ix].IsFavorite });
      //trace(`after set current order ${this.state.CustomOrders[ix].CustomOrderID}: ${this.state.CustomOrders[ix].IsFavorite} `);
      trace(`after set current order ${this.state.CustomOrders[ix].CustomOrderID}: ${CustomOrders[ix].IsFavorite} `);
      //if (this.state.CustomOrders[ix].IsFavorite === false) {
      if (CustomOrders[ix].IsFavorite === false) {
        trace(`favorites: ${JSON.stringify(this.state.favoriteReceipts)}`);
        let fx = this.state.favoriteReceipts.findIndex(order => order === customOrderID);
        if (fx >= 0)
          this.state.favoriteReceipts.splice(fx,1);
        trace(`favorites: ${JSON.stringify(this.state.favoriteReceipts)}`);
      }
      //this.setState({ CustomOrders });
      let rx = this.state.saveCustomOrders.findIndex(order => order.CustomOrderID === customOrderID);
      //trace(`favorite storeItemID: ${item.StoreItemID}, ix: ${ix}`);
      if (rx >= 0) { // Manipulate state array items; https://www.robinwieruch.de/react-state-array-add-update-remove/?msclkid=f39d880ebcdc11ec85f269db7902fa0b
        this.state.saveCustomOrders[rx].IsFavorite = this.state.CustomOrders[ix].IsFavorite;
      }
    }
  }
  gotoLanding = async () => {
    trace("Go To LandingPage");
    this.props.navigate("/LandingPage");
  }
  updateSearchValue = async (evt) => { // Original Search Value event handler
    let newValue = evt.value;
    trace(`updatesearchValue: ${this.state.searchValue}, targetname: ${evt.name}, event.value: ${evt.target.value}, ${newValue}`);
    if (!(newValue.length > 0 && (newValue[0] === '*' || newValue[0] === '`'))) {
      if (newValue.length >= 2 || this.state.lastSearchValue.length > newValue.length) {
        this.setState({ lastSearchValue: newValue });
        trace(`call itemSearch: ${newValue}`);
        this.itemSearch(newValue);
      }
      else {
        this.setState({ lastSearchValue: newValue });
      }
    }
    this.setState({ searchValue: newValue }); // Note - moved save to State to last operation
  }
  chgSrchVal = (event) => {
    this.setState({ searchValue: event.target.value });
    this.setState({ stateHasChanged: true });
    trace(`chgsearchValue: ${this.state.searchValue}, targetname: ${event.target.name}, event.value: ${event.target.value}`);
    this.setState({ [event.target.name]: event.target.value });
    this.setState({ stateHasChanged: true });
    if (!(event.target.value.length > 0 && (event.target.value[0] === '*' || event.target.value[0] === '`'))) {
      if (event.target.value.length >= 2 || this.state.lastSearchValue.length > event.target.value.length) {
        this.setState({ lastSearchValue: event.target.value });
        this.itemSearch(event.target.value);
      }
      else {
        this.setState({ lastSearchValue: event.value });
      }
    }
  }
  chgTAFldVal = (event) => {
    trace(`chgTAsearchValue: ${this.state.searchValue}, targetname: ${event.target.name}, event.value: ${event.value}`);
    this.setState({ [event.target.name]: event.value });
    this.setState({ stateHasChanged: true });
    if (!(event.value.length > 0 && (event.value[0] === '*' || event.value[0] === '`'))) {
      if (event.value.length >= 2 || this.state.lastSearchValue.length > event.value.length) {
        this.setState({ lastSearchValue: event.value });
        this.itemSearch(event.value);
      }
      else {
        this.setState({ lastSearchValue: event.value });
      }
    }
    this.setState({ showInfoChange: true });
  }
  itemSearch = async (srchStr) => { // Search for Match
    trace(`Receipt - search: ${srchStr}, lth: ${srchStr.length}`);
    //trace(`saveStoreItems: ${JSON.stringify(SessionInfo.saveStoreItems)}`);
    this.closePopups(3);
    if (srchStr.length > 2) {
      this.comp2Update = false; // Stop Updates
      let StoreItems = [];
      let cnt = 0;
      let cats = [];
      let groupSwitch = false;
      try {
        let srch = new RegExp(srchStr,"i"); // case insensitive
        let groupFnd = 0;
        if (srchStr.length > 3) { // Search Receipts
        }
        this.state.CustomOrders.forEach(row => {
          //trace(`search idx: ${row.ItemName.search(srchStr)}, name: ${row.ItemName}, ItemDesc: ${row.ItemDescription.search(srchStr)}, Desc: ${row.ItemDescription}`);
          trace(`search row: ${JSON.stringify(row)}`);
          let found = false;
          if (found == false) {
            //  if (row.ItemName.search(srch) >= 0) {
            //    StoreItems.push(row);
            //    cnt++
            //  } else if (row.ItemDescription.search(srch) >= 0) {
            //    StoreItems.push(row);
            //    cnt++
            //  }
            //  else if (row.HasSecond === true) {
            //    if (row.ItemName1.search(srch) >= 0) {
            //      StoreItems.push(row);
            //      cnt++
            //    } else if (row.ItemDescription1.search(srch) >= 0) {
            //      StoreItems.push(row);
            //      cnt++
            //    }
            //  }
          }
        });
        this.comp2Update = true; // Start Updates
      } catch (ex) {
        console.error(`itemSearch error: ${ex}`);
      }
      //this.setState({ StoreItems });
    } else { // ALL
      //this.setState({ StoreItems: SessionInfo.saveStoreItems[this.PIG] });
    }
  }
  clearSearch = async (event) => { // ClearDisplay, Clear Messages - see updateSearchValue
    //trace(`clear search: ${this.state.searchValue}`);
    if (this.state.searchValue !== '') {
      await this.getCustomOrders('');
      this.setState({ searchValue: '' });
      this.setState({ lastSearchValue: '' });
      this.setState({ clearSetAll: true });
      this.setState({ stateHasChanged: true });
    }
  }
  //-----------------------------------------------------------------------------
  showFavorites = async () => {
    trace(`show Favs - viewall: ${this.state.isViewAll}`);
    if (this.state.isViewAll === true) {
      this.setState({ isViewAll: false });
      let CustomOrders = [];
      let cnt = 0;
      this.state.CustomOrders.forEach(row => {
        if (row.IsFavorite === true) {
          CustomOrders.push(row);
          cnt++;
        }
      });
      if (cnt === 0) {
        this.setState({ isViewAll: true });
        this.setState({ CustomOrders: this.state.saveCustomOrders.slice() });
        displayMessage(`Currently NO Favorites are Set`);
      }
      else
        this.setState({ CustomOrders });
    } else {
      this.setState({ isViewAll: true });
      this.setState({ CustomOrders: this.state.saveCustomOrders.slice() });
    }
  }
  //--------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  ReceiptItemHeader = () => {
    return (
      <ListViewHeader className='listReceiptItemHeader'>
          <span style={{ fontSize: 'medium',fontWeight: 'bold' }}>&nbsp; Receipt Items </span>
      </ListViewHeader>
    );
  }
  ReceiptItemFormat = props => {
    let item = props.dataItem;
    let imageVal = undefined;
    if (item["Image"]) {
      let value = item["Image"];
      //trace("ImageCell value: " + value);
      const byteCharacters = window.atob(value);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      let image = new Blob([byteArray],{ type: 'image/jpeg' });
      //trace("image: " + JSON.stringify(image));
      imageVal = URL.createObjectURL(image);
    }
    return (
      <div className="listReceiptRow">
        <div className="listCol1">
          <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{item.ItemName}&nbsp;&nbsp;&nbsp;
            {/*  {item.IsPromotion ?*/}
            {/*    <Promotion alt="" fill={SessionInfo.addColor} id="FavoriteSelectBtn" title={`Set ${item.ItemName} as Favorite`} className="favoriteImage" />*/}
            {/*    : null}*/}
          </span><br />
          <span style={{ fontSize: 13,color: "black" }} >{item.ItemDescription}</span>
        </div>
        <div className="listColRec">
          <div className="listCol2">
            <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }} >{moneyFormat(item.Price)}</span> <br style={{ lineHeight: '0px',}} />
            {item.Quantity > 1 ? <span style={{ fontSize: 14,color: "black",fontWeight: 'bold' }}>&nbsp;x&nbsp;{item.Quantity}<br /></span> : null}
            {item.OptionsPrice > 0 ? <span style={{ fontSize: 14,color: "darkblue",fontWeight: 'bold' }} >{moneyFormat(item.OptionsPrice)}<br /></span> : null}
            <span style={{ fontSize: 18,color: "black",fontWeight: "bold" }} >{moneyFormat(item.TotalPrice)}</span> <br style={{ lineHeight: '0px',}} />
          </div>
          <div className="listColR4">
            <Avatar rounded='large' size='large' type='image' style={{ height: '66px',width: '66px',}}>
              <img alt="" src={imageVal} style={{ height: '66px',width: '66px',}} title={`${item.ItemName} (Photo Coming}`} onClick={(e) => this.addReceiptItemItemToOrder(item)} />
            </Avatar>
          </div>
        </div>
      </div>
    );
  }
  ReceiptItemFooter = () => {
    return (
      <ListViewFooter className='listReceiptItemFooter'>
        {/*  <div className='listFooterLeft'>*/}
        {/*    <span style={{ fontSize: 'large', fontWeight: 'bold' }}>&nbsp;&nbsp;&nbsp;Related Items </span>*/}
        {/*  </div>*/}
        {/*  <div className='listFooterRight'>*/}
        {/*  </div>*/}
      </ListViewFooter>
    );
  }
  addReceiptItemItemToOrder = async (item) => { // NOTE - 1DEC22 - this is NOT implemented
    trace(`Add Receipt Item: ${item.StoreItemID}, Order: ${this.CustomOrderID}`);
  }
  //------------------------- End Select StoreItem ----------------------------------------------------------------------
  displayOrderItems = async (item) => {
    //trace(`Display Add Item: ${JSON.stringify(item)}`);
    //trace(`Add Item: ${item.StoreItemID}, Order: ${this.CustomOrderID}`);
    this.setState({ showMenu: false });
    this.setState({ showItemsDetail: false });
    this.forceUpdate();
    if (this.cancelAddClick === false) {
      trace(`displayOrderItems - CustomOrderD: ${item.CustomOrderID}`);
      SessionInfo.CopyCustomOrderID = item.CustomOrderID; // OrderPage - if CopyOrderToCurrent is set - copy this order if requested
      SessionInfo.CopyOrderToCurrent = false;
      //----
      let CD = await CallOM("GetCustomOrderTrans",0,item.CustomOrderID,0,null,null,1); // 1 for parm2 indicates editview
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) { // Retrieved Value Assignment for: CustomOrders
        //trace("CustomOrder: " + JSON.stringify(CD));
        let row = CD.d;
        CustomOrdersO.forEach((elem) => {
          if (elem.n in row) {
            //trace(`Set ${elem.n}: ${row[elem.n]}`);
            let sobj = {};
            try {
              if (elem.t === 34 || elem.t === 35) { // Date, DateTime
                //sobj[elem.n] = new Date(row[elem.n]); // For use with DateTime Control
                sobj[elem.n] = row[elem.n];
                COV[elem.n] = new Date(row[elem.n]);
              } else if (elem.t === 28) {
                sobj[elem.n] = row[elem.n];
                let sobjS = {};
                sobjS[elem.n + 'S'] = moneyFormat(row[elem.n]);
                //trace(`Set Money Field: ${elem.n + 'S'}, Value: ${ sobjS[elem.n + 'S']}`);
                this.setState(sobjS);
                COV[elem.n] = row[elem.n];
              } else {
                sobj[elem.n] = row[elem.n];
                COV[elem.n] = row[elem.n];
              }
              //trace(`sobj ${JSON.stringify(sobj)}, t: ${elem.t}, val: ${COV[elem.n]}`);
              this.setState(sobj);
              // Add item specific code here
              //if (elem.n === 'ImageID')
              //  ImageID = row[elem.n];
            }
            catch (error) {
              trace(`Set CustomOrdersO Field: ${elem.n}, Error: ${error.message}`);
            }
          }
        });
        this.CustomOrderID = COV.CustomOrderID;
        this.StoreLocationID = row.SLocationID;
      }
      //----
      let CustomOrderItems;
      CD = await CallOM("GetCustomOrderItems",0,item.CustomOrderID,0);
      if (CD.x.o === 0)
        await LogOffResetMsg(`Web Session Has Failed in ${SessionInfo.currentPage} - Session: ${CD.x.s}`,1);
      else if (CD.x.o < 9500) {
        //trace(`OrderItems: ${JSON.stringify(CD.d)}`);
        CustomOrderItems = CD.d.rows;
        //trace("CustomOrderItems: " + JSON.stringify(CustomOrderItems));
        this.setState({ CustomOrderItems });
        //SessionInfo.saveRelatedItems = RelatedItems.slice();
        let rx = this.state.CustomOrders.findIndex(receipt => receipt.CustomOrderID === item.CustomOrderID);
        if (rx > 0)
          this.itemsPopupOffset.top = rx * 85 + 150;
        else
          this.itemsPopupOffset.top = 150;
        this.compUpdate = true;
        trace(`ReceiptID: ${item.CustomOrderID}, ix: ${rx}, isFavorite: ${this.state.CustomOrders[rx].IsFavorite}`);
        this.setState({ itemIsFavorite: this.state.CustomOrders[rx].IsFavorite });
        // Set Height of Popup - note - items will scroll if short
        let popupHeight = 460 + CustomOrderItems.length * 80; // Height of the header and item list
        if (popupHeight > (SessionInfo.height - 70))
          popupHeight = SessionInfo.height - 70;
        document.documentElement.style.setProperty('--itemDetailPopupHt',`${popupHeight}px`); // Popup Height 
        this.setState({ showItemsDetail: true });
      }
    }
    else
      this.cancelAddClick = false;
  }
  //------------------------- List Template ----------------------------------------------------------------------------------------------------------------------------
  ReceiptsHeader = () => {
    return (
      <ListViewHeader className='listReceiptsHeader'>
        <div className='receiptsHeaderLeft'>
          <span style={{ fontSize: 'large' }}>Number of Receipts: </span>
          <span style={{ fontSize: 'large',fontWeight: 'bold' }}>{this.state.ReceiptCount}</span>
        </div>
      </ListViewHeader>
    );
  }
  ReceiptsFormat = props => {
    let item = props.dataItem;

    return (
      <div className="listReceiptsRow" onClick={(e) => this.displayOrderItems(item)}>
        <div className="listRECContainer">
          <div className="boxREC1">
            <div>
              <span>{dateFormat(item.OrderPaidDate)}&nbsp;&nbsp;
                {item.IsFavorite ?
                  <Favorite alt="" fill={SessionInfo.addColor} id="FavoriteSelectBtn" title={`Set ${item.ItemName} as Favorite`} className="favoriteImageB" onClick={(e) => this.addOrderToFavorites(item)} />
                  :
                  <FavoriteSelect alt="" fill={SessionInfo.addColor} id="FavoriteSelectBtn" title={`Set ${item.ItemName} as Favorite`} className="favoriteImageB" onClick={(e) => this.addOrderToFavorites(item)} />}
              </span>
            </div>
            <div>
              <span>#{item.CustomOrderID} &nbsp; </span>
              <span>{item.StoreLocationID}</span>
            </div>
            {item.PaidComment.length > 0 ?
              <div>
                <span style={{ fontWeight: "normal" }}>Comment: &nbsp;</span>
                <span style={{ fontSize: 16 }}>{item.PaidComment}</span>
              </div> : null}
          </div>
          <div className="boxREC2">
            <span>Items:</span>
            <br/>
            <span>Total:</span>
            <br />
            <span>Paid:</span>
            <br />
            <span>Tip:</span>
          </div>
          <div className="boxREC3">
            <span>{item.ItemCount}</span> 
            <br/>
            <span>{moneyFormat(item.TotalPrice)}</span>
            <br />
            <span>{moneyFormat(item.AmountPaid)}</span>
            <br />
            <span>{moneyFormat(item.TipAmount)}</span>
            <br style={{ lineHeight: '0px',}} />
          </div>
        </div>
      </div>
    );
  }
  ReceiptsFooter = () => {
    //const [state, onCheckout] = React.useContext(StateContext);
    return (
      <ListViewFooter className='listReceiptsFooter'>
        <div style={{left: '20px'}}>
          <span> &nbsp; &nbsp; &nbsp;Receipts Total: &nbsp;</span>
          <span style={{ fontWeight: 'bold' }}>{moneyFormat(this.state.TotalReceiptsPrice)}</span>
          <br />
        </div>
      </ListViewFooter>
    );
  }
  //----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
  //------------------------- Receipt Items ---------------------------------------------------------------
  rowCustomOrderItemClick = async (event) => { // Click on Receipt Item - see if there is additional information
    const current = this.state.CustomOrderItems.findIndex(dataItem => dataItem === event.dataItem);
    let CustomOrderItemID = this.state.CustomOrderItems[current].CustomOrderItemID;
    trace(`OrderItemID: ${CustomOrderItemID} `);
    let CD = await CallOM("GetOrderItemOptionsList",0,CustomOrderItemID,0); // Or GetOrderItemOptionsList  
    //trace("OrderItemOptions: " + JSON.stringify(CD.d));
    this.setState({ CustomOrderItemOptions: CD.d.rows });
    //trace("OrderItemOptions: " + JSON.stringify(this.state.CustomOrderItemOptions));
    displayMessage(`${this.state.CustomOrderItemOptions.length} Options for OrderItemID: ${CustomOrderItemID}`);
  }

  render() {
    return (
      <div id="ReceiptsPage" className="pageMain">
        {/*<div id="hdr" className="header">
          <div className="headerLogoLeft">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: 'cover' }} onClick={(e) => this.gotoLanding()} />
          </div>
          <div className="headerCenter">
            <div className="headerCenterMenu">
              <MenuBar alt="" fill={SessionInfo.headingTextColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} />
            </div>
            <div className="headerCenterTop">
              <span className='landingHeadTextLarge' >&nbsp; &nbsp; Receipts</span>
            </div>
            <div className="headerCenterMid">
              <span>
                <span className='landingHeadText' >&nbsp; &nbsp;&nbsp;{SessionInfo.logonName}</span>
              </span>
            </div>

            <div className="headerCenterBot">
              <div className="seatsDiv">
                {this.state.isViewAll ?
                  <Button id="showFavoritesButton" icon="star" onClick={this.showFavorites} >&nbsp;View Favorites&nbsp;</Button>
                  :
                  <Button id="showFavoritesButton" icon="star-outline" onClick={this.showFavorites} >&nbsp;View All Receipts&nbsp;</Button>}
              </div>
            </div>
          </div>
        </div>*/}

        <div id="hdr" className="headerReceipts">
          <div className="headerTop">
            <div className="headerLeft">
              <div onClick={this.gotoLanding}>&nbsp; {this.backPrompt}</div>
            </div>
            <div className="headerOrderCenter"> {/* onClick={(e) => this.closePopups(6)}>*/}
              <div className="headerCenterR2">
                {/*<ShoppingCart stroke={SessionInfo.ECV.BrandColor} count={this.state.ItemCount} />*/}
              </div>
              {/*<CartDisplay alt="" stroke={SessionInfo.headingTextColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} />*/}
              <div className="headerCenterMenu">
                {SessionInfo.loggedOn === true && SessionInfo.accountLogon == true ?
                  <MenuBar alt="" fill={SessionInfo.ECV.BrandColor} id="MenuBtn" title={`View Menu`} className="functionImageL" onClick={(e) => this.openMenu()} ref={this.menuAnchor} /> :
                  <span className="signin" onClick={(e) => this.signIn()}>Sign In</span>}
              </div>
            </div>
          </div>

          <div className="headerRecCenterTop">
            <span className='landingHeadTextLarge' >Receipts</span>
          </div>
          <div className="headerRecCenterMid">
            <span>
              <span className='landingHeadText' >{SessionInfo.logonName}</span>
            </span>
            <div className="receiptsFav">
              {this.state.isViewAll ?
                <Button id="showFavoritesButton" icon="star" onClick={this.showFavorites} >View Favorites</Button>
                :
                <Button id="showFavoritesButton" icon="star-outline" onClick={this.showFavorites} >View All Receipts</Button>}
            </div>
          </div>

          <div className="headerMiddle" ref={(div) => { this.detailOptionAnchor = div; }}>
            <div className="headerMessage">
              {this.state.showNotify ?
                <>
                  <div dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }} onClick={(e) => { this.acknowledgeMessage(); }} />
                  {SessionInfo.acknowledge ?
                    <div className="bottomButton">
                      <Button icon="tell-a-friend" className="medButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
                    </div> : null}
                </> : null}
            </div>
          </div>

          <div className="headerRecBottom">
            <div className="headerOrderCenterBot">
              <div id="headerSearch">
                <div id="searchBtnDiv">
                  <Tooltip position="top" anchorElement="pointer">
                    <ButtonSearch alt="" fill={SessionInfo.searchColor} title={`Search Menu`} className="functionImage" onClick={this.searchButton} />
                  </Tooltip>
                </div>
                <div id="searchBoxDiv">
                  {/*<span style={{ float: 'left',paddingTop: '7px',paddingLeft: '70px' }}>Search&nbsp;</span><br />*/}
                  <FloatingLabel label='Search Receipts' style={{ width: '300',color: 'darkblue' }} editorId={'search-id'} editorValue={this.state.searchValue}>
                    <Input value={this.state.searchValue} autoComplete="off" onChange={evt => this.updateSearchValue(evt)} className='searchInput' />
                  </FloatingLabel>
                </div>
                {this.state.searchValue.length > 0 ?
                  <div id="clearSearchBtnDiv">
                    <Tooltip position="top" anchorElement="pointer">
                      <ButtonClear alt="" fill={SessionInfo.searchColor} title={`Clear Search Box`} className="functionImage" onClick={this.clearSearch} />
                    </Tooltip>
                  </div> : null}
              </div>
            </div>
          </div>
        </div>

        <Popup anchor={this.menuAnchor.current} show={this.state.showMenu} popupClass={'popupMenu'} >
          <div className="mainMenu">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
            <div className="menuTree">
              {SessionInfo.HasScanAndPay === false ?
                <br /> : null}
              {/*{SessionInfo.defaultLogon === true ?*/}
              {/*  <br /> : null}*/}
              <TreeView
                data={SessionInfo.MenuControlNQR}
                size='large' draggable='false' selection='single'
                onItemClick={this.onMenuItemClick}
                className='menuTreeSub'
                item={props => [<span key={props.item.key} className={props.item.className}>{props.item.icon}{props.item.space}{props.item.text}</span>]}
              />
            </div>
          </div>
        </Popup>
        {/*<Popup offset={this.popupOffset} show={this.state.showNotify} popupClass={'popup-content'} ><div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }}></div></Popup>*/}
        {/* Message Notify Popup */}
        <Popup offset={SessionInfo.popupNotifyOffset} show={this.state.showNotify} popupClass={'popup-content'} >
          <div id="messageBox" dangerouslySetInnerHTML={{ __html: this.state.notifyMessage }} onClick={(e) => { this.acknowledgeMessage(); }} />
          {SessionInfo.acknowledge ?
            <div className="bottomButton">
              <Button icon="tell-a-friend" className="medButton" onClick={this.acknowledgeMessage}>Acknowledge</Button>
            </div> : null}
        </Popup>
        {/* Item Info Popup */}
        <Popup anchor={this.detailOptionAnchor} show={this.state.showItemsDetail} popupClass={'popupItemDetail'} >
          <div id="receiptsBox">
            <div className="receiptTop">
              <div className="receiptLeft">
                <div onClick={this.doneItems}>&nbsp; {this.backMenuPrompt}</div>
              </div>
              <div className="receiptRight">
                <Button id="addButton" icon="checkbox-checked" className="smallButton" onClick={this.addItemsToOrder} >Add To Order</Button>
              </div>
            </div>
            <br /><br />
            <div id="div2" className="displayField">
              <span style={{ fontSize: 14,color: "black" }}>Location: </span>
              <span style={{ fontSize: 18,color: "black",fontWeight: "bold" }}>{this.state.StoreLocationID}</span>
            </div>
            <div id="div2" className="displayField">
              <span style={{ fontSize: 12,color: "black" }}>&nbsp; &nbsp; Seat Name: &nbsp;&nbsp;&nbsp;</span>
              <span style={{ fontSize: 16,color: "black",fontWeight: "bold" }}>{this.state.SeatName}</span>
              <span style={{ fontSize: 14,color: "black" }}>&nbsp; &nbsp; #</span>
              <span style={{ fontSize: 14,color: "black",fontWeight: "bold" }}>{this.state.CustomOrderID}</span>
              <span style={{ fontSize: 18,color: "black",fontWeight: "bold" }}> &nbsp;
                {this.state.itemIsFavorite ?
                  <Favorite alt="" fill={SessionInfo.addColor} id="FavoriteSelectBtn" title={`Set Receipt as Favorite`} className="favoriteImage" onClick={(e) => this.addCurrentOrderToFavorites()} />
                  :
                  <FavoriteSelect alt="" fill={SessionInfo.addColor} id="FavoriteSelectBtn" title={`Set Receipt as Favorite`} className="favoriteImage" onClick={(e) => this.addCurrentOrderToFavorites()} />}
              </span>
            </div>
            <br />
            <div id="div2" className="displayFieldMoney">
              <span className="moneyLabel"> &nbsp; &nbsp; Total Price:</span>
              <span className="moneyAmount">{moneyFormat(this.state.TotalPrice)}</span>
            </div>
            <div id="div2" className="displayFieldMoney">
              <span className="moneyLabel">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Taxes:</span>
              <span className="moneyAmount">{moneyFormat(this.state.TotalTaxAmount + this.state.TotalTax2Amount + this.state.TotalTax3Amount)}</span>
            </div>
            <div id="div2" className="displayFieldMoney">
              <span className="moneyLabel"> &nbsp;&nbsp; &nbsp; &nbsp;Total Bill:</span>
              <span className="moneyAmount">{moneyFormat(this.state.TotalPrice + this.state.TotalTaxAmount + this.state.TotalTax2Amount + this.state.TotalTax3Amount)}</span>
            </div>
            <div id="div2" className="displayFieldMoney">
              <span className="moneyLabel">&nbsp;&nbsp;Tip Amount:</span>
              <span className="moneyAmount">{moneyFormat(this.state.TipAmount)}</span>
            </div>
            <div id="div2" className="displayFieldMoney">
              <span className="moneyLabel"> Amount Paid:</span>
              <span className="moneyAmount">{moneyFormat(this.state.AmountPaid)}</span>
            </div>
            <div id="div2" className="displayField">
              <span style={{ fontSize: 14,color: "black" }}>Receipt Date: &nbsp;</span>
              <span style={{ fontSize: 14,color: "black",fontWeight: "bold" }}>{dateFormat(this.state.OrderPaidDate)}</span>
            </div>
            <div className="displayField">
              <span className="editFieldLabel">Your Rating</span>
              <Rating value={this.state.Rating} name="Rating" selection={"continues"} readOnly={true} defaultValue={this.state.Rating} max={5} />
            </div>
            <br />
            {this.state.PaidComment.length > 0 ?
              <div className="receiptCommentField">
                <span className="editFieldLabel">&nbsp;Your Comment</span>
                <br />
                <textarea value={this.state.PaidComment} name="PaidComment" autoComplete="off" className="receiptComment" readOnly={true} />
              </div> : null}
            <div className="relatedList">
              <ListView data={this.state.CustomOrderItems} item={this.ReceiptItemFormat} header={this.ReceiptItemHeader} footer={this.ReceiptItemFooter} style={{ width: "100%",backgroundColor: 'white' }} />
            </div>
            <br />
            <div style={{ height: 27,width: '100%',marginTop: 3 }}>
              <span>&nbsp;&nbsp;</span>
              <Button id="addButton" icon="checkbox-checked" className="smallButton" onClick={this.addItemsToOrder} >Add All Items To Order</Button>
              {/*<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <Button id="doneButton" icon="close-outline" className="smallButton" onClick={this.doneItems}>Back to Receipts</Button>
              <span>&nbsp;&nbsp;</span>*/}
            </div>
            <br />
          </div>
        </Popup>

        <div className="receiptList">
          {/*<StateContext.Provider value={[this.state.TotalPrice, this.onCheckout]}>*/}
          <ListView data={this.state.CustomOrders} item={this.ReceiptsFormat} header={this.ReceiptsHeader} footer={this.ReceiptsFooter} />
        </div>

        <div className="footerReceipts">
          <span></span>
        </div>
      </div>  
    );
  }
}

export default withRouter(ReceiptsPage);