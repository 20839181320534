//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
// omAPI Login - InteractMenu Logon  -   Version 1.005 - May 17, 2019
//----------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef,useEffect } from 'react';
import './App.css';
import { Input } from "@progress/kendo-react-inputs";
import { Button } from '@progress/kendo-react-buttons';
import { FloatingLabel } from '@progress/kendo-react-labels';
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { LogOn,LogOff,mainLogon,UserRegister,RequestPWReset,trace,AddressLogOn,GetTableFilter,MaskString,UnmaskString,LogonWithPhone,SendVerifyCode,LogonWithPhoneVerify,setCookie,getCookie } from './CommonCode.js';
import { SessionInfo } from './App';

//-------------
//let LoginInProgress = false;
//const emailRegex = new RegExp(/\S+@\S+\.\S+/);
//const emailValidator = (value) =>
//  emailRegex.test(value) ? "" : "Please enter a valid email.";
//const LabelEmailInput = (fieldRenderProps) => {
//  const { validationMessage, visited, label, id, valid, ...others } =
//    fieldRenderProps;
//  const showValidationMessage = visited && validationMessage;
//  return (
//    <FieldWrapper>
//      <Label editorId={id} editorValid={valid}>
//        {label}
//      </Label>
//      <Input valid={valid} type={"email"} id={id} {...others} />
//      {!showValidationMessage && (
//        <Hint>Enter your personal email address.</Hint>
//      )}
//      {showValidationMessage && <Error>{validationMessage}</Error>}
//    </FieldWrapper>
//  );
//};
//const FloatingLabelEmailInput = (fieldRenderProps) => {
//  const { validationMessage, visited, label, id, valid, value, ...others } =
//    fieldRenderProps;
//  const showValidationMessage = visited && validationMessage;
//  return (
//    <FieldWrapper>
//      <FloatingLabel
//        label={label}
//        editorValue={value}
//        editorValid={valid}
//        editorId={id}
//      >
//        <Input value={value} valid={valid} type={"email"} id={id} {...others} />
//      </FloatingLabel>
//      {!showValidationMessage && (
//        <Hint>Enter your personal email address.</Hint>
//      )}
//      {showValidationMessage && <Error>{validationMessage}</Error>}
//    </FieldWrapper>
//  );
//};         
var tryCount = 0;
var LoginInProgress = false;    
var offset = false;
            
function SignIn(props) {
  const { classes } = props;
  const [isLogin,setShowLogin] = React.useState(true);
  const [showMsg,setShowMsg] = React.useState(false);
  const [mesgColor,setMesgColor] = React.useState("green");
  const [failCount,setFailCount] = React.useState(0);
  const [loginMessage,setLoginMessage] = React.useState('');
  const [emailAddress,setEmailAddress] = React.useState('');
  const [emailStyle,setEmailStyle] = React.useState('');
  const [firstName,setFirstName] = React.useState('');
  const [lastName,setLastName] = React.useState('');
  const [phoneNumber,setPhoneNumber] = React.useState('');
  const [verify,setVerify] = React.useState(''); 
  const [password,setPassword] = React.useState('');
  const [confirmPassword,setConfirm] = React.useState('');
  const [showPassword,setShowPassword] = React.useState(false);
  const [stayLoggedOn,setStayLoggedOn] = React.useState(SessionInfo.stayLoggedOn);
  const navigate = useNavigate();
  //const phoneRef = React.createRef();
  const phoneRef = useRef(null);

  const handleLogin = async (event) => {
    event.preventDefault();
    setShowMsg(false);
    setShowPassword(false);
    SessionInfo.forceLogoff = false;
    if (LoginInProgress)
      return;
    LoginInProgress = true;
    if (SessionInfo.phoneLoginVerify === false) {
      trace(`logon email: ${emailAddress}, password: ${password}`);
      setMesgColor("black");
      setLoginMessage(`Sign In To App`);
      let m = await LogOn(emailAddress,password);
      LoginInProgress = false;
      //setLoginMessage(`Logon result: ${m}`);
      //trace("Sign in - m: " + m + " UserID: " + SessionInfo.OMUserID);
      if (m === 'ok') { // Login successful
        clearMessage();
        setMesgColor("black");
        //showMessage(`Signed In: '${m}'`);
        showMessage(`Signed In - SessionInfo.gotoAutoPage: ${SessionInfo.gotoAutoPage}, OrderType: ${SessionInfo.OrderType}`);
        //navigate("/AppMain");    
        SessionInfo.defaultLogon = false;
        SessionInfo.Email = emailAddress;
        navigate(`/${SessionInfo.gotoAutoPage}`);
      }
      else if (m) {
        setFailCount(failCount + 1);
        if (failCount < 2) {
          setMesgColor("red");
          m += ` (${failCount + 1})`;
        } else {
          setMesgColor("blue");
          m = `Request New Password?`;
        }
        setError(m);
      } else if (emailAddress !== '') {
        setMesgColor("red");
        setFailCount(failCount + 1);
        setWarning("Unable to Sign In");
      }
    } else {
      verifyPhoneLogon(event)
    }
  }
  const handlePhoneLogin = async (event) => {
    event.preventDefault();
    setShowMsg(false);
    setShowPassword(false);
    SessionInfo.forceLogoff = false;
    //if (LoginInProgress)
    //  return;
    //LoginInProgress = true;    
    let keyValue = '';
    if (phoneNumber.length === 0) {
      if (SessionInfo.EntCookie === true)
        keyValue = getCookie(`IMSecure${SessionInfo.AppEntityID.toString()}ID`); 
      else
        keyValue = getCookie(`IMSecureID`);
      if (keyValue && keyValue !== 'NF') { // OM returned NF token
        //trace(`IMSecureID cookie: ${value}, RoleID: ${SessionInfo.RoleID}`);
        SessionInfo.loggedOn = true;
        SessionInfo.stayLoggedOn = true;
        SessionInfo.CheckoutToken = keyValue;
        await mainLogon(keyValue);
      }
    } 
    trace(`trycount: ${tryCount}`);
    if (tryCount++ >= 1 && phoneNumber.length > 0) {
      setPhoneNumber('');
      tryCount = 0;
    } else if (SessionInfo.phoneLoginVerify === true && phoneNumber.length > 1) {
      verifyPhoneLogon(event)
    } else {
      // Note - if No phoneLoginVerify - then no need to process another logon event
      trace(`handle phone logon - no verify - logonReturn: ${SessionInfo.logonReturn}, phoneNumber: ${phoneNumber}`);
      SessionInfo.AfterSignIn = true;
      if (SessionInfo.logonReturn === 1)
        navigate(`/LandingPage`);
      else if (SessionInfo.logonReturn === 2)
        navigate(`/OrderPage`);
      else
        navigate(`/${SessionInfo.gotoAutoPage}`);
    }
  }
  const handleConfirmation = async (event) => {
    event.preventDefault();
    setShowMsg(false);
    setShowPassword(false);
    //if (LoginInProgress)
    //  return;
    LoginInProgress = true;
    trace(`logon phoneNumber: ${phoneNumber}, password: ${password}`);
    setMesgColor("black");
    setLoginMessage(`Sign In To App`);
    let m = await LogOn(phoneNumber,password);
    LoginInProgress = false;
    //setLoginMessage(`Logon result: ${m}`);
    //trace("Sign in - m: " + m + " UserID: " + SessionInfo.OMUserID);
    if (m === 'ok') { // Login successful
      clearMessage();
      setMesgColor("black");
      //showMessage(`Signed In: '${m}'`);
      showMessage(`Signed In - SessionInfo.gotoAutoPage: ${SessionInfo.gotoAutoPage}, OrderType: ${SessionInfo.OrderType}`);
      //navigate("/AppMain"); 
      SessionInfo.defaultLogon = false;
      SessionInfo.Email = emailAddress;
      navigate(`/${SessionInfo.gotoAutoPage}`);
    }
    else if (m) {
      setFailCount(failCount + 1);
      if (failCount < 2) {
        setMesgColor("red");
        m += ` (${failCount + 1})`;
      } else {
        setMesgColor("blue");
        m = `Request New Password?`;
      }
      setError(m);
    } else if (emailAddress !== '') {
      setMesgColor("red");
      setFailCount(failCount + 1);
      setWarning("Unable to Sign In");
    }
  }
  const handleRegister = async (event) => { // Register
    SessionInfo.Email = emailAddress;
    SessionInfo.Password = password;
    if (confirmPassword === '') {
      confirmPassword = confirmPassword.value;
      SessionInfo.confirmPassword = confirmPassword;
    }
    trace(`Email: ${emailAddress}, Password: ${password}, Confirm Password: ${confirmPassword}`);
    setShowMsg(false);
    setShowPassword(false);
    event.preventDefault();
    if (password !== confirmPassword) {
      showWarning("Passwords must match");
    }
    else if (password.length < 8) {
      showWarning("Password must be at least 8 characters");
    }
    else if (SessionInfo.registerCounter > 0)
      showWarning("You must wait to Register");
    else {
      console.log("----> Call Register");
      SessionInfo.registerCounter = 10;
      //let m = await UserRegister(SessionInfo.FirstName,SessionInfo.LastName,SessionInfo.Email,SessionInfo.PhoneNumber,SessionInfo.Password,SessionInfo.confirmPassword,SessionInfo.registrationCode,"","STND"); // Register in CommonCode
      let m = await UserRegister(firstName,lastName,emailAddress,phoneNumber,password,confirmPassword,SessionInfo.registrationCode,"","STND"); // Register in CommonCode
      console.log("Return from UserRegister - m: " + m);
      SessionInfo.registerCounter = 0;
      if (m === 'ok') {
        showMessage(`Registration Successful - ID: ${SessionInfo.OMUserID}`);
        if (SessionInfo.OMUserID) {
          let CD = await GetTableFilter(1020151,"OMUserID=" + SessionInfo.OMUserID); // Get CustomerID 
          if (!CD.d.rows || !CD.d.rows.length) {
            this.showError(`Unable to Find Customer for the current User: ${SessionInfo.logonName}`);
            await LogOff(true);
            navigate("/AppMain"); // Note - that the componentWillUnmount will execute for the current page 
          }
          else {
            //trace("Get User CD: " + JSON.stringify(CD));
            ////SessionInfo.CFParticipantID = CD.d.rows[0]["CFParticipantID"];
            ////SessionInfo.SelectedParticipantID = CD.d.rows[0]["CFParticipantID"];
            navigate("/OrderPage");
          }
        }
      }
      else if (m === 'pa') {
        showWarning("Passwords must match");
      }
      else if (m)
        this.showError(m);
      else
        this.showWarning("Register Error");
    }
  }
  const requestPassword = async (event) => { // Register
    SessionInfo.Email = emailAddress;
    SessionInfo.Password = password;
    setMesgColor("black");
    setLoginMessage(`Requesting New Password`);
    let m = await RequestPWReset(emailAddress,password);
    showMessage(m);
    setFailCount(0);
  }
  //--
  const setPhoneStyle = (type) => { // Set border for phonenumber
    var color = '#787878';
    if (type === 'error')
      color = 'red';
    document.documentElement.style.setProperty('--signinPhone',color);
  }
  const setVerifyStyle = (type) => { // Set border for verifycode
    var color = '#787878';
    if (type === 'error')
      color = 'red';
    document.documentElement.style.setProperty('--signinVerify',color);
  }
  const chgShowPW = (event) => {
    //event.preventDefault();
    //trace(`start showPassword: ${showPassword}`);
    if (showPassword === true)
      setShowPassword(false);
    else
      setShowPassword(true);
    //trace(`showPassword: ${showPassword}`);
  }
  const chgStayLO = (event) => {
    if (stayLoggedOn === true) {
      setStayLoggedOn(false);
      SessionInfo.stayLoggedOn = false;
    } else {
      setStayLoggedOn(true);
      SessionInfo.stayLoggedOn = true;
    }
  }
  const gotoRegister = (event) => {
    event.preventDefault();
    setShowLogin(false);
  }
  const gotoSignIn = (event) => {
    event.preventDefault();
    setShowLogin(true);
  }
  const showMessage = (msg) => {
    setMesgColor("black");
    //trace(`showMessage: ${msg}, showMsg: ${showMsg}`);
    setLoginMessage(msg);
    setShowMsg(true);
    //trace(`After: ${message}, showMsg: ${showMsg}`);
    //  sleep(3000);
    //  setShowMsg(false);
  }
  const showWarning = (msg) => {
    showMessage(`Warn: ${msg}`);
  }
  const showError = (msg) => {
    setMesgColor("red");
    setLoginMessage(msg);
    setShowMsg(true);
    //showMessage(`Error: ${msg}`);
  }
  const setWarning = (msg) => {
    setMesgColor("orange");
    setLoginMessage(msg);
  }
  const setError = (msg) => {
    setMesgColor("red");
    setLoginMessage(msg);
  }
  const clearMessage = () => {
    setLoginMessage("");
  }
  const updateEmailAddress = (event) => {
    //trace(`fld chg - name: ${event.target.name}, value: ${event.target.value}`);
    setEmailAddress(event.target.value);
    //  this.setState({ [event.target.name]: event.target.value });
    //  this.setState({ stateHasChanged: true });
  }
  const blurEmail = (event) => {
    event.stopPropagation();
    trace(`Email: ${emailAddress}`);
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailAddress)) {
      setEmailStyle({ border: "none",fontWeight: "normal" });
    } else {
      setEmailStyle({ border: "red 2px solid",fontWeight: "bold" });
      //EmailRef.current.focus()
    }
    trace(`Email: ${emailAddress}`);
    SessionInfo.Email = emailAddress;
  }
  const chgFldValMask = (event,mask) => {
    //console.log(`chg masked value: ${event.target.value}, mask: ${mask}`);
    let phoneNumber = UnmaskString(event.target.value,mask);
    console.log(`phoneNumber: ${phoneNumber}`);
    if (phoneNumber.substring(0,1) === ' ') {
      offset = true;
      event.target.element.setSelectionRange(0,0);
      while (phoneNumber.substring(0,1) === ' ') {
        phoneNumber = phoneNumber.substring(1) + " ";
      }
      //console.log(`new phoneNumber: ${phoneNumber}`);
    } else if (offset === true) {
      for (let ix = 1; ix++; ix < 10) {
        if (phoneNumber.substring(ix,ix + 1) === ' ') {
          phoneNumber = phoneNumber.substring(0,ix) + phoneNumber.substring(ix + 1) + " ";
        }
      }
    }
    setPhoneNumber(phoneNumber);
    //this.setState({ [event.target.name]: UnmaskString(event.target.value,mask) });
    ////console.log(`chg masked - name: ${event.target.name}:${event.target.value}`);
    //this.setItemStateChanged(true);
    SessionInfo.PhoneNumber = phoneNumber;
  }
  const updatePhone = (event) => {
    //trace(`trycount: ${tryCount}`);
    tryCount = 0;          
    setPhoneNumber(event.target.value); 
  }
  const updateFirstName = (event) => {
    setFirstName(event.target.value);
  }
  const updateLastName = (event) => {
    setLastName(event.target.value);
  }
  const updatePhoneNumber = (event) => {
    setPhoneNumber(event.target.value);
  }
  const focusPhone = (event) => {  
    event.target.setSelectionRange(0,0);
  }
  const focusPhoneM = (event) => {
    setTimeout(() => {                      
      event.target.element.setSelectionRange(0,0); 
    });
  }
  const blurPhone = async (event) => {
    //event.stopPropagation();
    trace(`Email: ${emailAddress}`);
    trace(`Phone: ${phoneNumber}`);
    setVerify('');
    if (phoneNumber.length >= 10) {
      //setPhoneStyle({ border: "none",fontWeight: "normal" });
      if (/^\d{10}$/.test(phoneNumber)) {
        setPhoneStyle("normal");
        // Okay - so request code  
        let m = '';
        if (SessionInfo.phoneLoginVerify === true) { // Send the Verify Code to the Phone number provided
          m = await SendVerifyCode(phoneNumber);
          trace(`After SendVerify - m: ${m}`);
          if (m === 'ok') { // Send Code successful
            clearMessage();         
            showMessage(`Enter the SMS Text Code`);
            //navigate("/AppMain");
            //navigate(`/${SessionInfo.gotoAutoPage}`);
          }
          else if (m) {
            setFailCount(failCount + 1); 
            showError("Invalid Phone Number"); 
            event.target.focus();
          }
        } else {
          setMesgColor("black");
          showMessage(``);
          m = await LogonWithPhone(phoneNumber);
          if (m === 'ok') { // Login successful
            clearMessage();    
            showMessage(`Login Successful`);
            SessionInfo.defaultLogon = false;
            navigate(`/${SessionInfo.gotoAutoPage}`);
          }
          else if (m) {
            setFailCount(failCount + 1); 
            showError("Invalid Phone Number"); 
            event.target.focus();
          }
        }
      } else { // Non-numeric
        showError("Non-Numeric Phone Number");  
        event.target.focus();
      }
    } else { // Too short   
      if (phoneNumber.length > 0) {
        if (phoneNumber.length < 4)
          showError("Phone Number Required");
        else
          showError("Phone Number too Short");
        event.target.focus();
      }
    }
    SessionInfo.PhoneNumber = phoneNumber;
  }
  const chgVerify = (event) => {
    setVerify(event.target.value);
  }
  const verifyPhoneLogon = async (event) => {
    //event.stopPropagation();
    trace(`Phone: ${phoneNumber}, Verify: ${verify}`);
    if (phoneNumber.length >= 4) {
      //setPhoneStyle({ border: "none",fontWeight: "normal" });
      setPhoneStyle("normal");
      setVerifyStyle('normal');
      // Okay - so request code  
      let m = '';
      if (SessionInfo.phoneLoginVerify === true) {
        m = await LogonWithPhoneVerify(phoneNumber,verify);
        if (m === 'ok') { // Login successful
          clearMessage();
          setMesgColor("black");
          showMessage(`Login Successful`);
          SessionInfo.defaultLogon = false;
          trace(`phone logon - no verify - logonReturn: ${SessionInfo.logonReturn}`);
          SessionInfo.AfterSignIn = true;
          if (SessionInfo.logonReturn === 1)
            navigate(`/LandingPage`);
          else if (SessionInfo.logonReturn === 2)
            navigate(`/OrderPage`);
          else
            navigate(`/${SessionInfo.gotoAutoPage}`);
        }
        else if (m) {
          setFailCount(failCount + 1);
          setMesgColor("red");
          showError("Invalid Verify Code");
          setVerifyStyle('error');
          event.target.focus();
        }
      }
    } else {
      setPhoneStyle('error');
      event.target.focus();
    }
    SessionInfo.PhoneNumber = phoneNumber;
  }
  const chgPassword = (event) => {
    setPassword(event.target.value);
  }
  const blurPassword = (event) => {
    event.preventDefault();
    trace(`On Blur Password: ${password}`);
    SessionInfo.Password = password;
  }
  const chgConfirm = (event) => {
    setConfirm(event.target.value);
  }
  const blurconfirmPassword = (event) => {
    event.preventDefault();
    SessionInfo.confirmPassword = confirmPassword;
  }
  //const sleep = (delay) => {
  //  var start = new Date().getTime();
  //  while (new Date().getTime() < start + delay);
  //}
  
  console.log(`==> isLogin: ${isLogin}, isPhoneLogin: ${SessionInfo.isPhoneLogin}`);
  //if (SessionInfo.appEntityLogonType === 1) {

  //} else {
  //  //phoneRef.setSelectionRange(0,0);
  //  //phoneRef.current.focus();
  //  //useEffect(() => { phoneRef?.current?.setSelectionRange?.(0,0); },[phoneRef]);
  //  //useEffect(() => { phoneRef?.current?.focus?.(); },[phoneRef]);
  //  console.log(phoneRef);
  //}

  return (
    <div id="Login" className="pageMain">
      {isLogin === true ?
        <div id="Login" className="pageLoginBorder">
          <div id="div1" className="insideFrame">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href="http://www.omnovos.com" target="_blank" rel="noopener noreferrer" />
            {SessionInfo.appEntityLogonType === 1 ?
              <div className="loginPageInside">
                <label className="loginHeading">&nbsp; &nbsp; Interact Menu Logon</label>
                <br />
                <br />
                <label className="loginLabel" style={{ color: mesgColor }}>{loginMessage}</label>
                <br />
                <br />
                <div id="div2" className="editField">
                  <label className="logLabel">Email Address (<span>User Name</span>)</label>
                  <input name="EmailAddress" type="text" className="signinField" autoFocus autoComplete="email" onChange={evt => updateEmailAddress(evt)} value={emailAddress} />
                </div>
                <br />
                <br />
                <div id="div2" className="editField">
                  <label className="logLabel">Password</label><br />
                  {showPassword === false ?
                    <input name="password" type="password" autoComplete="password" className="signinField" onChange={evt => chgPassword(evt)} value={password} />
                    :
                    <input name="password" autoComplete="password" className="signinField" onChange={evt => chgPassword(evt)} value={password} />}
                </div>
                <br />
                <div id="div2" className="pwField">
                  <input type="checkbox" checked={showPassword} name="ShowPassword" onChange={chgShowPW} />
                  <span className="editFieldLabel">&nbsp; Show Password</span>
                  <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                  <input type="checkbox" checked={stayLoggedOn} name="StayLoggedOn" onChange={chgStayLO} />
                  <span className="editFieldLabel">&nbsp; Stay Logged On</span>
                </div>
                {failCount < 3 ?
                  <Button className="submitButton" onClick={handleLogin}>SIGN IN</Button>
                  :
                  <Button className="submitButton" onClick={requestPassword}>REQUEST PASSWORD</Button>
                }
                <br />
                <br />
                <label className="registerLabel">Not Registered Yet?</label><br /> <br />
                <Button className="submitButton" onClick={gotoRegister}>REGISTER</Button>
              </div>
              :
              <div className="loginPageInside">
                <label className="loginHeading">&nbsp; Interact Menu </label>
                <br />
                {loginMessage != undefined && loginMessage != '' ?
                  <>
                    <br />
                    <label className="loginLabel" style={{ color: mesgColor }}>{loginMessage}</label>
                    <br /> 
                  </> : null} 
                  <br />
                <div id="div2" className="editField">
                  <label className="logLabel">Phone Number</label>
                  <br/>
                  {/*<MaskedTextBox value={MaskString(phoneNumber,"(###) ###-####")} name='PhoneNumber' autoFocus ref={phoneRef} onChange={evt => chgFldValMask(evt,"(###) ###-####")} onFocus={evt => focusPhoneM(evt)} onBlur={evt => blurPhone(evt)} mask="(999) 000-0000" className='signinPhoneInputM' />*/}
                  <input name='PhoneNumber' value={phoneNumber} autoFocus inputMode="numeric" onChange={evt => updatePhone(evt)} onFocus={evt => focusPhone(evt)} onBlur={evt => blurPhone(evt)} className='signinPhoneInput'/>
                </div>
                <br />
                {SessionInfo.phoneLoginVerify === true ?
                  <>
                    <br />
                    <br />
                    <div id="div2" className="editField">
                      <label className="logLabel">Confirmation Code</label><br />
                      <label className="logLabelSub">(SMS Text Code Sent to Your Phone)</label><br />
                      {/*showPassword === false ?
                        <input name="verify" type="password" className="signinField" onChange={evt => chgVerify(evt)} value={verify} />
                        :*/}
                      <input name="verify" value={verify} inputMode="numeric" autoComplete="off" onChange={evt => chgVerify(evt)} className="verifyField" />
                    </div>
                    <br />
                    <div id="div2" className="codeField">
                      {/*<input type="checkbox" checked={showPassword} name="ShowPassword" onChange={chgShowPW} />*/}
                      <span className="editFieldLabel">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      <input type="checkbox" checked={stayLoggedOn} name="StayLoggedOn" onChange={chgStayLO} />
                      <span className="editFieldLabel">&nbsp; Save Identification</span>
                    </div>
                  </>
                  :
                  <>
                    <div id="div2" className="codeField">
                      <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>  
                    <br />
                  </>}
                <br />
                <Button className="submitButton" onClick={handlePhoneLogin}>GO TO APP</Button>
              </div>}
          </div>
        </div>
        :
        <div id="Register" className="pageRegisterBorder">
          <div id="div1" className="insideFrame">
            <a id="headerLogo" style={{ backgroundImage: `url(${SessionInfo.LogoImageURL})`,backgroundPosition: 'left top',backgroundRepeat: 'no-repeat',backgroundSize: '100px 60px' }} href={SessionInfo.RestaurantURL} target="_blank" rel="noopener noreferrer" />}
            <br />
            <br />
            <br />
            <br />
            <h2>Interact Menu Register</h2>
            <label className="loginLabel">{loginMessage}</label>
            <br />
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="First Name *" style={{ width: '300',color: 'darkblue' }} editorId={'first-name'} editorValue={firstName}>
                <Input id={'first-name'} value={firstName} style={{ width: 210 }} onChange={evt => updateFirstName(evt)} />
              </FloatingLabel>
            </div>
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Last Name *" style={{ width: '300',color: 'darkblue' }} editorId={'last-name'} editorValue={lastName}>
                <Input id={'last-name'} value={lastName} style={{ width: 210 }} onChange={evt => updateLastName(evt)} />
              </FloatingLabel>
            </div>
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Email Address (User Name) *" style={{ width: '300',color: 'darkblue' }} editorId={'email-address'} editorValue={emailAddress}>
                <Input id={'email-address'} value={emailAddress} style={{ width: 210 }} onChange={evt => updateEmailAddress(evt)} />
              </FloatingLabel>
            </div>
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Phone Number *" style={{ width: '300',color: 'darkblue' }} editorId={'phone-number'} editorValue={phoneNumber}>
                <Input id={'phone-number'} value={phoneNumber} style={{ width: 210 }} onChange={evt => updatePhoneNumber(evt)} />
              </FloatingLabel>
            </div>
            <br />
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Password *" style={{ width: '300',color: 'darkblue' }} editorId={'password'} editorValue={password}>
                {showPassword === false ?
                  <Input id={'password'} value={password} style={{ width: 210 }} type="password" autoComplete="false" onChange={evt => chgPassword(evt)} />
                  :
                  <Input id={'password'} value={password} style={{ width: 210 }} autoComplete="false" onChange={evt => chgPassword(evt)} />}
              </FloatingLabel>
            </div>
            <br />
            <div id="div2" className="pwField">
              <input type="checkbox" checked={showPassword} name="ShowPassword" onChange={chgShowPW} />
              <span className="editFieldLabel">&nbsp; Show Password</span>
              <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <input type="checkbox" checked={stayLoggedOn} name="StayLoggedOn" onChange={chgStayLO} />
              <span className="editFieldLabel">&nbsp; Stay Logged On</span>
            </div>
            Your Password must be 8 or more characters    With at least one Capital, one Number and one Symbol
            <br />
            <div id="div2" className="editField">
              <FloatingLabel label="Confirm Password" style={{ width: '300',color: 'darkblue' }} editorId={'confirmPassword'} editorValue={confirmPassword}>
                {showPassword === false ?
                  <Input id={'confirmPassword'} value={confirmPassword} style={{ width: 210 }} type="password" autoComplete="false" onChange={evt => chgConfirm(evt)} />
                  :
                  <Input id={'confirmPassword'} value={confirmPassword} style={{ width: 210 }} autoComplete="false" onChange={evt => chgConfirm(evt)} />}
              </FloatingLabel>
            </div>
            <br />
            <br />
            <br />
            <Button className="submitButton" onClick={handleRegister}>REGISTER</Button>
            <br />
            <label className="registerLabel">Already Registered? &nbsp; Sign In</label>
            <br />
            <Button className="submitButton" onClick={gotoSignIn}>SIGN IN</Button>
          </div>
        </div>
      }
    </div >
  );
}

export default (SignIn);